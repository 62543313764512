import React, {useState, useEffect,useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import MessageBox from '../components/MessageBox';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-green/theme.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import { Column } from 'primereact/column';
import { getcomboGruposMercaderia } from '../actions/comboActions';
import { AutoComplete } from 'primereact/autocomplete';
import {  MERCADERIASGRUPOSMERCADERIAS_UPDATE_SUCCESS } from '../constants/Mercaderias_GruposMercaderias';
import { getlistamercaderiasgruposmercaderias, mercaderiasgruposmercaderiasUpdate } from '../actions/mercaderiasgruposmercaderiasActions';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import { Toolbar } from 'primereact/toolbar';
 
export default function MercaderiasGruposMercaderiasScreen(props) {
    const mercaderiasgruposmercaderias =  useSelector(state => state.mercaderiasgruposmercaderias)
    const {loading, error, mercaderiasgruposmercaderiasLista} = mercaderiasgruposmercaderias;
    const [selectedMercaderia, setSelectedMercaderia] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Grupo, setGrupo] = useState(null);
    const [filteredCountries, setFilteredCountries] = useState(null); 
    const [loadingdata, setLoadingdata] = useState(false);

    const dt = useRef(null);
    const dispatch = useDispatch();

    const searchGrupo = (event) => {
        setTimeout(() => {
            let filteredCountries;
            if (!event.query.trim().length) {
                filteredCountries = [...GruposMercaderiaCombo];
            }
            else {
                filteredCountries = GruposMercaderiaCombo.filter((country) => {
                    return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            } 
            setFilteredCountries(filteredCountries);
        }, 250);
    }

    useEffect(() =>{
        setLoadingdata(loading);
    },[dispatch,loading]
    )

    const itemTemplate = (item) => {
        return (
            <div className="country-item">
                <img alt={item.sDescripcion} src={`showcase/demo/images/flag_placeholder.png`} 
                onError={(e) => e.target.src='https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
                className={`flag flag-${item.sCodigo.toLowerCase()}`} />
                <div>{item.sDescripcion}</div>
            </div>
        );
    }

    

    useEffect(() => {
    if (Grupo && Grupo.Id > 0)
      dispatch(getlistamercaderiasgruposmercaderias(Grupo.Id));
    }, [dispatch,Grupo]);

    const comboGruposMercaderia =  useSelector(state => state.comboGruposMercaderia);
    const {  GruposMercaderiaCombo} = comboGruposMercaderia;
    useEffect(() => { 
        dispatch({type: PANTALLASELECCIONADA, payload:  'Mercaderias Por Grupos'}); 
        dispatch({type: PANTALLAATRAS, payload:  ''}); 
         dispatch(getcomboGruposMercaderia())
         dispatch(getlistamercaderiasgruposmercaderias(0))
    }, [dispatch])
 
      const IdBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Id</span>
                {rowData.Id}
            </React.Fragment>
        );
      }

      const CodigoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Codigo</span>
                {rowData.sCodigo}
            </React.Fragment>
        );
      }

      const DescripcionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Descripcion</span>
                {rowData.sDescripcion}
            </React.Fragment>
        );
      }

    const seleccionar = (lista) => {
        setSelectedMercaderia(lista);
        for(let  j = 0; j < mercaderiasgruposmercaderiasLista.length; j++){
        let cambio = lista.find(r => mercaderiasgruposmercaderiasLista[j].Id === r.Id)
        if(!cambio && mercaderiasgruposmercaderiasLista[j].bActivo)
        { 
            let o = mercaderiasgruposmercaderiasLista.find(r => mercaderiasgruposmercaderiasLista[j].Id === r.Id );
            o.bActivo = !o.bActivo;
            dispatch({type: MERCADERIASGRUPOSMERCADERIAS_UPDATE_SUCCESS, payload:  mercaderiasgruposmercaderiasLista}); 
            dispatch(mercaderiasgruposmercaderiasUpdate( Grupo.Id,o.Id,o.bActivo));
        }
        else if(cambio  && !mercaderiasgruposmercaderiasLista[j].bActivo)
        {
            let o = mercaderiasgruposmercaderiasLista.find(r =>   mercaderiasgruposmercaderiasLista[j].Id === r.Id );
            o.bActivo = !o.bActivo;
            dispatch({type: MERCADERIASGRUPOSMERCADERIAS_UPDATE_SUCCESS, payload:  mercaderiasgruposmercaderiasLista}); 
            dispatch(mercaderiasgruposmercaderiasUpdate( Grupo.Id,o.Id,o.bActivo));
        }
      };
}

useEffect(() => {
    if(mercaderiasgruposmercaderiasLista)
    setSelectedMercaderia(mercaderiasgruposmercaderiasLista.filter(r =>   r.bActivo === true))
    }, [dispatch,mercaderiasgruposmercaderiasLista]);

    const rightContents = (
        <React.Fragment>
              <span   className="p-input-icon-left">
                    <i  className="pi pi-search" />
                    <InputText     style={   { fontSize: "1.5rem", color:'black',width:"80px"    }   }   
                    type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                </span>  
        </React.Fragment>
    );

    const leftContents = (
        <React.Fragment>
                <label htmlFor="GrupoId" >Grupo  </label> 
                 <AutoComplete value={Grupo} suggestions={filteredCountries} completeMethod={searchGrupo} 
                 field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                 onChange={(e) => setGrupo(e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/> 
        </React.Fragment>
    );

    return (
                error? <MessageBox variant="danger" >{error}</MessageBox>
                :
                (
                <div style={{marginBottom:'50px'}}>
                    <div style={{  padding:"0px" ,position: 'fixed',zIndex: 1, width: '100%',marginTop:'20px'}}>
                        <Toolbar right={rightContents} left={leftContents}  style={{ backgroundColor:"#179F5D", margin:0,padding:0, fontSize:'16px', fontWeight:"bold", width:"auto"}}>
                        </Toolbar>   
                    </div>
                    <div className="datatable-doc-demo" style={{paddingTop:"60px"}}>
                        <DataTable var="article" id="merc" ref={dt} value={mercaderiasgruposmercaderiasLista} 
                            className="p-datatable-customers" dataKey="Id"
                            rowHover globalFilter={globalFilter}   selection={selectedMercaderia} onSelectionChange={e => seleccionar(e.value)}
                            paginator rows={10} emptyMessage="No se encontraron Pedidos" loading={loadingdata}
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
                            rowsPerPageOptions={[10,25,50]}
                            >
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="Id" header="Id"  body={IdBodyTemplate} headerStyle={{   textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
                        <Column field="sCodigo" header="Codigo"  body={CodigoBodyTemplate} headerStyle={{   textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
                        <Column field="sDescripcion" header="Descripcion"  body={DescripcionBodyTemplate} headerStyle={{   textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>                             
                        </DataTable>       
                    </div>
                </div>)
);
}