import React, {  useState,useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getcomboClasificaciones, getcomboDefinicionesCuarto, getcomboMercaderiasAfip, getcomboNomencladores } from '../../actions/comboActions';
import { MERCADERIA_GET_SUCCESS } from '../../constants/mercaderiaConstants';
import LoadingBox from '../LoadingBox';
import MessageBox from '../MessageBox';
import 'primereact/resources/themes/saga-green/theme.css';
import { AutoComplete } from 'primereact/autocomplete';
import 'primeicons/primeicons.css';
export default function MercaderiaDetalleFrigo() {
  const dispatch = useDispatch();
  const mercaderiaGet =  useSelector(state => state.mercaderiaGet)
  const { mercaderiaItem} = mercaderiaGet;
  const Modificar = (campo,valor,combo) => 
  {
    switch (campo) {
    case 'DefinicionCuarto_Id':
      setDefinicionCuarto(combo);
    break;
    case 'Nomenclador_id':
      setNomenclador(combo);
    break;
    case 'ClasificacionId':
      setClasificacion(combo);
    break;
    case 'MercaderiaAfip_Id':
      setMercaderiaAfip(combo);
    break;
    
    default:
      break;
  }
  if(combo)
  mercaderiaItem[campo] = valor ? valor : 0;
  else
  mercaderiaItem[campo] = valor;
    dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});    
  }

    const comboDefinicionesCuarto =  useSelector(state => state.comboDefinicionesCuarto);
    const {loading: loadingDefinicionesCuarto, error: errorDefinicionesCuarto, DefinicionesCuartoCombo} = comboDefinicionesCuarto;
    useEffect(() => { 
         dispatch(getcomboDefinicionesCuarto())
    }, [dispatch])
    const [DefinicionCuarto, setDefinicionCuarto] = useState(null);
    const [filteredDefinicionesCuarto, setFilteredDefinicionesCuarto] = useState(null); 
    const searchDefinicionesCuarto = (event) => {
        setTimeout(() => {
            let filteredDefinicionesCuarto;
            if (!event.query.trim().length) {
                filteredDefinicionesCuarto = [...DefinicionesCuartoCombo];
            }
            else {
              filteredDefinicionesCuarto = DefinicionesCuartoCombo.filter((country) => {
                    return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            } 
            setFilteredDefinicionesCuarto(filteredDefinicionesCuarto);
        }, 250);
    }
    useEffect(() => { 
      if(DefinicionesCuartoCombo && mercaderiaItem)
      setDefinicionCuarto(DefinicionesCuartoCombo.find(r => r.Id === mercaderiaItem.DefinicionCuarto_Id));
  }, [dispatch,DefinicionesCuartoCombo,mercaderiaItem])


    const comboNomencladores =  useSelector(state => state.comboNomencladores);
    const {loading: loadingNomencladores, error: errorNomencladores, NomencladoresCombo} = comboNomencladores;
    useEffect(() => { 
         dispatch(getcomboNomencladores())
    }, [dispatch])
    const [Nomenclador, setNomenclador] = useState(null);
    const [filteredNomencladores, setFilteredNomencladores] = useState(null); 
    const searchNomencladores = (event) => {
        setTimeout(() => {
            let filteredNomencladores;
            if (!event.query.trim().length) {
                filteredNomencladores = [...NomencladoresCombo];
            }
            else {
              filteredNomencladores = NomencladoresCombo.filter((country) => {
                    return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            } 
            setFilteredNomencladores(filteredNomencladores);
        }, 250);
    }
    useEffect(() => { 
      if(NomencladoresCombo && mercaderiaItem)
      setNomenclador(NomencladoresCombo.find(r => r.Id === mercaderiaItem.Nomenclador_id));
  }, [dispatch,NomencladoresCombo,mercaderiaItem])

    
    const comboClasificaciones =  useSelector(state => state.comboClasificaciones);
    const {loading: loadingClasificaciones, error: errorClasificaciones, ClasificacionesCombo} = comboClasificaciones;
    useEffect(() => { 
         dispatch(getcomboClasificaciones())
    }, [dispatch])
    const [Clasificacion, setClasificacion] = useState(null);
    const [filteredClasificaciones, setFilteredClasificaciones] = useState(null); 
    const searchClasificaciones = (event) => {
        setTimeout(() => {
            let filteredClasificaciones;
            if (!event.query.trim().length) {
                filteredClasificaciones = [...ClasificacionesCombo];
            }
            else {
              filteredClasificaciones = ClasificacionesCombo.filter((country) => {
                    return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            } 
            setFilteredClasificaciones(filteredClasificaciones);
        }, 250);
    }
    useEffect(() => { 
      if(ClasificacionesCombo && mercaderiaItem)
      setClasificacion(ClasificacionesCombo.find(r => r.Id === mercaderiaItem.ClasificacionId));
  }, [dispatch,ClasificacionesCombo,mercaderiaItem])


    const comboMercaderiasAfip =  useSelector(state => state.comboMercaderiasAfip);
    const {loading: loadingMercaderiasAfip, error: errorMercaderiasAfip, MercaderiasAfipCombo} = comboMercaderiasAfip;
    useEffect(() => { 
         dispatch(getcomboMercaderiasAfip())
    }, [dispatch])
    const [MercaderiaAfip, setMercaderiaAfip] = useState(null);
    const [filteredMercaderiasAfip, setFilteredMercaderiasAfip] = useState(null); 
    const searchMercaderiasAfip = (event) => {
        setTimeout(() => {
            let filteredMercaderiasAfip;
            if (!event.query.trim().length) {
                filteredMercaderiasAfip = [...MercaderiasAfipCombo];
            }
            else {
              filteredMercaderiasAfip = MercaderiasAfipCombo.filter((country) => {
                    return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            } 
            setFilteredMercaderiasAfip(filteredMercaderiasAfip);
        }, 250);
    }
    useEffect(() => { 
      if(MercaderiasAfipCombo && mercaderiaItem)
      setMercaderiaAfip(MercaderiasAfipCombo.find(r => r.Id === mercaderiaItem.MercaderiaAfip_Id));
  }, [dispatch,MercaderiasAfipCombo,mercaderiaItem])




    const itemTemplate = (item) => {
      return (
          <div className="country-item">
              <img  alt={item.sDescripcion} src={'https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
              onError={(e) => e.target.src='https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
              className={`flag flag-${item.sCodigo.toLowerCase()}`} />
              <div>{item.sDescripcion}</div>
          </div>
      );
    }
    return (
    
               <form className="form">
               <h5 htmlFor="sCodSenasa" style={{marginBottom:"0px"}}>CodSenasa</h5>
                <input
                id="sCodSenasa"
                type="text" 
                placeholder="Ingrese CodSenasa"
                value={mercaderiaItem.sCodSenasa}
                onChange={(e) => Modificar('sCodSenasa',e.target.value)} ></input>    

<h5 htmlFor="sDescSenasa" style={{marginBottom:"0px"}}>DescSenasa</h5>
                <input
                id="sDescSenasa"
                type="text" 
                placeholder="Ingrese DescSenasa"
                value={mercaderiaItem.sDescSenasa}
                onChange={(e) => Modificar('sDescSenasa',e.target.value)} ></input>   

<h5 htmlFor="DefinicionCuarto_Id">Definicion de Cuarto</h5>
            <div>
              {loadingDefinicionesCuarto? <LoadingBox></LoadingBox>
              :
              errorDefinicionesCuarto?<MessageBox variant="danger">{errorDefinicionesCuarto}</MessageBox>
              :    
              <AutoComplete value={DefinicionCuarto} suggestions={filteredDefinicionesCuarto} completeMethod={searchDefinicionesCuarto} 
              field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
              onChange={(e) => Modificar('DefinicionCuarto_Id', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
          </div>


          <h5 htmlFor="Nomenclador_id">Definicion de Cuarto</h5>
            <div>
              {loadingNomencladores? <LoadingBox></LoadingBox>
              :
              errorNomencladores?<MessageBox variant="danger">{errorNomencladores}</MessageBox>
              :    
              <AutoComplete value={Nomenclador} suggestions={filteredNomencladores} completeMethod={searchNomencladores} 
              field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
              onChange={(e) => Modificar('Nomenclador_id', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
          </div>
 

        <Link onClick={() => {Modificar('bControlMagro',!mercaderiaItem.bControlMagro)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
        <div>
          <tr>
            <td>{mercaderiaItem.bControlMagro ? <i class="fa fa-check-square-o" 
                            aria-hidden="true" 
                            style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                    : <i class="fa fa-square-o" 
                            aria-hidden="true" 
                            style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
            }</td>
            <td> <h4 style={{marginRight:"20px"}}>Control Magro </h4></td>
            </tr>    
        </div> 
        </Link> 

        <h5 htmlFor="ClasificacionId">Clasificacion</h5>
            <div>
              {loadingClasificaciones? <LoadingBox></LoadingBox>
              :
              errorClasificaciones?<MessageBox variant="danger">{errorClasificaciones}</MessageBox>
              :    
              <AutoComplete value={Clasificacion} suggestions={filteredClasificaciones} completeMethod={searchClasificaciones} 
              field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
              onChange={(e) => Modificar('ClasificacionId', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
          </div>


          <h5 htmlFor="MercaderiaAfip_Id">Mercaderia Afip </h5>
            <div>
              {loadingMercaderiasAfip? <LoadingBox></LoadingBox>
              :
              errorMercaderiasAfip?<MessageBox variant="danger">{errorMercaderiasAfip}</MessageBox>
              :    
              <AutoComplete value={MercaderiaAfip} suggestions={filteredMercaderiasAfip} completeMethod={searchMercaderiasAfip} 
              field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
              onChange={(e) => Modificar('MercaderiaAfip_Id', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
          </div>

          <h5 htmlFor="scodigodgrproducto" style={{marginBottom:"0px"}}>Cod DGR Producto</h5>
                <input
                id="scodigodgrproducto"
                type="text" 
                placeholder="Ingrese DGR"
                value={mercaderiaItem.scodigodgrproducto}
                onChange={(e) => Modificar('scodigodgrproducto',e.target.value)} ></input>
               </form>
    )
}
