import { MERCADERIASGRUPOSMERCADERIAS_LISTA_FAIL, MERCADERIASGRUPOSMERCADERIAS_LISTA_REQUEST, MERCADERIASGRUPOSMERCADERIAS_LISTA_SUCCESS, 
  MERCADERIASGRUPOSMERCADERIAS_UPDATE_FAIL, MERCADERIASGRUPOSMERCADERIAS_UPDATE_REQUEST, MERCADERIASGRUPOSMERCADERIAS_UPDATE_RESET, 
  MERCADERIASGRUPOSMERCADERIAS_UPDATE_SUCCESS } from "../constants/Mercaderias_GruposMercaderias";

 
 
  
    export const mercaderiasgruposmercaderiasReducer = ( state = { loading:true,  mercaderiasgruposmercaderiasLista: [] },  action) => {
    switch (action.type) {
      case MERCADERIASGRUPOSMERCADERIAS_LISTA_REQUEST:
        return { loading: true };
      case MERCADERIASGRUPOSMERCADERIAS_LISTA_SUCCESS:
        return { loading: false, mercaderiasgruposmercaderiasLista: action.payload };
      case MERCADERIASGRUPOSMERCADERIAS_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const mercaderiasgruposmercaderiasUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case MERCADERIASGRUPOSMERCADERIAS_UPDATE_REQUEST:
        return { loading: true };
      case MERCADERIASGRUPOSMERCADERIAS_UPDATE_SUCCESS:
        return { loading: false, success: true  };
      case MERCADERIASGRUPOSMERCADERIAS_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case MERCADERIASGRUPOSMERCADERIAS_UPDATE_RESET:
          return {};
      default:
        return state;
    }
  };