import React, {useState, useEffect,useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux'; 
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import { Column } from 'primereact/column'; 
import { InputNumber } from 'primereact/inputnumber'; 
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import { Toolbar } from 'primereact/toolbar';
import { DescuentoUpdate, getlistaDescuentos } from '../actions/descuentosActions'; 
import { Tooltip } from '@material-ui/core'; 
import { DESCUENTOS_LISTA_SUCCESS } from '../constants/DescuentosConstants';

export default function DescuentosScreen(props) {
    const descuentos =  useSelector(state => state.descuentos)

    const {loading, descuentosLista} = descuentos;
    const [loadingdata, setLoadingdata] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({type: PANTALLASELECCIONADA, payload:  'Descuentos'}); 
        dispatch({type: PANTALLAATRAS, payload:  ''}); 
      dispatch(getlistaDescuentos());
    }, [dispatch]);

    useEffect(() =>{
        setLoadingdata(loading);
    },[dispatch,loading]
    )

    const IdBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="p-column-title">Id</span>
            {rowData.Id}
        </React.Fragment>
    );
    }

    const DiaHastaBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="p-column-title">DiaHasta</span>
            {rowData.sDiaHasta}
        </React.Fragment>
    );
    }

    const MetodoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Metodo</span>
                {rowData.MetodoPagoDescripcion}
            </React.Fragment>
        );
        }

        
    const DiaDesdeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">DiaDesde</span>
                {rowData.sDiaDesde}
            </React.Fragment>
        );
        }

        const HoraHastaBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <span className="p-column-title">HoraHasta</span>
                    {rowData.sHoraHasta}
                </React.Fragment>
            );
            }
        
            const HoraDesdeBodyTemplate = (rowData) => {
                return (
                    <React.Fragment>
                        <span className="p-column-title">HoraDesde</span>
                        {rowData.sHoraDesde}
                    </React.Fragment>
                );
                }
 
    const DescuentoBodyTemplate = (rowData) => {
        return   <React.Fragment>
                <span className="p-column-title">Descuento: </span>
                {new  Intl.NumberFormat('en-US').format(rowData.nDescuento) }
                </React.Fragment>
    }

    const DescuentoEditor = ( props) => {
        return     <InputNumber value={props.rowData['nDescuento']} 
            style={{size: 'Large'}} showButtons buttonLayout="horizontal"
            onValueChange={(e) => onDescuentoValueChange( props, e.value)} 
            mode="decimal"   minFractionDigits={3} decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
            locale="en-US" className="p-inputtext-lg p-d-block"  />
    }
 
    const onDescuentoValueChange = (props, value) => {
        let o = descuentosLista.find(r => 
            r.Id === props.rowData['Id'] );
        o.nDescuento = value;
        dispatch({type: DESCUENTOS_LISTA_SUCCESS, payload:  descuentosLista}); 
        dispatch(DescuentoUpdate(o,false)); 
    }

    const PorcentajeBodyTemplate = (rowData, column) => {
    if(!rowData.bEsPorcentaje )
        {
          return (
            <React.Fragment>
            <span className="p-column-title">%</span>
              
                        <i class="fa fa-circle-o" aria-hidden="true" 
                            style={   { fontSize: "2rem", color:'black'  ,cursor: "hand" , verticalAlign: "bottom"  }   }    
                          />                
        </React.Fragment>);
       
        }
        else
        {
            return (
                <React.Fragment>
                <span className="p-column-title">%</span>
                   
                            <i class="fa fa-check-circle-o" aria-hidden="true" 
                                style={   { fontSize: "2rem", color:'black'  ,cursor: "hand" , verticalAlign: "bottom"  }   }    
                                 />
            </React.Fragment>);
        }
        }


        const Nueva = () => {
            props.history.push(`/descuentodetalle/0`);
        }

        const activoBodyTemplate = (rowData, column) => {
            if(!rowData.bActivo )
            {  return (
                <React.Fragment>
                    <span className="p-column-title">Activo</span>
                    <i class="fa fa-circle-o" aria-hidden="true"  style={{ fontSize: "2rem", color:'black'  ,cursor: "hand" , verticalAlign: "bottom"}}/>                
                </React.Fragment>);
            }
            else
            {
                return (
                    <React.Fragment>
                    <span className="p-column-title">Activo</span>
                     <i class="fa fa-check-circle-o" aria-hidden="true"style={{ fontSize: "2rem", color:'black',cursor: "hand", verticalAlign: "bottom"}}/>
                </React.Fragment>);
            }}

  const rightContents = (
    <React.Fragment>
          <span   className="p-input-icon-left">
                <i  className="pi pi-search" />
                <InputText     style={   { fontSize: "1.5rem", color:'black'    }   }   
                type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busqueda Global" />
            </span>  
    </React.Fragment>
);

const customFunction = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0
}
    return (<div style={{marginBottom:'50px'}}>
                <div style={{  padding:"0px" ,position: 'fixed',zIndex: 1, width: '100%',marginTop:'20px'}}>
                    <Toolbar right={rightContents}  style={{ backgroundColor:"#179F5D", margin:0,padding:0, fontSize:'16px', fontWeight:"bold", width:"auto"}}>
                    </Toolbar>   
                </div>

                <div style={{ position: 'fixed',zIndex: 1, bottom: 0,  right: 0,  justifyContent: 'center', alignItems: 'center', marginBottom:"30px"}} >
                    <Tooltip title={<h4 style={{ color: "#FF70FF" }}>Nuevo Descuento</h4>}>
                        <i className="fa fa-plus-circle" aria-hidden="true" style={{fontSize:'72px',color:'black',zIndex:"100",cursor:'pointer', marginRight:"20px"}} onClick={() => Nueva()}></i> 
                    </Tooltip>
                </div>


                        <div className="datatable-doc-demo" style={{paddingTop:'55px'}}>
                        <DataTable ref={dt} value={descuentosLista} className="p-datatable-customers" dataKey="Id"
                        rowHover globalFilter={globalFilter}   selectionMode="single"  loading={loadingdata}
                        onSelectionChange={(e) => {  props.history.push(`/descuentodetalle/${e.value.Id}`);  }}   
                        paginator rows={10} emptyMessage="No se encontraron Pedidos" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
>
<Column  field="bActivo" header="Act." body={activoBodyTemplate} headerStyle={{width:"4rem", textAlign: 'center' }}  />
        <Column field="Id" header="Id"  body={IdBodyTemplate} headerStyle={{ width:"8rem",  textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="sDiaDesde" header="DiaDesde" filterMatchMode="custom" filterFunction={customFunction} body={DiaDesdeBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="sDiaHasta" header="DiaHasta" filterMatchMode="custom" filterFunction={customFunction} body={DiaHastaBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="sHoraDesde" header="HoraDesde" filterMatchMode="custom" filterFunction={customFunction} body={HoraDesdeBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="sHoraHasta" header="HoraHasta" filterMatchMode="custom" filterFunction={customFunction} body={HoraHastaBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="MetodoPagoDescripcion" header="Metodo" filterMatchMode="custom" filterFunction={customFunction} body={MetodoBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column  field="bEsPorcentaje" header="%" body={PorcentajeBodyTemplate} headerStyle={{width:"4rem", textAlign: 'center' }}  />
        <Column field="nDescuento" header="Descuento" body={DescuentoBodyTemplate} editor={(props) => DescuentoEditor( props)}></Column>
</DataTable>       
</div>
</div>
);

}