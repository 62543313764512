import React, {useState, useEffect,useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import MessageBox from '../components/MessageBox';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-green/theme.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import { Column } from 'primereact/column'; 
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import { Toolbar } from 'primereact/toolbar';
import { descuentosmercaderiasUpdate, getlistadescuentosmercaderias } from '../actions/descuentosmercaderiasActions';
import { DESCUENTOSMERCADERIAS_UPDATE_SUCCESS } from '../constants/Descuentos_MercaderiasConstants';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import DescuentosGrilla from '../components/DescuentosGrilla';
 
export default function DescuentosMercaderiasScreen(props) {

    const descuentosmercaderias =  useSelector(state => state.descuentosmercaderias)
    const {loading, error, descuentosmercaderiasLista} = descuentosmercaderias;

    const constantes =  useSelector(state => state.constantes)
    const { descuento: DescuentoSeleccionado} = constantes;

    const [selectedMercaderia, setSelectedMercaderia] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [loadingdata, setLoadingdata] = useState(false);

    const dt = useRef(null);
    const dispatch = useDispatch();

    useEffect(() =>{
        setLoadingdata(loading);
    },[dispatch,loading]
    )

    useEffect(() => {
    if (DescuentoSeleccionado && DescuentoSeleccionado.Id > 0)
      dispatch(getlistadescuentosmercaderias(DescuentoSeleccionado.Id));
      else
      dispatch(getlistadescuentosmercaderias(0));
    }, [dispatch,DescuentoSeleccionado]);

    useEffect(() => { 
        dispatch({type: PANTALLASELECCIONADA, payload:  'Descuentos Por Mercaderias'}); 
        dispatch({type: PANTALLAATRAS, payload:  ''}); 
   
         dispatch(getlistadescuentosmercaderias(0))
    }, [dispatch])
 
      const IdBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Id</span>
                {rowData.Id}
            </React.Fragment>
        );
      }

      const CodigoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Codigo</span>
                {rowData.sCodigo}
            </React.Fragment>
        );
      }

      const DescripcionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Descripcion</span>
                {rowData.sDescripcion}
            </React.Fragment>
        );
      }

    const seleccionar = (lista) => {
        setSelectedMercaderia(lista);
        for(let  j = 0; j < descuentosmercaderiasLista.length; j++){
        let cambio = lista.find(r => descuentosmercaderiasLista[j].Id === r.Id)
        if(!cambio && descuentosmercaderiasLista[j].bActivo)
        { 
            let o = descuentosmercaderiasLista.find(r => descuentosmercaderiasLista[j].Id === r.Id );
            o.bActivo = !o.bActivo;
            dispatch({type: DESCUENTOSMERCADERIAS_UPDATE_SUCCESS, payload:  descuentosmercaderiasLista}); 
            dispatch(descuentosmercaderiasUpdate( DescuentoSeleccionado.Id,o.Id,o.bActivo));
        }
        else if(cambio  && !descuentosmercaderiasLista[j].bActivo)
        {
            let o = descuentosmercaderiasLista.find(r =>   descuentosmercaderiasLista[j].Id === r.Id );
            o.bActivo = !o.bActivo;
            dispatch({type: DESCUENTOSMERCADERIAS_UPDATE_SUCCESS, payload:  descuentosmercaderiasLista}); 
            dispatch(descuentosmercaderiasUpdate( DescuentoSeleccionado.Id,o.Id,o.bActivo));
        }
      };
}

useEffect(() => {
    if(descuentosmercaderiasLista)
    setSelectedMercaderia(descuentosmercaderiasLista.filter(r =>   r.bActivo === true))
    }, [dispatch,descuentosmercaderiasLista]);
    const rightContents = (
        <React.Fragment>
              <span   className="p-input-icon-left">
                    <i  className="pi pi-search" />
                    <InputText     style={   { fontSize: "1.5rem", color:'black',width:"80px"    }   }   
                    type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                </span>  
        </React.Fragment>
    );
    const leftContents = (
        <React.Fragment>
           {DescuentoSeleccionado && DescuentoSeleccionado.Id ? <label style={{color:"white"}}>
               {"De " + DescuentoSeleccionado.sDiaDesde + " a " + DescuentoSeleccionado.sDiaHasta + 
               "("+DescuentoSeleccionado.sHoraDesde+":" + DescuentoSeleccionado.sHoraHasta+") " + DescuentoSeleccionado.nDescuento +
              ( DescuentoSeleccionado.bEsPorcentaje? "% " :"$ " ) + DescuentoSeleccionado.MetodoPagoDescripcion }</label>:<></>}
        </React.Fragment>
    );
    const customFunction = (value, filter) => {
        return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0
    }
    return (
                error? <MessageBox variant="danger" >{error}</MessageBox>
                :
                (
<>
                        <div style={{  padding:"0px" ,position: 'fixed',zIndex: 1, width: '100%',marginTop:'20px'}}>
                                <Toolbar   left={leftContents}  style={{ backgroundColor:"#179F5D", margin:0,padding:0, fontSize:'16px', fontWeight:"bold", width:"auto"}}>
                                </Toolbar>   
                            </div>

                    <Splitter  layout="vertical">
                        <SplitterPanel className="p-d-flex p-ai-center p-jc-center">
                        <div className="datatable-doc-demo" style={{paddingTop:"40px"}}>
                            <DescuentosGrilla/> 
                        </div>
                        </SplitterPanel>
                        <SplitterPanel className="p-d-flex p-ai-center p-jc-center">
                            <div style={{marginBottom:'20px'}}>
                            <div style={{  padding:"0px" , zIndex: 1, width: '100%' }}>
                                <Toolbar right={rightContents}    style={{ backgroundColor:"#179F5D", margin:0,padding:0, fontSize:'16px', fontWeight:"bold", width:"auto"}}>
                                </Toolbar>   
                            </div>

                            <div className="datatable-doc-demo" style={{paddingTop:"0px"}}>
                                <DataTable var="article" id="merc" ref={dt} value={descuentosmercaderiasLista} 
                                    className="p-datatable-customers" dataKey="Id"
                                    rowHover globalFilter={globalFilter}   selection={selectedMercaderia} onSelectionChange={e => seleccionar(e.value)}
                                    paginator rows={10} emptyMessage="No se encontraron Pedidos" loading={loadingdata}
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
                                    rowsPerPageOptions={[10,25,50,10000]} >
                                <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                                <Column field="Id" header="Id"  body={IdBodyTemplate} headerStyle={{  width: '8em', textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
                                <Column field="sCodigo" header="Codigo"  body={CodigoBodyTemplate} headerStyle={{   textAlign: 'center' , margin:'0'}} filterMatchMode="custom" filterFunction={customFunction} sortable filter filterPlaceholder="Buscar"></Column>
                                <Column field="sDescripcion" header="Descripcion"  body={DescripcionBodyTemplate} headerStyle={{   textAlign: 'center' , margin:'0'}} filterMatchMode="custom" filterFunction={customFunction} sortable filter filterPlaceholder="Buscar"></Column>                             
                                </DataTable>       
                            </div>
                            </div>
                        </SplitterPanel>
                    </Splitter></>
               )
);
}