import { GRUPOSMERCADERIA_LISTA_FAIL, GRUPOSMERCADERIA_LISTA_REQUEST, GRUPOSMERCADERIA_LISTA_SUCCESS, GRUPOSMERCADERIA_UPDATE_FAIL,
   GRUPOSMERCADERIA_UPDATE_REQUEST, GRUPOSMERCADERIA_UPDATE_SUCCESS } 
   from "../constants/gruposmercaderiaConstants";

 
export const getlistaGruposMercaderia = () => async (dispatch) => {
    dispatch({ type: GRUPOSMERCADERIA_LISTA_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/GruposMercaderia/Lista', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
          if(data.Message)
           {
            dispatch({ type: GRUPOSMERCADERIA_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: GRUPOSMERCADERIA_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: GRUPOSMERCADERIA_LISTA_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: GRUPOSMERCADERIA_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const updateGruposMercaderia = (Item,FotoNombre) => async (dispatch, getState) => {
    dispatch({ type: GRUPOSMERCADERIA_UPDATE_REQUEST});
    try {
      const { operadorSignin: { operadorInfo }, } = getState(); 
      var parametros = {EmpresaId: localStorage.getItem("empresaId"), Item: Item, OperadorId: operadorInfo.Id,FotoNombre:FotoNombre };
  /*     dispatch({ type: GRUPOSMERCADERIA_UPDATE_FAIL, payload:  JSON.stringify(parametros) });
      return;
    */
   const response = await fetch('https://twinsappservice.azurewebsites.net/api/GruposMercaderia/Insert', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: GRUPOSMERCADERIA_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: GRUPOSMERCADERIA_UPDATE_FAIL, payload:  data });
           }
           else
           {
           dispatch({type: GRUPOSMERCADERIA_UPDATE_SUCCESS }); 
           }  
           
    } catch (error) {
      dispatch({
        type: GRUPOSMERCADERIA_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };