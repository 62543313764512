import React from 'react';
import NumericInput from 'react-numeric-input';
import { useDispatch,useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MERCADERIA_GET_SUCCESS } from '../../constants/mercaderiaConstants';

export default function MercaderiaDetalleFechas() {
    const dispatch = useDispatch();
    const mercaderiaGet =  useSelector(state => state.mercaderiaGet)
    const { mercaderiaItem} = mercaderiaGet;
    const Modificar = (campo,e) => 
    {
      mercaderiaItem[campo] = e;
      dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});    
    }
  
    return (
      mercaderiaItem && 
      <div >
             <div style={{   padding:"0", alignContent:"center"}} >
               <h2 style={{padding:"0",textAlign:"center"}}>  Vencimiento  </h2> 
            </div>
            <form className="form" style={{borderColor:"#585858", border:"1px solid #585858"}} >
            <tr>
                  <td><h5 htmlFor="CantidadVencimiento" style={{marginLeft:"5px"}}>Cantidad: </h5></td>
                  <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.VencimientoCantidad} onChange={e => Modificar('VencimientoCantidad',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 16 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td>
            
            </tr> 
            <div> 
            <Link onClick={() => {Modificar('VencimientoPorDia',!mercaderiaItem.VencimientoPorDia)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                    <div>

                      <tr>
                        <td>{mercaderiaItem.VencimientoPorDia ? <i class="fa fa-check-square-o" 
                                        aria-hidden="true" 
                                        style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                                : <i class="fa fa-square-o" 
                                        aria-hidden="true" 
                                        style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                        }</td>
                        <td> <h4>Por Días </h4></td>
                        </tr> 
                          <h5 style={{padding:"0" , marginTop:"0" }}>
                        Desmarque y el cálculo se hará por meses
                                        </h5> 
                    </div> 
                  </Link> 
                 
            </div>
         
            <div> 
            <Link onClick={() => {Modificar('VencimientoDesdeProduccion',!mercaderiaItem.VencimientoDesdeProduccion)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                    <div> <tr> 
                         <td>{mercaderiaItem.VencimientoDesdeProduccion ? <i class="fa fa-check-square-o" 
                                        aria-hidden="true" 
                                        style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                                : <i class="fa fa-square-o" 
                                        aria-hidden="true" 
                                        style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                        }  </td>
                         <td> <h4>Desde Producción  </h4></td>
                        </tr> 
                        
                         <h5 style={{padding:"0" , marginTop:"0" }}>
                        Desmarque y el cálculo se hará desde Faena
                                        </h5> 
                    </div> 
                  </Link> 
                 
            </div>
      </form>

      <div style={{   padding:"0", alignContent:"center"}} >
               
               <h2 style={{padding:"0",textAlign:"center"}}>
               Congelado
               </h2> 
</div>
      <form className="form" style={{borderColor:"#585858", border:"1px solid #585858", marginTop:"5px"}}>
           
      <td><h5 htmlFor="CantidadVencimiento" style={{marginLeft:"5px"}}>Cantidad: </h5></td>
        
        <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.CongeladoCantidad} onChange={e => Modificar('CongeladoCantidad',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 16 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td>
            
           
            <div> 
            <Link onClick={() => {Modificar('CongeladoPorDia',!mercaderiaItem.CongeladoPorDia)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                    <div><tr>  <td>
                        {mercaderiaItem.CongeladoPorDia ? <i class="fa fa-check-square-o" 
                                        aria-hidden="true" 
                                        style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                                : <i class="fa fa-square-o" 
                                        aria-hidden="true" 
                                        style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                        }    
                        </td>
                         <td> <h4>Por Días  </h4></td>
                        </tr> 
                        
                        <h5 style={{padding:"0" , marginTop:"0" }}>
                        Desmarque y el cálculo se hará por meses
                                        </h5> 
                    </div> 
                  </Link> 
                 
            </div>
         
            <div> 
            <Link onClick={() => {Modificar('CongeladoDesdeProduccion',!mercaderiaItem.CongeladoDesdeProduccion)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                    <div>  <tr><td>
                        {mercaderiaItem.CongeladoDesdeProduccion ? <i class="fa fa-check-square-o" 
                                        aria-hidden="true" 
                                        style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                                : <i class="fa fa-square-o" 
                                        aria-hidden="true" 
                                        style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                        }  
                         </td>
                         <td> <h4>Desde Producción   </h4></td>
                        </tr> 
                         <h5 style={{padding:"0" , marginTop:"0" }}>
                        Desmarque y el cálculo se hará desde Faena
                                        </h5> 
                    </div> 
                  </Link> 
                 
            </div>
      </form>
      </div>
      
    )
}
