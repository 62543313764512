import React,{useState,useEffect} from 'react'
import { IdentificacionGet, IdentificacionUpdate } from '../actions/identificacionActions';
import { useDispatch,useSelector } from 'react-redux';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { CircularProgress, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors'; 
import clsx from 'clsx';
import Mensaje from 'sweetalert';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save'; 
import { IDENTIFICACION_GET_SUCCESS, IDENTIFICACION_UPDATE_RESET } from '../constants/identificacionConstants';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import { Link } from 'react-router-dom';
import {  uploadFileIdentificacion } from '../actions/BlobActions';
import { SelectButton } from 'primereact/selectbutton';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    root2: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    }, 
  }));


export default function IdentificacionSreen(props) {
const identificacion =  useSelector(state => state.identificacion)
const {loading, error, identificacionInfo} = identificacion;
const identificacionUpdate = useSelector((state) => state.identificacionUpdate);
const {loading: loadingSave, success: successSave, error: errorSave} = identificacionUpdate; 
const [Dias, setDias] = useState(null);
const classes = useStyles();
const dispatch = useDispatch();
    useEffect(() => {
      dispatch({type: PANTALLASELECCIONADA, payload:  'Identificacion'}); 
    dispatch({type: PANTALLAATRAS, payload:  ''}); 
      dispatch(IdentificacionGet());
    }, [dispatch]);


    useEffect(() => {
var s = (identificacionInfo && identificacionInfo.sDiasDisponibles) ?identificacionInfo.sDiasDisponibles:"";
  let o =[];
  if(s.includes('0'))
  o.push(0);
  if(s.includes('1'))
  o.push(1);
  if(s.includes('2'))
  o.push(2);
  if(s.includes('3'))
  o.push(3);
  if(s.includes('4'))
  o.push(4);
  if(s.includes('5'))
  o.push(5);
  if(s.includes('6'))
  o.push(6);
  setDias(o)
    }, [dispatch,identificacionInfo]);


    const buttonClassname = clsx({
        [classes.buttonSuccess]: successSave,
      });
      const Guardar = () => { 
        identificacionInfo.sDiasDisponibles=String(Dias); 
         dispatch(IdentificacionUpdate(identificacionInfo));
     };
  
     const Modificar = (campo,e) => 
     {
        identificacionInfo[campo] = e;
       dispatch({type: IDENTIFICACION_GET_SUCCESS, payload:  identificacionInfo});    
     }
    
     useEffect(() => {
        if (successSave) {
            Mensaje({  title: "Exito", text: 'Informacion guardada satisfactoriamente' ,  icon: "success", button: "cerrar" });
            dispatch({ type: IDENTIFICACION_UPDATE_RESET });
        }
        if(errorSave)
        {
          Mensaje({ title: "Error",  text: errorSave, icon: "error", button: "cerrar" });
        }
      }, [dispatch, props.history, successSave,errorSave]);

    const handlefilechange1 = (e) =>{
      let files = e.target.files;
      handfiles(files,1);
    }
    const handlefilechange2 = (e) =>{
      let files = e.target.files;
      handfiles(files,2);
    }
    const handlefilechange3 = (e) =>{
      let files = e.target.files;
      handfiles(files,3);
    }
    const handlefilechange4 = (e) =>{
      let files = e.target.files;
      handfiles(files,4);
    }
    
    const handfiles = (files,Nro) => {
      for(let file of files){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () =>{
        dispatch(uploadFileIdentificacion(file,Nro)); })
      }
    }
    
    const [highlight1,setHighlight1]=useState(false);
    const handlehighlight1 = (e) =>{
      e.preventDefault();
      e.stopPropagation();
      setHighlight1(true);
    }
    const handleunhighlight1 = (e) =>{
      e.preventDefault();
      e.stopPropagation();
      setHighlight1(false);
    }
    const handledrop1 = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        let dt =e.dataTransfer;
        let files = dt.files;
        handfiles(files,1);
        setHighlight1(false);
    }

    const [highlight2,setHighlight2]=useState(false);
    const handlehighlight2 = (e) =>{
      e.preventDefault();
      e.stopPropagation();
      setHighlight2(true);
    }
    const handleunhighlight2 = (e) =>{
      e.preventDefault();
      e.stopPropagation();
      setHighlight2(false);
    }
    const handledrop2 = (e) =>{
      e.preventDefault();
      e.stopPropagation();
      let dt =e.dataTransfer;
      let files = dt.files;
      handfiles(files,2);
      setHighlight2(false);
  }

  const [highlight3,setHighlight3]=useState(false);
    const handlehighlight3 = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    setHighlight3(true);
    }
    const handleunhighlight3 = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    setHighlight3(false);
    }
  const handledrop3 = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    let dt =e.dataTransfer;
    let files = dt.files;
    handfiles(files,3);
    setHighlight3(false);
}

const [highlight4,setHighlight4]=useState(false);
    const handlehighlight4 = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    setHighlight4(true);
    }
    const handleunhighlight4 = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    setHighlight4(false);
    }
const handledrop4 = (e) =>{
  e.preventDefault();
  e.stopPropagation();
  let dt =e.dataTransfer;
  let files = dt.files;
  handfiles(files,4);
  setHighlight4(false);
}
    

const paymentOptions = [
  {name: 'Lunes', value: 1},
  {name: 'Martes', value: 2},
  {name: 'Miercoles', value: 3},
  {name: 'Jueves', value: 4},
  {name: 'Viernes', value: 5},
  {name: 'Sabado', value: 6},
  {name: 'Domingo', value: 0},
];

 
const itemTemplate = (option) => {
  return <h5 style={{margin:1}}>{option.name}</h5>
}

    return (
        loading? <LoadingBox Mensaje={'Buscando Identificacion'}></LoadingBox>:
        error? <MessageBox variant="danger" >{error}</MessageBox>
        :
        <>
<div style={{backgroundColor:"#585858" ,with:"100%",position: 'fixed',zIndex:"1" , marginTop:"10px" }}  >
<div className={classes.root2} style={{ position: 'fixed', top: 0,  right: 0,  justifyContent: 'center', alignItems: 'center', marginTop:"50px"}} >
              <div className={classes.wrapper}>
                  <Fab aria-label="save"  color="primary" className={buttonClassname} onClick={() => Guardar()}  >
                    {successSave ? <CheckIcon /> : <SaveIcon />}
                  </Fab>
                  {loadingSave && <CircularProgress size={68} className={classes.fabProgress} />}
              </div>
            </div>
 
      </div>  

        ({ identificacionInfo &&
           <div className="row top" style={{  padding:"0" , marginTop:"10px" , marginBottom:"50px"}}>
                <div className="col-4">
                <div style={{   padding:"0", alignContent:"center"}} >
               <h2 style={{padding:"0",textAlign:"center"}}>  Contactos  </h2> 
            </div>
            <div style={{alignItems:"center", alignContent:"center", textAlign:"center"}}>
                <h5 htmlFor="Telefono" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>Telefono </h5>  
                <input style={{width:"100%"}}
                id="Telefono"
                type="text"
                required
                placeholder="Ingrese Telefono"
                value={identificacionInfo.sTelefono}
                onChange={(e) => Modificar('sTelefono',e.target.value)} ></input>

                <h5 htmlFor="Web" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>Web </h5>  
                <input style={{width:"100%"}}
                id="Web"
                type="text"
                required
                placeholder="Ingrese Web"
                value={identificacionInfo.sWeb}
                onChange={(e) => Modificar('sWeb',e.target.value)} ></input>

                <h5 htmlFor="WhatsApp" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>WhatsApp </h5>  
                <input style={{width:"100%"}}
                id="WhatsApp"
                type="text"
                required
                placeholder="Ingrese WhatsApp"
                value={identificacionInfo.sWhatsApp}
                onChange={(e) => Modificar('sWhatsApp',e.target.value)} ></input>

                <h5 htmlFor="Mail" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>Mail </h5>  
                <input style={{width:"100%"}}
                id="Mail"
                type="email"
                required
                placeholder="Ingrese Mail"
                value={identificacionInfo.sMailCuenta}
                onChange={(e) => Modificar('sMailCuenta',e.target.value)} ></input>



            <Link onClick={() => {Modificar("bTiendaInicioPorGrupos",!identificacionInfo.bTiendaInicioPorGrupos)}} 
            style={{fontSize: "2.5rem" , color:'black',cursor: "Hand", verticalAlign: "bottom"}}>
                    <div style={{marginTop:"20px"}}> 
                        {identificacionInfo.bTiendaInicioPorGrupos ? <i class="fa fa-check-square-o" 
                                        aria-hidden="true" 
                                        style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                                : <i class="fa fa-square-o" 
                                        aria-hidden="true" 
                                        style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                        } Tienda Inicio Por Grupos
                    </div> 
                </Link> 

                <div style={{alignContent:"left", alignItems:"left", textAlign:"left"}}>
                      <h5 htmlFor="comment" style={{marginLeft:"5px", width:"100%",  textAlign:"start", verticalAlign:"end" , marginBottom:"0"}}>
                        Dias de Apertura </h5>
                <SelectButton value={Dias} itemTemplate={itemTemplate}  options={paymentOptions} onChange={(e) => setDias(e.value)} optionLabel="name" multiple />
                </div>
                <div>
                      <h5 htmlFor="comment" style={{marginLeft:"5px", width:"100%",  textAlign:"start", verticalAlign:"end" , marginBottom:"0"}}>
                        Informacion de la empresa </h5>  
                      <textarea style={{marginLeft:"5px",width:"100%",minHeight:"200px", verticalAlign:"end", marginBottom:"0",}}
                        id="comment" maxLength="3999"
                        value={identificacionInfo.sObservaciones}
                        onChange={(e) => Modificar('sObservaciones',e.target.value)}
                      ></textarea>
                    </div>




                </div>
                </div>


                <div className="col-4">
                <div style={{   padding:"0", alignContent:"center"}} >
               <h2 style={{padding:"0",textAlign:"center"}}>  MercadoPago  </h2> 
            </div>

            <div style={{alignItems:"center", alignContent:"center", textAlign:"center"}}>
                <h5 htmlFor="ClientId" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>Client Id </h5>  
                <input style={{width:"100%"}}
                id="ClientId"
                type="text"
                required
                placeholder="Ingrese Client Id"
                value={identificacionInfo.MPUsuario}
                onChange={(e) => Modificar('MPUsuario',e.target.value)} ></input>

                <h5 htmlFor="ClientSecret" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>Access Secret</h5>  
                <input style={{width:"100%"}}
                id="ClientSecret"
                type="text"
                required
                placeholder="Ingrese Client Secret"
                value={identificacionInfo.MPPass}
                onChange={(e) => Modificar('MPPass',e.target.value)} ></input>

                <h5 htmlFor="AccessToken" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>Client Token </h5>  
                <input style={{width:"100%"}}
                id="AccessToken"
                type="text"
                required
                placeholder="Ingrese Access Token"
                value={identificacionInfo.MPToken}
                onChange={(e) => Modificar('MPToken',e.target.value)} ></input>
            </div>
                </div>
          
          
                <div className="col-4">
                <div style={{   padding:"0", alignContent:"center"}} >
               <h2 style={{padding:"0",textAlign:"center"}}>  Imagenes  </h2> 




               <div className={highlight1? "custom-file-drop-area2 highlight":"custom-file-drop-area2"} onDragEnter={handlehighlight1} 
                onDragOver={handlehighlight1} onDragLeave={handleunhighlight1} onDrop={handledrop1}>
                    <input type="file"name="photos" placeholder="Enter photos"   id="filephotos" onChange={handlefilechange1}/>
                    <label htmlFor="filephotos" >Arrastre o click aqui</label>
                    {identificacionInfo.sLinkPublicidad1 !== "" &&
                    <div className="custom-file-preview">
                   
                 <div className="prev-img"  >
                     <span onClick={() =>Modificar("sLinkPublicidad1","")}>&times;</span>
                     <img className="smallGrilla" src={identificacionInfo.sLinkPublicidad1} alt={identificacionInfo.sLinkPublicidad1}/>
                 </div>
                    </div>}
                </div>

                <div className={highlight2? "custom-file-drop-area2 highlight":"custom-file-drop-area2"} onDragEnter={handlehighlight2} 
                onDragOver={handlehighlight2} onDragLeave={handleunhighlight2} onDrop={handledrop2}>
                    <input type="file"name="photos" placeholder="Enter photos"   id="filephotos" onChange={handlefilechange2}/>
                    <label htmlFor="filephotos" >Arrastre o click aqui</label>
                    {identificacionInfo.sLinkPublicidad2 !== "" &&
                    <div className="custom-file-preview">
                   
                 <div className="prev-img"  >
                     <span onClick={() => Modificar("sLinkPublicidad2","")}>&times;</span>
                     <img className="smallGrilla" src={identificacionInfo.sLinkPublicidad2} alt={identificacionInfo.sLinkPublicidad2}/>
                 </div>
                    </div>}
                </div>


                <div className={highlight3? "custom-file-drop-area2 highlight":"custom-file-drop-area2"} onDragEnter={handlehighlight3} 
                onDragOver={handlehighlight3} onDragLeave={handleunhighlight3} onDrop={handledrop3}>
                    <input type="file"name="photos" placeholder="Enter photos"   id="filephotos" onChange={handlefilechange3}/>
                    <label htmlFor="filephotos" >Arrastre o click aqui</label>
                    {identificacionInfo.sLinkPublicidad3 !== "" &&
                    <div className="custom-file-preview">
                   
                 <div className="prev-img"  >
                     <span onClick={() => Modificar("sLinkPublicidad3","")}>&times;</span>
                     <img className="smallGrilla" src={identificacionInfo.sLinkPublicidad3} alt={identificacionInfo.sLinkPublicidad3}/>
                 </div>
                    </div>}
                </div>

                <div className={highlight4? "custom-file-drop-area2 highlight":"custom-file-drop-area2"} onDragEnter={handlehighlight4} 
                onDragOver={handlehighlight4} onDragLeave={handleunhighlight4} onDrop={handledrop4}>
                    <input type="file"name="photos" placeholder="Enter photos"   id="filephotos" onChange={handlefilechange4}/>
                    <label htmlFor="filephotos" >Arrastre o click aqui</label>
                    {identificacionInfo.sLinkPublicidad4 !== "" &&
                    <div className="custom-file-preview">
                   
                 <div className="prev-img"  >
                     <span onClick={() => Modificar("sLinkPublicidad4","")}>&times;</span>
                     <img className="smallGrilla" src={identificacionInfo.sLinkPublicidad4} alt={identificacionInfo.sLinkPublicidad4}/>
                 </div>
                    </div>}
                </div>

            </div>
            </div>
          
          
            </div>
            }
            </>
    )
}
     