import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { signin } from '../actions/operadorActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

export default function LoginPantalla(props) {
    const redirect = props.location.search? props.location.search.split('=')[1]: '/Menu';
    const [email, setEmail] = useState(''); 
  const [password, setPassword] = useState('');

  const operadorSignin = useSelector((state) => state.operadorSignin);
  const {operadorInfo, loading, error} = operadorSignin;

const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email,password));
  };

   useEffect(() => {
    
     if (operadorInfo && operadorInfo.Id) {
       props.history.push(redirect);
     }
   }, [props.history,redirect, operadorInfo]);

  return (
    <div>
      <form className="form" onSubmit={submitHandler}>
        <div>
          <h1>Iniciar Sesión</h1>
        </div>

        {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger">{error}</MessageBox>}


        <div>
          <label htmlFor="email">Correo Electronico</label>
          <input
            type="email"
            id="email"
            placeholder="Ingrese su correo electronico"
            required
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Ingrese su password"
            required
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <div>
          <label />
          <button className="primary" type="submit">
          Iniciar Sesión
          </button>
        </div>

      </form>
    </div>
  );
}