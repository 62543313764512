import {  
    FUNCIONALIDADES_LISTA_FAIL,
    FUNCIONALIDADES_LISTA_REQUEST,
    FUNCIONALIDADES_LISTA_SUCCESS, 
  } from '../constants/funcionalidadesConstants';
  
  export const funcionalidadesGetReducer = ( state = { loading:true,  funcionalidadesLista: [] },  action) => {
    switch (action.type) {
      case FUNCIONALIDADES_LISTA_REQUEST:
        return { loading: true };
      case FUNCIONALIDADES_LISTA_SUCCESS:
        return { loading: false, funcionalidadesLista: action.payload };
      case FUNCIONALIDADES_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };