 
import { PEDIDO_APROBAR_REQUEST, PEDIDO_LISTA_FAIL, PEDIDO_LISTA_REQUEST, PEDIDO_LISTA_SUCCESS,
  PEDIDO_APROBAR_RESET,PEDIDO_APROBAR_SUCCESS,PEDIDO_APROBAR_FAIL, PEDIDO_CERRAR_REQUEST, PEDIDO_CERRAR_SUCCESS, PEDIDO_CERRAR_FAIL, PEDIDO_CERRAR_RESET, PEDIDO_ELIMINAR_REQUEST, PEDIDO_ELIMINAR_SUCCESS, PEDIDO_ELIMINAR_FAIL, PEDIDO_ELIMINAR_RESET } from '../constants/pedidoConstants';
 
    export const pedidoReducers = ( state = { loading:true,  pedidosLista: [] },  action) => {
    switch (action.type) {
      case PEDIDO_LISTA_REQUEST:
        return { loading: true };
      case PEDIDO_LISTA_SUCCESS:
        return { loading: false, pedidosLista: action.payload };
      case PEDIDO_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const pedidoAprobarReducer = (state = {}, action) => {
    switch (action.type) {
      case PEDIDO_APROBAR_REQUEST:
        return { loading: true };
      case PEDIDO_APROBAR_SUCCESS:
        return { loading: false, success: true  };
      case PEDIDO_APROBAR_FAIL:
        return { loading: false, error: action.payload };
        case PEDIDO_APROBAR_RESET:
          return {};
      default:
        return state;
    }
  };
 
  export const pedidoCerrarReducer = (state = {}, action) => {
    switch (action.type) {
      case PEDIDO_CERRAR_REQUEST:
        return { loading: true };
      case PEDIDO_CERRAR_SUCCESS:
        return { loading: false, success: true  };
      case PEDIDO_CERRAR_FAIL:
        return { loading: false, error: action.payload };
        case PEDIDO_CERRAR_RESET:
          return {};
      default:
        return state;
    }
  };
 
  export const pedidoEliminarReducer = (state = {}, action) => {
    switch (action.type) {
      case PEDIDO_ELIMINAR_REQUEST:
        return { loading: true };
      case PEDIDO_ELIMINAR_SUCCESS:
        return { loading: false, success: true  };
      case PEDIDO_ELIMINAR_FAIL:
        return { loading: false, error: action.payload };
        case PEDIDO_ELIMINAR_RESET:
          return {};
      default:
        return state;
    }
  };