import React, {  useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useDispatch,useSelector } from 'react-redux';
import { getMercaderia, updateMercaderias } from '../actions/mercaderiaActions'; 
import MessageBox from '../components/MessageBox'; 
import MercaderiaDetalleFechas from '../components/MercaderiaDetalle/MercaderiaDetalleFechas';
import MercaderiaDetalleLimites from '../components/MercaderiaDetalle/MercaderiaDetalleLimites';
import MercaderiaDetalleEtiquetas from '../components/MercaderiaDetalle/MercaderiaDetalleEtiquetas';
import MercaderiaDetalleFrigo from '../components/MercaderiaDetalle/MercaderiaDetalleFrigo';
import MercaderiaDetalleOtros from '../components/MercaderiaDetalle/MercaderiaDetalleOtros';
import MercaderiaDetalleAppPedidos from '../components/MercaderiaDetalle/MercaderiaDetalleAppPedidos';
import MercaderiaDetalleMinimos from '../components/MercaderiaDetalle/MercaderiaDetalleMinimos';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors'; 
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save'; 
import Mensaje from 'sweetalert';
import { MERCADERIA_UPDATE_RESET } from '../constants/mercaderiaConstants';
import MercaderiaDetalleAppCaracteristicas from '../components/MercaderiaDetalle/MercaderiaDetalleAppCaracteristicas';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import Backdrop from '@material-ui/core/Backdrop';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`wrapped-tabpanel-${index}`}
    aria-labelledby={`wrapped-tab-${index}`}
    {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`, 'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {flexGrow: 1,width: '100%',backgroundColor: theme.palette.background.paper,},
  root2: {display: 'flex',alignItems: 'center',},
  wrapper: {margin: theme.spacing(1),position: 'relative',},
  buttonSuccess: {backgroundColor: green[500],'&:hover': {backgroundColor: green[700],},},
  fabProgress: {color: green[500],position: 'absolute',top: -6,left: -6,zIndex: 1,},
  buttonProgress: {color: green[500],position: 'absolute',top: '50%',left: '50%',marginTop: -12,marginLeft: -12,}, 
}));
export default function MercaderiaAppDetalleSreen(props) {
  const MercaderiaId = props.match.params.id;
  const MercaderiaCodigo = props.match.params.codigo;
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {  setValue(newValue); };
  const mercaderiaGet =  useSelector(state => state.mercaderiaGet)
  const {  error, mercaderiaItem} = mercaderiaGet;

  useEffect(() => { 
      dispatch(getMercaderia(MercaderiaId,MercaderiaCodigo)); 
 },[dispatch,MercaderiaId,MercaderiaCodigo]) 

 useEffect(()=>{
  dispatch({type: PANTALLASELECCIONADA, payload: (props.match.params.id === "0" ? 'Nueva Mercaderia' : "Editando Mercaderia Id : " + props.match.params.id)  }); 
  dispatch({type: PANTALLAATRAS, payload:  'mercaderiasapp'}); 
},[dispatch,props.match.params.id])

const [NombreFoto, setNombreFoto] = React.useState("");
 const mercaderiaUpdate = useSelector((state) => state.mercaderiaUpdate);
 const {loading: loadingSave, success: successSave, error: errorSave} = mercaderiaUpdate; 
 const mercaderiaSubiendoFotos = useSelector((state) => state.mercaderiaSubiendoFotos);
 const {loading: loadingFotos , archivo  } = mercaderiaSubiendoFotos; 

 useEffect(()=>{ 
   setNombreFoto(archivo);
  setOpen(loadingFotos);
},[dispatch,loadingFotos,archivo])

 
 const [Codigo, setCodigo ]= React.useState('');
 const Guardar = () => { 
      if (mercaderiaItem.sCodigo === "") {
         Mensaje({title: "Debe Cargar el Codigo",text: "",icon: "error",button: "cerrar",}); 
            return;}             
       if (mercaderiaItem.sDescripcion === "") {
          Mensaje({title: "Debe Cargar la descripcion",text: "",icon: "error",button: "cerrar",});
            return;}  
     if (mercaderiaItem.lstCaracteristicasOpciones1.find(r => r.bActivo === true) && mercaderiaItem.Caracteristicas1.sDescripcion === "") {
          Mensaje({title: "Debe Cargar la descripcion de la caracteristica 1 en la solapa de Caracteristicas. Si no desea asignar categorias desactive los items",text: "",icon: "error",button: "cerrar",});
            return;}
     if (mercaderiaItem.lstCaracteristicasOpciones2.find(r => r.bActivo === true) && mercaderiaItem.Caracteristicas2.sDescripcion === "") {
      Mensaje({title: "Debe Cargar la descripcion de la caracteristica 2 en la solapa de Caracteristicas. Si no desea asignar categorias desactive los items",text: "",icon: "error",button: "cerrar",});
          return;}
       setCodigo(mercaderiaItem.sCodigo);
       if(mercaderiaItem.Id === 0)
       {
        mercaderiaItem.bActivo = true;
        if(mercaderiaItem.nStock === 0){mercaderiaItem.nStock=1}
        if(mercaderiaItem.iPresentacionUnidadId === 0){mercaderiaItem.iPresentacionUnidadId =mercaderiaItem.MercaderiaUnidad_Id}
       }
       dispatch(updateMercaderias(mercaderiaItem));   
   };
  const buttonClassname = clsx({
    [classes.buttonSuccess]: successSave,
  });
  useEffect(() => {
    if (successSave) {
      Mensaje({title: "Exito",text: 'La mercaderia ha sido guardada con exito' ,icon: "success",button: "cerrar",});
      dispatch({ type: MERCADERIA_UPDATE_RESET });
      props.history.push(`/mercaderiasapp`);
    }
    if(errorSave)
    {
      Mensaje({title: "Error",text: errorSave,icon: "error",button: "cerrar",});
    }
  }, [dispatch, props.history, successSave,errorSave,MercaderiaId,Codigo ]);
  return (
    <div className={classes.root}  >    
  <Backdrop className={classes.backdrop} open={open} style={{zIndex:20000}} >
        <CircularProgress color="inherit" text={NombreFoto} /><h5>{" Procesando imagenes. Por favor aguarde unos instantes"}</h5>
      </Backdrop>
<div className={classes.root2} style={{ position: 'fixed', top: 0,  right: 0,  justifyContent: 'center', alignItems: 'center', marginTop:"130px",zIndex:"3"}} >
              <div className={classes.wrapper}>
                  <Fab aria-label="save"  color="primary" className={buttonClassname} onClick={() => Guardar()}  >
                    {successSave ? <CheckIcon /> : <SaveIcon />}
                  </Fab>
                  {loadingSave && <CircularProgress size={68} className={classes.fabProgress} />}
              </div>
            </div>

      <AppBar style={{marginTop:"56px",backgroundColor:"#585858",zIndex:"2" }}  >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable force tabs example">
          <Tab  label="Datos Minimos" icon={<i style={{fontSize:"3rem" }} class="fa fa-info-circle" aria-hidden="true"></i>} {...a11yProps(0)} />
          <Tab label="Fechas" icon={<i style={{fontSize:"3rem" }} class="fa fa-calendar" aria-hidden="true"></i>} {...a11yProps(1)} />
          <Tab label="Limites" icon={<i style={{fontSize:"3rem" }} class="fa fa-balance-scale" aria-hidden="true"></i>} {...a11yProps(2)} />
          <Tab label="Etiquetas" icon={<i style={{fontSize:"3rem" }} class="fa fa-tag" aria-hidden="true"></i>} {...a11yProps(3)} />
          <Tab label="Frigo" icon={<i style={{fontSize:"3rem" }} class="fa fa-snowflake-o" aria-hidden="true"></i>} {...a11yProps(4)} />
          <Tab label="Otros" icon={<i style={{fontSize:"3rem" }} class="fa fa-question-circle" aria-hidden="true"></i>} {...a11yProps(5)} />
          <Tab label="App Pedidos" icon={<i style={{fontSize:"3rem" }} class="fa fa-shopping-cart" aria-hidden="true"></i>} {...a11yProps(6)} />
          <Tab label="Caracteristicas" icon={<i style={{fontSize:"3rem" }} class="fa fa-paint-brush" aria-hidden="true"></i>} {...a11yProps(7)} />
        </Tabs>
      </AppBar>
      <div style={{marginTop:"30px" }}>      
  
 
              {error?<MessageBox variant="danger">{error}</MessageBox>
              :
 <div  style={{ zIndex:"0"  }}>
      <TabPanel value={value} index={0}><div  style={{marginTop:"60px"  }} > <MercaderiaDetalleMinimos/> </div> </TabPanel>
      <TabPanel value={value} index={1}> <div  style={{marginTop:"60px"  }} ><MercaderiaDetalleFechas/></div> </TabPanel>
      <TabPanel value={value} index={2}><div  style={{marginTop:"60px"  }} > <MercaderiaDetalleLimites/> </div></TabPanel>
      <TabPanel value={value} index={3}> <div  style={{marginTop:"60px"  }} ><MercaderiaDetalleEtiquetas/></div> </TabPanel>
      <TabPanel value={value} index={4}> <div  style={{marginTop:"60px"  }} ><MercaderiaDetalleFrigo/></div> </TabPanel>
      <TabPanel value={value} index={5}> <div  style={{marginTop:"60px"  }} ><MercaderiaDetalleOtros/></div> </TabPanel>
      <TabPanel value={value} index={6}> <div  style={{marginTop:"60px"  }} ><MercaderiaDetalleAppPedidos/> </div></TabPanel>
      <TabPanel value={value} index={7}> <div  style={{marginTop:"60px"  }} ><MercaderiaDetalleAppCaracteristicas/> </div></TabPanel>
      </div>}
      </div>  
    </div>    
  );
}