import React,{useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CircularProgress, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors'; 
import clsx from 'clsx';
import Mensaje from 'sweetalert';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import { updateFamilias } from '../actions/familiaActions';
import { FAMILIA_UPDATE_RESET } from '../constants/familiaConstants';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    root2: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    }, 
  }));


export default function FamiliaDetallePantalla(props) {
  const FamiliaId = props.match.params.id;

  const familiaUpdate = useSelector((state) => state.familiaUpdate);
  const {loading:loadingSave, success, error:errorSave} = familiaUpdate;

  const familias =  useSelector(state => state.familias)
  const {  familiasLista} = familias;
  let dtFamilia = {
      Error: '',
      Id: 0,
      sCodigo: '',
      sDescripcion: '',
      bActivo: true,
      bCalibrado: false,
      bEmitePrimarias: false,
      bLlevaBanderita: false
    };
  if(FamiliaId > 0)
  {
  dtFamilia = familiasLista.find(l => {
      return  l.Id === Number(FamiliaId)});
  }

  const [Codigo, setCodigo] = useState(dtFamilia.sCodigo);
  const [Descripcion, setDescripcion] = useState(dtFamilia.sDescripcion);
  const [Activo, setActivo] = useState(dtFamilia.bActivo);
  const [Calibrado, setCalibrado] = useState(dtFamilia.bCalibrado);
  const [EmitePrimarias, setEmitePrimarias] = useState(dtFamilia.bEmitePrimarias);
  const [LlevaBanderita, setLlevaBanderita] = useState(dtFamilia.bLlevaBanderita);

const classes = useStyles();
const dispatch = useDispatch();

useEffect(()=>{
  dispatch({type: PANTALLASELECCIONADA, payload: (props.match.params.id === "0" ? 'Nueva Familia' : "Editando Familia Id : " + props.match.params.id)  }); 
  dispatch({type: PANTALLAATRAS, payload:  'familias'}); 
},[dispatch,props.match.params.id])


const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
      });
      const Guardar = () => { 
        if (Codigo === "") {
          Mensaje({
                title: "Debe Cargar el Codigo",
                text: "",
                icon: "error",
                button: "cerrar",
              });}             
      else if (Descripcion === "") {
                  Mensaje({
                        title: "Debe Cargar la descripcion",
                        text: "",
                        icon: "error",
                        button: "cerrar",
                      });
      } else {
            dtFamilia.sCodigo=Codigo;
            dtFamilia.sDescripcion=Descripcion;
            dtFamilia.bActivo=Activo;
            dtFamilia.bCalibrado=Calibrado;
            dtFamilia.bEmitePrimarias=EmitePrimarias;
            dtFamilia.bLlevaBanderita=LlevaBanderita;
          dispatch(updateFamilias(dtFamilia));
          
      } 
     };

     useEffect(() => {
        if (success) {
            Mensaje({  title: "Exito", text: 'Familia guardada satisfactoriamente' ,  icon: "success", button: "cerrar" });
            dispatch({ type: FAMILIA_UPDATE_RESET });
            props.history.push(`/familias`);
        }
        if(errorSave)
        {
          Mensaje({ title: "Error",  text: errorSave, icon: "error", button: "cerrar" });
        }
      }, [dispatch, props.history, success,errorSave]);

    return (
        <>
<div style={{backgroundColor:"#585858" ,with:"100%",position: 'fixed',zIndex:"1" , marginTop:"10px" }}  >
<div className={classes.root2} style={{ position: 'fixed', top: 0,  right: 0,  justifyContent: 'center', alignItems: 'center', marginTop:"50px"}} >
              <div className={classes.wrapper}>
                  <Fab aria-label="save"  color="primary" className={buttonClassname} onClick={() => Guardar()}  >
                    {success ? <CheckIcon /> : <SaveIcon />}
                  </Fab>
                  {loadingSave && <CircularProgress size={68} className={classes.fabProgress} />}
              </div>
            </div>
      </div>  
        ({  

<div className="row top" style={{marginTop:"10px" , marginLeft:"20px" }} > 
<form className="form"  style={{width:"100%" }} >  
        {dtFamilia.Id > 0 && <>
        <Link onClick={() => {setActivo(!Activo)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
        <div> 
            {Activo ? <i class="fa fa-check-square-o" 
                            aria-hidden="true" 
                            style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                    : <i class="fa fa-square-o" 
                            aria-hidden="true" 
                            style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
            } Activo
        </div> 
    </Link> 
    </>}    
    <div> 
       <h5 htmlFor="Codigo" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>Codigo </h5>  
                <input  style={{width:"50%" }}
                id="Codigo"
                type="text"
                required
                placeholder="Ingrese Codigo"
                value={Codigo}
                onChange={(e) => setCodigo( e.target.value)} ></input>
</div> 
<div> 
<h5 htmlFor="Descripcion" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>Descripcion </h5>  
                <input  style={{width:"100%" }}
                id="Descripcion"
                type="text"
                required
                placeholder="Ingrese Descripcion"
                value={Descripcion}
                onChange={(e) => setDescripcion( e.target.value)} ></input>
</div> 
    <Link  onClick={() => {  setCalibrado(!Calibrado) }} style={   { fontSize: "2rem", color:'black'  ,cursor: "Hand" , verticalAlign: "bottom"  , marginTop: "5px"}}>
    <div> 
    {Calibrado   ? <i class="fa fa-check-square-o" aria-hidden="true"  style={{ fontSize: "3rem",marginRight:"10px", color:'black', verticalAlign: "bottom"}}/>
                 : <i class="fa fa-square-o" aria-hidden="true"  style={   { fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/>} 
                 Calibrado
    </div>
       
    </Link>

    <Link onClick={() => {  setEmitePrimarias(!EmitePrimarias) }}   style={   { fontSize: "2rem", color:'black'  ,cursor: "Hand" , verticalAlign: "bottom"  , marginTop: "5px"}}>
     
    {EmitePrimarias ? <i  class="fa fa-check-square-o" aria-hidden="true" style={{ fontSize: "3rem",marginRight:"10px", color:'black', verticalAlign: "bottom"}}/> 
                    : <i  class="fa fa-square-o" aria-hidden="true"  style={{ fontSize: "3rem",marginRight:"10px", color:'black', verticalAlign: "bottom"}} /> } 
                  Emite Primarias      
        
    </Link>

    <Link onClick={() => {  setLlevaBanderita(!LlevaBanderita) }}  style={   { fontSize: "2rem", color:'black'    , verticalAlign: "bottom"  , marginTop: "5px"}}>
        <div> 
    {LlevaBanderita ? <i  class="fa fa-check-square-o" aria-hidden="true"  style={   { fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                : <i  class="fa fa-square-o" aria-hidden="true"  style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> }  
               Lleva Banderita 
        </div>
    </Link>
</form>
</div>

            }
            </>
    )
}
     