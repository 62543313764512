 
import React, {useState, useEffect,useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import MessageBox from '../components/MessageBox';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import { Column } from 'primereact/column';
import Moment from 'moment';
import { getlistaPedidos, ReportePedido } from '../actions/pedidosActions';
import { Toast } from 'primereact/toast';  
import PedidoAprobar from '../components/Pedidos/PedidoAprobar';
import PedidoEliminar from '../components/Pedidos/PedidoEliminar';
import PedidoCerrar from '../components/Pedidos/PedidoCerrar';
import { Calendar } from 'primereact/calendar';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import { Toolbar } from 'primereact/toolbar';
 
export default function PedidosScreen(props) {
    const pedidos =  useSelector(state => state.pedidos)
    const {loading, error, pedidosLista} = pedidos;
    const [expandedRows, setExpandedRows] = useState(null);
    const isMounted = useRef(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const [selectedPedido, setSelectedPedido] = useState(null);
    const [FechaDesde, setFechaDesde] = useState();
    const [FechaHasta, setFechaHasta] = useState();
    const [loadingdata, setLoadingdata] = useState(false);

    const onChangeDesde = (event) => {
        setFechaDesde(event.value); 
        dispatch(getlistaPedidos(event.value, FechaHasta));
    }
   
    const onChangeHasta = (event) => {  
        setFechaHasta(event.value); 
        dispatch(getlistaPedidos(FechaDesde, event.value));
    }
    const dt = useRef(null);
    const dispatch = useDispatch(); 

    useEffect(() =>{
        setLoadingdata(loading);
    },[dispatch,loading]
    )
    
    const Imprimir = () =>{
        dispatch(ReportePedido( selectedPedido.Id));
    } 
     
    


    useEffect(() => {
        isMounted.current = true;
        var d = new Date();
        d.setDate(d.getDate());
        var h = new Date();
        h.setDate(h.getDate());
        setFechaHasta(h);
        setFechaDesde(d);
        dispatch({type: PANTALLASELECCIONADA, payload:  'Pedidos'}); 
        dispatch({type: PANTALLAATRAS, payload:  ''}); 
      dispatch(getlistaPedidos(d, h));
    }, [dispatch]);

      const IdBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Id</span>
                <label style={{fontSize:"20px", color:"black", fontWeight:"bold"}}>
                    {rowData.Id}
                    </label>
            </React.Fragment>
        );
      }

      const FechaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Fecha</span>
                <label style={{ color:"black", fontWeight:"bold"}}>
                {Moment(rowData.dFechaEntrega).format('DD/MM/YYYY')}
                    </label>
            </React.Fragment>
        );
      }
      
      const ClienteDescripcionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Cliente</span>
                <label style={{color:"black", fontWeight:"bold"}}>
                    {rowData.ClienteDescripcion}
                    </label>
            </React.Fragment>
        );
      }
  
      const ProvinciaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Provincia</span>
                <label style={{color:"black", fontWeight:"bold"}}>
                    {rowData.Provincia}
                    </label>
            </React.Fragment>
        );
      }

      const EstadoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Estado</span>
                <label style={{color:"black", fontWeight:"bold"}}>
                    {rowData.Estado}
                    </label>
            </React.Fragment>
        );
      }

      const LocalidadBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Localidad</span>
                <label style={{ color:"black", fontWeight:"bold"}}>
                    {rowData.Localidad}
                    </label>
            </React.Fragment>
        );
      }

      const DireccionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Direccion</span>
                <label style={{color:"black", fontWeight:"bold"}}>
                    {rowData.Direccion}
                    </label>
            </React.Fragment>
        );
      }

      const TelefonoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Telefono</span>
                <label style={{color:"black", fontWeight:"bold"}}>
                    {rowData.Telefono}
                    </label>
            </React.Fragment>
        );
      }

      const TotalPedidoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Total</span>
       
                <label style={{ color:"black", fontWeight:"bold"}}>
                    {rowData.TotalPedido}
                    </label>
            </React.Fragment>
        );
      }

      const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable" style={{textAlign:"center" }}>
                <h5>Detalle del pedido {String(data.Id)}</h5>
                <DataTable value={data.lstDetalle} style={{textAlign:"center",backgroundColor:"red",padding:"0px",margin:"0px"}}>
                <Column  field="LinkFoto"   body={imagenBodyTemplate} headerStyle={{width:"15rem", textAlign: 'center' }}  />
                <Column field="nCantidad" header="Cantidad"   headerStyle={{width:"4rem", textAlign: 'center' }}></Column>
                <Column field="Mercaderia_Descripcion" header="Mercaderia"  ></Column>    
                </DataTable>
            </div>
        );
    }
    const onRowCollapse = (event) => {
        toast.current.show({severity: 'success', summary: 'Pedido Plegado', detail: event.data.Id, life: 3000});
    }
  
    const onRowExpand = (event) => {
        toast.current.show({severity: 'info', summary: 'Pedido Desplegado', detail: event.data.Id, life: 3000});
    }
    const imagenBodyTemplate = (rowData, column) => {
        return (
          <React.Fragment>
                <span className="p-column-title"></span>        
                <img class="small" src={rowData.LinkFoto} alt=''  />                
            </React.Fragment>);
      }

      const rowClass = (data) => {
          if(data.Id === (selectedPedido ? selectedPedido.Id : 0))
          {
        return {
            
        }
    }
    else if(data.bEliminado === true)
    {
        return {
            'row-eliminado': data.bEliminado === true
        }
    }
    else if(data.bCerrado === true)
    {
        return {
            'row-cerrado': data.bCerrado === true
        }
    }
    else if(data.bAprobado === true)
    {
        return {
            'row-aprobado': data.bAprobado === true
        }
    }
    else if(data.bAprobado === false)
    {
        return {
            'row-sinaprobar': data.bAprobado === false
        }
    }
    }

    const leftContents = (
        <React.Fragment>
  <label style={{color:"white"}}>Desde</label>
        <Calendar value={FechaDesde} onChange={onChangeDesde}   
                        placeholder="Desde" dateFormat="dd/mm/yy" className="p-column-filter" showIcon/>
                             <label style={{color:"white"}}>Hasta</label>
                        <Calendar value={FechaHasta} onChange={onChangeHasta} style={{with:"auto"}} showIcon  placeholder="Hasta" dateFormat="dd/mm/yy" className="p-column-filter" />    
        </React.Fragment>
    );

    const leftContents2 = (
        <React.Fragment>
                <PedidoAprobar  Id={selectedPedido ? selectedPedido.Id :0}   bAprobado={selectedPedido ? selectedPedido.bAprobado :true} bEliminado={selectedPedido ? selectedPedido.bEliminado :true} />
                <PedidoEliminar Id={selectedPedido ? selectedPedido.Id :0}   bEliminado={selectedPedido ? selectedPedido.bEliminado :true} MercadoPagoId={selectedPedido ? selectedPedido.MercadoPagoId :0} />
                <PedidoCerrar Id={selectedPedido ? selectedPedido.Id :0}   bCerrado={selectedPedido ? selectedPedido.bCerrado :true} bEliminado={selectedPedido ? selectedPedido.bEliminado :true} />    

                {selectedPedido &&   <button className="primary" type="submit" style={{backgroundColor:"#43AF7A",margin:'0px',padding:'3px', marginLeft:"5px"}} onClick={Imprimir}>Imprimir</button> }
        </React.Fragment>
    );

    const rightContent2 = (
        <React.Fragment>
        <span style={{margin:"5px"}}  className="p-input-icon-left">
                        <i  className="pi pi-search" />
                        <InputText     style={   { fontSize: "1.5rem", color:'black',width:"80px"    }   }   
                        type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                    </span> 
                    </React.Fragment> 
    );
  
    return (error? <MessageBox variant="danger" >{error}</MessageBox>
                :
                (<>
                    <div style={{  padding:"0px" ,position: 'fixed',zIndex: 2, width: '100%',marginTop:'20px'}}>
                    <Toolbar left={leftContents}  style={{ backgroundColor:"#179F5D", margin:0,padding:0, fontSize:'16px', fontWeight:"bold", width:"auto"}}>
                    </Toolbar>   
                </div>
                <div style={{  padding:"0px" ,position: 'fixed',zIndex: 1, width: '100%',marginTop:'46px'}}>
                    <Toolbar left={leftContents2} right={rightContent2} style={{ backgroundColor:"#FA9100", margin:0,padding:0, fontSize:'16px',fontWeight:"bold", width:"auto"}}>
                    </Toolbar>   
                </div>             
               
                    <div style={{marginBottom:'50px',marginTop:'90px' }}>
                        <Toast ref={toast} />
                <div className="datatable-doc-demo">
 
                <DataTable ref={dt} value={pedidosLista} className="p-datatable-customers" dataKey="Id"
                rowHover globalFilter={globalFilter} loading={loadingdata}
                selection={selectedPedido} onSelectionChange={e => setSelectedPedido(e.value)} selectionMode="single"
                paginator rows={10} emptyMessage="No se encontraron Pedidos" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
                rowExpansionTemplate={rowExpansionTemplate}  rowClassName={rowClass}
                expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} resizableColumns columnResizeMode="expand"
                                    onRowExpand={onRowExpand} onRowCollapse={onRowCollapse}
                >
                <Column expander style={{ width: '3em' }} />
                <Column field="Id" header="Id"  body={IdBodyTemplate} headerStyle={{ width:"8rem", textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
                <Column  field="Estado" header="Estado" body={EstadoBodyTemplate}  />
                <Column field="dFechaEntrega" header="Fecha" body={FechaBodyTemplate} sortable   />
                <Column field="ClienteDescripcion" header="Cliente"  body={ClienteDescripcionBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
                <Column field="Provincia" header="Provincia"  body={ProvinciaBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
                <Column field="Localidad" header="Localidad"  body={LocalidadBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
                <Column field="Direccion" header="Direccion"  body={DireccionBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
                <Column field="Telefono" header="Telefono"  body={TelefonoBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
                <Column field="TotalPedido" header="Total"  body={TotalPedidoBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
                </DataTable>       
</div>
</div></>)
);

}