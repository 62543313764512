import {  
  COMBO_CLASIFICACIONES_FAIL,
  COMBO_CLASIFICACIONES_REQUEST,
  COMBO_CLASIFICACIONES_SUCCESS,
  COMBO_DEFINICIONESCUARTO_FAIL,
  COMBO_DEFINICIONESCUARTO_REQUEST,
  COMBO_DEFINICIONESCUARTO_SUCCESS,
  COMBO_DIAS_SUCCESS,
  COMBO_ETIQUETAS_FAIL,
  COMBO_ETIQUETAS_REQUEST,
  COMBO_ETIQUETAS_SUCCESS,
  COMBO_FAMILIAS_FAIL,
  COMBO_FAMILIAS_REQUEST,
  COMBO_FAMILIAS_SUCCESS,
  COMBO_GRUPOSMERCADERIA_FAIL,
  COMBO_GRUPOSMERCADERIA_REQUEST,
  COMBO_GRUPOSMERCADERIA_SUCCESS,
  COMBO_IMPUESTOS_FAIL,
  COMBO_IMPUESTOS_REQUEST,
  COMBO_IMPUESTOS_SUCCESS,
  COMBO_MERCADERIASAFIP_FAIL,
  COMBO_MERCADERIASAFIP_REQUEST,
  COMBO_MERCADERIASAFIP_SUCCESS,
  COMBO_MERCADERIAS_FAIL,
  COMBO_MERCADERIAS_REQUEST,
  COMBO_MERCADERIAS_SUCCESS,
  COMBO_MERCADERIATIPOS_FAIL,
  COMBO_MERCADERIATIPOS_REQUEST,
  COMBO_MERCADERIATIPOS_SUCCESS,
  COMBO_METODOSPAGO_FAIL,
  COMBO_METODOSPAGO_REQUEST,
  COMBO_METODOSPAGO_SUCCESS,
  COMBO_NOMENCLADORES_FAIL,
  COMBO_NOMENCLADORES_REQUEST,
  COMBO_NOMENCLADORES_SUCCESS,
    COMBO_UNIDADES_FAIL,
    COMBO_UNIDADES_REQUEST,
    COMBO_UNIDADES_SUCCESS, 
  } from '../constants/comboConstants';
  
  export const comboUnidadesReducer = ( state = { loading:true,  UnidadesCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_UNIDADES_REQUEST:
        return { loading: true };
      case COMBO_UNIDADES_SUCCESS:
        return { loading: false, UnidadesCombo: action.payload };
      case COMBO_UNIDADES_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const comboMercaderiaTiposReducer = ( state = { loading:true,  MercaderiaTiposCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_MERCADERIATIPOS_REQUEST:
        return { loading: true };
      case COMBO_MERCADERIATIPOS_SUCCESS:
        return { loading: false, MercaderiaTiposCombo: action.payload };
      case COMBO_MERCADERIATIPOS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const comboFamiliasReducer = ( state = { loading:true,  FamiliasCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_FAMILIAS_REQUEST:
        return { loading: true };
      case COMBO_FAMILIAS_SUCCESS:
        return { loading: false, FamiliasCombo: action.payload };
      case COMBO_FAMILIAS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const comboImpuestosReducer = ( state = { loading:true,  ImpuestosCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_IMPUESTOS_REQUEST:
        return { loading: true };
      case COMBO_IMPUESTOS_SUCCESS:
        return { loading: false, ImpuestosCombo: action.payload };
      case COMBO_IMPUESTOS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const comboEtiquetasReducer = ( state = { loading:true,  EtiquetasCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_ETIQUETAS_REQUEST:
        return { loading: true };
      case COMBO_ETIQUETAS_SUCCESS:
        return { loading: false, EtiquetasCombo: action.payload };
      case COMBO_ETIQUETAS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const comboDefinicionesCuartoReducer = ( state = { loading:true,  DefinicionesCuartoCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_DEFINICIONESCUARTO_REQUEST:
        return { loading: true };
      case COMBO_DEFINICIONESCUARTO_SUCCESS:
        return { loading: false, DefinicionesCuartoCombo: action.payload };
      case COMBO_DEFINICIONESCUARTO_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const comboNomencladoresReducer = ( state = { loading:true,  NomencladoresCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_NOMENCLADORES_REQUEST:
        return { loading: true };
      case COMBO_NOMENCLADORES_SUCCESS:
        return { loading: false, NomencladoresCombo: action.payload };
      case COMBO_NOMENCLADORES_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const comboClasificacionesReducer = ( state = { loading:true,  ClasificacionesCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_CLASIFICACIONES_REQUEST:
        return { loading: true };
      case COMBO_CLASIFICACIONES_SUCCESS:
        return { loading: false, ClasificacionesCombo: action.payload };
      case COMBO_CLASIFICACIONES_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const comboMercaderiasAfipReducer = ( state = { loading:true,  MercaderiasAfipCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_MERCADERIASAFIP_REQUEST:
        return { loading: true };
      case COMBO_MERCADERIASAFIP_SUCCESS:
        return { loading: false, MercaderiasAfipCombo: action.payload };
      case COMBO_MERCADERIASAFIP_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const comboGruposMercaderiaReducer = ( state = { loading:true,  GruposMercaderiaCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_GRUPOSMERCADERIA_REQUEST:
        return { loading: true };
      case COMBO_GRUPOSMERCADERIA_SUCCESS:
        return { loading: false, GruposMercaderiaCombo: action.payload };
      case COMBO_GRUPOSMERCADERIA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const comboMercaderiasReducer = ( state = { loading:true,  MercaderiasCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_MERCADERIAS_REQUEST:
        return { loading: true };
      case COMBO_MERCADERIAS_SUCCESS:
        return { loading: false, MercaderiasCombo: action.payload };
      case COMBO_MERCADERIAS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const comboDiasReducer = ( state = { loading:true,  DiasCombo: [] },  action) => {
    switch (action.type) {
 
      case COMBO_DIAS_SUCCESS:
        return { loading: false, DiasCombo: action.payload };
   
      default:
        return state;
    }
  };
  
  export const comboMetodosPagoReducer = ( state = { loading:true,  MetodosPagoCombo: [] },  action) => {
    switch (action.type) {
      case COMBO_METODOSPAGO_REQUEST:
        return { loading: true };
      case COMBO_METODOSPAGO_SUCCESS:
        return { loading: false, MetodosPagoCombo: action.payload };
      case COMBO_METODOSPAGO_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };