import React, {useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux'; 
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import { Column } from 'primereact/column';
import { MERCADERIA_GET_SUCCESS } from '../../constants/mercaderiaConstants';
import MercaderiaDetalleAppCaracteristicasABM from './MercaderiaDetalleAppCaracteristicasABM';

export default function MercaderiaDetalleAppCaracteristicas(props) {
        const dispatch = useDispatch();
        const mercaderiaGet =  useSelector(state => state.mercaderiaGet)
        const { mercaderiaItem} = mercaderiaGet;
        const ModificarCaracteristica1 = (e) => 
        {
            mercaderiaItem.Caracteristicas1.sDescripcion = e;
            dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});    
        }
        const ModificarCaracteristica2 = (e) => 
        {
            mercaderiaItem.Caracteristicas2.sDescripcion = e;
            dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});    
        }

        /* const [globalFilter1, setGlobalFilter1] = useState(null); */
        const renderHeader1 = () => {
        return (
            <div style={{backgroundColor:"#4C8BF4", padding:"0" , margin:"-1rem"}}>
                
                <div className="row center">
                    <td width="100%" colSpan="2" style={{width:"100%", textAlign:"center" , alignContent:"center" , padding:"0" }}>
                        <h2 style={{alignContent:"center", textAlign:"center", padding:"0", color:"white"}}>
                            <strong>Caracteristica 1</strong>
                        </h2>  
                    </td>
            </div>

            
            <div className="row top">
            <div className="col-4">   
                <MercaderiaDetalleAppCaracteristicasABM Id={0} Link={''} Descripcion={''} Activo={true} Caracteristica={1} Default={false}/>
            </div>
            <div className="col-1">
                                <td> 
                                    <h5 htmlFor="Descripcion" style={{ color:"white"}}>Descripcion: 
                                    </h5> 
                                </td> 
                                <td>
                                    <input
                                    id="Descripcion"
                                    type="text"
                                    required
                                    placeholder="Ingrese Descripcion"
                                    value={mercaderiaItem.Caracteristicas1.sDescripcion}
                                    onChange={(e) => ModificarCaracteristica1(e.target.value)}>
                                    </input>
                                </td> 
                            </div>  
                {/* <div className="col-4">
                    <span style={{marginBottom:"1rem", alignContent:"flex-end"}}  className="p-input-icon-left">
                        <i  className="pi pi-search" />
                        <InputText     style={   { fontSize: "1.5rem", color:'black'    }   }   
                        type="search" onInput={(e) => setGlobalFilter1(e.target.value)} placeholder="Busqueda" />
                    </span>  
                </div> */}
            </div>
            </div>);
        } 
        const dt1 = useRef(null);
        const DescripcionBodyTemplate1 = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Descripcion</span>
                {rowData.sValor}
            </React.Fragment>
        );
        }
 
        const ButtonBodyTemplate1 = (rowData) => {
        return (
            <React.Fragment>
                <MercaderiaDetalleAppCaracteristicasABM Id={rowData.Id} Link={rowData.sLink} Descripcion={rowData.sValor} Activo={rowData.bActivo} Caracteristica={1} Default={rowData.bDefault}/>
            </React.Fragment>
        );
        }

        const imagenBodyTemplate1 = (rowData, column) => {
        return ( <React.Fragment> 
                    <span className="p-column-title"></span>  
                    <img class="small" src={rowData.sLink} alt=''/> 
                </React.Fragment>);  
        }

        const header1 = renderHeader1();
      
        const renderHeader2 = () => {
          return (
              <div style={{backgroundColor:"#DE6257", padding:"0" , margin:"-1rem"}}>
                  
                  <div className="row center">
                      <td width="100%" colSpan="2" style={{width:"100%", textAlign:"center" , alignContent:"center" , padding:"0" }}>
                          <h2 style={{alignContent:"center", textAlign:"center", padding:"0", color:"white"}}>
                           <strong>Caracteristica 2</strong>
                          </h2>  
                      </td>
              </div>
              <div className="row top">
              <div className="col-4">
              <MercaderiaDetalleAppCaracteristicasABM Id={0} Link={''} Descripcion={''} Activo={true} Caracteristica={2}  Default={false}/>
                  </div>
                  <div className="col-1">
                                  <td> <h5 htmlFor="Descripcion" style={{ color:"white"}}>Descripcion: </h5> </td> 
                                  <td>   <input
                                      id="Descripcion"
                                      type="text"
                                      required
                                      placeholder="Ingrese Descripcion"
                                      value={mercaderiaItem.Caracteristicas2.sDescripcion}
                                      onChange={(e) => ModificarCaracteristica2(e.target.value)} ></input> </td> 
                              </div>  
  
               
                      {/*         <div className="col-4">
                      <span style={{marginBottom:"1rem", alignContent:"flex-end"}}  className="p-input-icon-left">
                          <i  className="pi pi-search" />
                          <InputText     style={   { fontSize: "1.5rem", color:'black'    }   }   
                          type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Busqueda" />
                      </span>  
                  </div> */}
              </div>
              </div>
  
          );
        } 
        const dt2 = useRef(null);
        const DescripcionBodyTemplate2 = (rowData) => {
          return (
              <React.Fragment>
                  <span className="p-column-title">Descripcion</span>
                  {rowData.sValor}
              </React.Fragment>
          );
        }
   
        const imagenBodyTemplate2 = (rowData, column) => {
        return ( <React.Fragment> 
                        <span className="p-column-title"></span>  
                        <img class="small" src={rowData.sLink} alt=''/> 
                    </React.Fragment>);  
        }
        
        const header2 = renderHeader2();
        const ButtonBodyTemplate2 = (rowData) => {
        return (
            <React.Fragment>
                <MercaderiaDetalleAppCaracteristicasABM Id={rowData.Id} Link={rowData.sLink} Descripcion={rowData.sValor} Activo={rowData.bActivo} Caracteristica={2} Default={rowData.bDefault}/>
            </React.Fragment>
        );
        }

        const activo1BodyTemplate = (rowData, column) => {
        if(!rowData.bActivo )
        {
        return (
        <React.Fragment>
        <span className="p-column-title">Activo</span>
            
                    <i class="fa fa-circle-o" aria-hidden="true"  onClick={() => Activar(1,rowData.Id,true)}
                        style={   { fontSize: "3rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"  }   }    
                        />                
        </React.Fragment>);

        }
        else
        {
        return (
            <React.Fragment>
                <span className="p-column-title">Activo</span>
                <i class="fa fa-check-circle-o" aria-hidden="true" onClick={() => Activar(1,rowData.Id,false)}
                style={{ fontSize: "3rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"}}/>                           
            </React.Fragment>);
        }
        }

        const Activar = (CarateristicaNro, Id, Activo) => {
        if(CarateristicaNro===1)
        {
                for (var i=0 ; i<mercaderiaItem.lstCaracteristicasOpciones1.length ; i++) 
                {
                    if(parseInt(mercaderiaItem.lstCaracteristicasOpciones1[i].Id) === parseInt(Id)){
                    mercaderiaItem.lstCaracteristicasOpciones1[i].bActivo = Activo;}
                }
            }
            else
            {
                for (var j=0 ; j<mercaderiaItem.lstCaracteristicasOpciones2.length ; j++) 
                {
                        if(parseInt(mercaderiaItem.lstCaracteristicasOpciones2[j].Id) === parseInt(Id)){
                        mercaderiaItem.lstCaracteristicasOpciones2[j].bActivo = Activo;}
                        
                }
            }
            dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});  
        }

        const SetearDefault = (CarateristicaNro, Id) => {
        if(CarateristicaNro===1)
        {
        for (var i=0 ; i<mercaderiaItem.lstCaracteristicasOpciones1.length ; i++) 
        {
            mercaderiaItem.lstCaracteristicasOpciones1[i].bDefault = false;
            if(parseInt(mercaderiaItem.lstCaracteristicasOpciones1[i].Id) === parseInt(Id)){
            mercaderiaItem.lstCaracteristicasOpciones1[i].bDefault = true;}
        }
        }
        else
        {
        for (var j=0 ; j<mercaderiaItem.lstCaracteristicasOpciones2.length ; j++) 
        { 
            mercaderiaItem.lstCaracteristicasOpciones2[j].bDefault = false;
                if(parseInt(mercaderiaItem.lstCaracteristicasOpciones2[j].Id) === parseInt(Id)){
                mercaderiaItem.lstCaracteristicasOpciones2[j].bDefault = true;}               
        }
        }
        dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});  
        }

        const default1BodyTemplate = (rowData, column) => {
        if(!rowData.bDefault )
        {
            return (
            <React.Fragment>
            <span className="p-column-title">Default</span>
                        <i class="fa fa-circle-o" aria-hidden="true"  onClick={() => SetearDefault(1,rowData.Id)}
                            style={   { fontSize: "3rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"}} />                
        </React.Fragment>);
        }
        else
        {
            return (
                <React.Fragment>
                    <span className="p-column-title">Default</span>
                    <i class="fa fa-check-circle-o"  aria-hidden="true" onClick={() => SetearDefault(1,rowData.Id)}
                    style={{ fontSize: "3rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"}}/>                           
                </React.Fragment>);
        }
        }

        const activo2BodyTemplate = (rowData, column) => {
        if(!rowData.bActivo )
        {
            return (
            <React.Fragment>
            <span className="p-column-title">Activo</span>
                
                        <i class="fa fa-circle-o" aria-hidden="true" onClick={() => Activar(2,rowData.Id,true)}
                            style={   { fontSize: "3rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"  }   }    
                            />                
        </React.Fragment>);

        }
        else
        {
            return (
                <React.Fragment>
                    <span className="p-column-title">Activo</span>
                    <i class="fa fa-check-circle-o" aria-hidden="true" onClick={() => Activar(2,rowData.Id,false)}
                    style={{ fontSize: "3rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"}}/>                           
                </React.Fragment>);
        }
        }
        
        const default2BodyTemplate = (rowData, column) => {
        if(!rowData.bDefault )
        {
            return (
            <React.Fragment>
            <span className="p-column-title">Default</span>       
            <i class="fa fa-circle-o" aria-hidden="true" onClick={() => SetearDefault(2,rowData.Id)}
                style={   { fontSize: "3rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"  }   }  />                
        </React.Fragment>);
        }
        else
        {
            return (
                <React.Fragment>
                    <span className="p-column-title">Default</span>
                    <i class="fa fa-check-circle-o" aria-hidden="true" onClick={() => SetearDefault(2,rowData.Id)}
                    style={{ fontSize: "3rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"}}/>                           
                </React.Fragment>);
        }
        }

        const ButtonEliminarBodyTemplate1 = (rowData) => {
        if(rowData.bNuevo )
        {
            return (
            <React.Fragment>

                
                        <i class="fa fa-trash" aria-hidden="true" onClick={() => Eliminar(1,rowData.Id)}
                            style={   { fontSize: "3rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"  }   }    
                            />                
        </React.Fragment>);

        }
        }

        const ButtonEliminarBodyTemplate2 = (rowData) => {
        if(rowData.bNuevo )
        {
            return (
            <React.Fragment>
            
                
                        <i class="fa fa-trash" aria-hidden="true" onClick={() => Eliminar(2,rowData.Id)}
                            style={   { fontSize: "3rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"  }   }    
                            />                
        </React.Fragment>);

        }
        }

        const Eliminar = (CarateristicaNro, Id) => {
        if(CarateristicaNro===1)
        {
            mercaderiaItem.lstCaracteristicasOpciones1 = mercaderiaItem.lstCaracteristicasOpciones1.filter((x) => x.Id !== Id);   
        }
        else
        {
            mercaderiaItem.lstCaracteristicasOpciones2 = mercaderiaItem.lstCaracteristicasOpciones2.filter((x) => x.Id !== Id);        
        }
        dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});  
        }
                    
return (
<div className="row top">
<div className="col-4">
                <div style={{marginBottom:'50px'}}>
                    <div className="datatable-doc-demo">
                        <DataTable ref={dt1} value={mercaderiaItem.lstCaracteristicasOpciones1} header={header1} className="p-datatable-customers" dataKey="Id1"
                                      
                                    selectionMode="single" 
                                    paginator rows={10} emptyMessage="No se encontraron Caracteristicas" 
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
                                    rowsPerPageOptions={[10,25,50]}>
                                    <Column  body={ButtonBodyTemplate1} headerStyle={{width:"4rem", textAlign: 'center' }}/>
                                    <Column  field="bActivo" header="Act." body={activo1BodyTemplate} headerStyle={{width:"4rem", textAlign: 'center' }}  />
                                    <Column  field="bDefault" header="Def." body={default1BodyTemplate} headerStyle={{width:"4rem", textAlign: 'center' }}  />
                                    <Column  field="sLink"   body={imagenBodyTemplate1} headerStyle={{width:"15rem", textAlign: 'center' }}  />
                                    <Column field="sValor" header="Descripcion"  body={DescripcionBodyTemplate1}  filterPlaceholder="Buscar"></Column>
                                    <Column  body={ButtonEliminarBodyTemplate1} headerStyle={{width:"4rem", textAlign: 'center' }}/>
                        </DataTable>       
                    </div>
                </div>  
</div>
<div className="col-4">
<div style={{marginBottom:'50px'}}>
                    <div className="datatable-doc-demo">
                        <DataTable ref={dt2} value={mercaderiaItem.lstCaracteristicasOpciones2} header={header2} className="p-datatable-customers" dataKey="Id2"
                                    
                                    selectionMode="single" 
                                    paginator rows={10} emptyMessage="No se encontraron Caracteristicas" 
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
                                    rowsPerPageOptions={[10,25,50]}>
                                    <Column  body={ButtonBodyTemplate2} headerStyle={{width:"4rem", textAlign: 'center' }}/>
                                    <Column  field="bActivo" header="Act." body={activo2BodyTemplate} headerStyle={{width:"4rem", textAlign: 'center' }}  />
                                    <Column  field="bDefault" header="Def." body={default2BodyTemplate} headerStyle={{width:"4rem", textAlign: 'center' }}  />
                                    <Column  field="sLink"   body={imagenBodyTemplate2} headerStyle={{width:"15rem", textAlign: 'center' }}  />
                                    <Column field="sValor" header="Descripcion"  body={DescripcionBodyTemplate2}  filterPlaceholder="Buscar"></Column>
                                    <Column  body={ButtonEliminarBodyTemplate2} headerStyle={{width:"4rem", textAlign: 'center' }}/>
                        </DataTable>       
                    </div>
                </div>  
</div>
</div>

                
);

}
