import React, {useState, useEffect,useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux'; 
import MessageBox from '../components/MessageBox';
/* import { InputText } from 'primereact/inputtext'; */
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import { Column } from 'primereact/column'; 
import { InputNumber } from 'primereact/inputnumber'; 
import { getlistalocalidadesapp, localidadesappUpdate } from '../actions/localidadesappActions';
import { LOCALIDADESAPP_UPDATE_SUCCESS } from '../constants/LocalidadesAppConstants';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
export default function LocalidadesAppScreen(props) {

    const localidadesapp =  useSelector(state => state.localidadesapp)
    const {loading, error, localidadesappLista} = localidadesapp;
    const [loadingdata, setLoadingdata] = useState(false);
   // const [globalFilter, setGlobalFilter] = useState(null);

    const dt = useRef(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({type: PANTALLASELECCIONADA, payload:  'Localidades y Costos de Envio'}); 
    dispatch({type: PANTALLAATRAS, payload:  ''}); 
      dispatch(getlistalocalidadesapp());
    }, [dispatch]);

    useEffect(() =>{
        setLoadingdata(loading);
    },[dispatch,loading]
    )

      const IdBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Id</span>
                {rowData.sId}
            </React.Fragment>
        );
      }

      const DescripcionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Descripcion</span>
                {rowData.sDescripcion}
            </React.Fragment>
        );
      }

      const DepartamentoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Departamento</span>
                {rowData.sDepartamento}
            </React.Fragment>
        );
      }

      const MunicipioBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Municipio</span>
                {rowData.sMunicipio}
            </React.Fragment>
        );
      }

      const ProvinciaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Provincia</span>
                {rowData.sProvincia}
            </React.Fragment>
        );
      }

      const activoBodyTemplate = (rowData, column) => {
        if(!rowData.bActivo )
        {
          return (
            <React.Fragment>
            <span className="p-column-title">Activo</span>
              
                        <i class="fa fa-circle-o" aria-hidden="true" onClick={() => Activar(rowData.sId,true)}
                            style={   { fontSize: "2rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"  }   }    
                          />                
        </React.Fragment>);
        }
        else
        {
            return (
                <React.Fragment>
                <span className="p-column-title">Activo</span>
                   
                            <i class="fa fa-check-circle-o" aria-hidden="true" onClick={() => Activar(rowData.sId,false)}
                                style={   { fontSize: "2rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"  }   }    
                                 />
            </React.Fragment>);
        }}
  
        const activoEditor= ( props) =>
        {
            if(!props.rowData['bActivo'] )
            {
              return (
                <React.Fragment>
                <span className="p-column-title">Activo</span>
                  
                            <i class="fa fa-circle-o" aria-hidden="true" onClick={() => Activar(props.rowData.sId,true)}
                                style={   { fontSize: "2rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"  }   }    
                              />                
            </React.Fragment>);
            }
            else
            {
                return (
                    <React.Fragment>
                    <span className="p-column-title">Activo</span>
                       
                                <i class="fa fa-check-circle-o" aria-hidden="true" onClick={() => Activar(props.rowData.sId,false)}
                                    style={   { fontSize: "2rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"  }   }    
                                     />
                </React.Fragment>);
            }}
        
        const Activar = ( sId, Activo) => {
            let o = localidadesappLista.find(r => 
                r.sId === sId );
            o.bActivo = Activo;
            dispatch({type: LOCALIDADESAPP_UPDATE_SUCCESS, payload:  localidadesappLista}); 
            dispatch(localidadesappUpdate(o.sId,o.bActivo,o.CostoEnvio)); 
            }

      
        const CostoEnvioBodyTemplate = (rowData) => {
            return   <React.Fragment>
                    <span className="p-column-title">Cantidad: </span>
                    {new  Intl.NumberFormat('en-US').format(rowData.CostoEnvio) }
                 </React.Fragment>
        }

        const CostoEnvioEditor = ( props) => {
            return     <InputNumber value={props.rowData['CostoEnvio']} 
                style={{size: 'Large'}} showButtons buttonLayout="horizontal"
                onValueChange={(e) => onEditorValueChange( props, e.value)} 
                mode="decimal"   minFractionDigits={3} decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                locale="en-US" className="p-inputtext-lg p-d-block"  />
        }
 
        const onEditorValueChange = (props, value) => {
            let o = localidadesappLista.find(r => 
                r.sId === props.rowData['sId'] );
            o.CostoEnvio = value;
            dispatch({type: LOCALIDADESAPP_UPDATE_SUCCESS, payload:  localidadesappLista}); 
           dispatch(localidadesappUpdate(o.sId,o.bActivo,o.CostoEnvio));
        }

        const customFunction = (value, filter) => {
            return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0
    }

    return (
                error? <MessageBox variant="danger" >{error}</MessageBox>
                :
                (
                    <div style={{marginBottom:'50px', paddingTop:"25px"}}>
                        <div className="datatable-doc-demo">
                        <DataTable ref={dt} value={localidadesappLista} className="p-datatable-customers" dataKey="Id"
                        rowHover   selectionMode="single" loading={loadingdata} 
                        paginator rows={10} emptyMessage="No se encontraron Pedidos" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
>
            <Column  field="bActivo" header="Act." body={activoBodyTemplate} headerStyle={{width:"4rem", textAlign: 'center' }}  editor={(props) => activoEditor( props)} />
            <Column field="sId" header="Id"  body={IdBodyTemplate} headerStyle={{   textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
            <Column field="sDescripcion" header="Descripcion" filterMatchMode="custom" filterFunction={customFunction}  body={DescripcionBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
            <Column field="sDepartamento" header="Departamento" filterMatchMode="custom" filterFunction={customFunction}  body={DepartamentoBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
            <Column field="sMunicipio" header="Municipio" filterMatchMode="custom" filterFunction={customFunction}  body={MunicipioBodyTemplate} headerStyle={{   textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
            <Column field="sProvincia" header="Provincia" filterMatchMode="custom" filterFunction={customFunction}  body={ProvinciaBodyTemplate} headerStyle={{   textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
            <Column field="CostoEnvio" header="CostoEnvio" filterMatchMode="custom" filterFunction={customFunction}  body={CostoEnvioBodyTemplate} editor={(props) => CostoEnvioEditor( props)}></Column>
</DataTable>       
</div>
</div>)
);

}