import {createStore, compose,applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk'  ;
import { operadorSigninReducer, pantallaAtrasReducer, pantallaSeleccionadaReducer  } from './reducers/operadorReducers';
import { funcionalidadesGetReducer  } from './reducers/funcionalidadReducers';
import {   familiasReducer, familiaUpdateReducer } from './reducers/familiaReducers';
import { mercaderiaGetReducer, mercaderiasReducer, mercaderiaSubiendoFotosReducer, mercaderiaUpdateReducer } from './reducers/mercaderiaReducers';
import { comboClasificacionesReducer, comboDefinicionesCuartoReducer, comboDiasReducer, comboEtiquetasReducer, comboFamiliasReducer, comboGruposMercaderiaReducer, comboImpuestosReducer, comboMercaderiasAfipReducer, comboMercaderiasReducer, comboMercaderiaTiposReducer, comboMetodosPagoReducer, comboNomencladoresReducer, comboUnidadesReducer } from './reducers/comboReducers';
import { MercaderiasOpcionesStockReducer, MercaderiasOpcionesStockUpdateReducer } from './reducers/MercaderiasOpcionesStockReducers';
import { identificacionReducers, identificacionUpdateReducer } from './reducers/identificacionReducers';
import { localidadesappReducer, localidadesappUpdateReducer } from './reducers/localidadesappReducers';
import { familiaspromocionesReducer, familiaspromocionesUpdateReducer,familiaspromocionesDeleteReducer } from './reducers/familiaspromocionesReducers';
import { gruposmercaderiaReducer, gruposmercaderiaUpdateReducer } from './reducers/gruposmercaderiaReducers';
import { mercaderiasgruposmercaderiasReducer, mercaderiasgruposmercaderiasUpdateReducer } from './reducers/mercaderiasgruposmercaderiasReducers';
import { pedidoAprobarReducer, pedidoCerrarReducer, pedidoEliminarReducer, pedidoReducers } from './reducers/pedidoReducers';
import { RangosHorarioDeleteReducer, RangosHorarioReducer, RangosHorarioUpdateReducer } from './reducers/rangoshorarioReducers';
import { descuentosDeleteReducer, descuentosReducer, descuentosUpdateReducer } from './reducers/descuentosReducers';
import { descuentosmercaderiasReducer, descuentosmercaderiasUpdateReducer } from './reducers/descuentosmercaderiasReducers';
import { constantesReducer } from './reducers/constantesReducers';
import { MercaderiasStockReducer, MercaderiasStockUpdateReducer } from './reducers/MercaderiasStockReducers';

     const initialState = {
        operadorSignin:
         {
            operadorInfo: localStorage.getItem('operadorInfo') ? JSON.parse(localStorage.getItem('operadorInfo')):null 
 
         },
         funcionalidadesGet:
         {
              
            funcionalidadesLista: localStorage.getItem('funcionalidadesLista') ? JSON.parse(localStorage.getItem('funcionalidadesLista')):null 
 
         },
      };
const reducer = combineReducers({
    operadorSignin: operadorSigninReducer,
    funcionalidadesGet: funcionalidadesGetReducer,
    familias: familiasReducer,
    familiaUpdate: familiaUpdateReducer,
    mercaderias: mercaderiasReducer,
    mercaderiaUpdate: mercaderiaUpdateReducer,
    mercaderiaGet: mercaderiaGetReducer,
    comboUnidades:comboUnidadesReducer,
    comboMercaderiaTipos:comboMercaderiaTiposReducer,
    comboFamilias:comboFamiliasReducer,
    comboImpuestos:comboImpuestosReducer,
    comboEtiquetas:comboEtiquetasReducer,
    comboDefinicionesCuarto:comboDefinicionesCuartoReducer,
    comboNomencladores:comboNomencladoresReducer,
    comboClasificaciones:comboClasificacionesReducer,
    comboMercaderiasAfip:comboMercaderiasAfipReducer,
    comboGruposMercaderia:comboGruposMercaderiaReducer,
    comboMercaderias:comboMercaderiasReducer,
    MercaderiasOpcionesStock:MercaderiasOpcionesStockReducer,
    MercaderiasOpcionesStockUpdate:MercaderiasOpcionesStockUpdateReducer,
    identificacion:identificacionReducers,
    identificacionUpdate:identificacionUpdateReducer,
    localidadesapp:localidadesappReducer,
    localidadesappUpdate:localidadesappUpdateReducer,
    familiaspromociones:familiaspromocionesReducer,
    familiaspromocionesUpdate:familiaspromocionesUpdateReducer,
    gruposmercaderia:gruposmercaderiaReducer,
    gruposmercaderiaUpdate:gruposmercaderiaUpdateReducer,
    mercaderiasgruposmercaderias:mercaderiasgruposmercaderiasReducer,
    mercaderiasgruposmercaderiasUpdate:mercaderiasgruposmercaderiasUpdateReducer,
    familiaspromocionesDelete:familiaspromocionesDeleteReducer,
    pedidos:pedidoReducers,
    pedidoAprobar:pedidoAprobarReducer,
    pedidoCerrar:pedidoCerrarReducer,
    pedidoEliminar:pedidoEliminarReducer,
    pantallaSeleccionada:pantallaSeleccionadaReducer,
    pantallaAtras:pantallaAtrasReducer,mercaderiaSubiendoFotos:mercaderiaSubiendoFotosReducer,
    RangosHorario:RangosHorarioReducer,
    RangosHorarioUpdate:RangosHorarioUpdateReducer,
    RangosHorarioDelete:RangosHorarioDeleteReducer,
    descuentos:descuentosReducer,
    descuentosDelete:descuentosDeleteReducer,
    descuentosUpdate:descuentosUpdateReducer,
    comboDias:comboDiasReducer,
    comboMetodosPago:comboMetodosPagoReducer,
    descuentosmercaderias:descuentosmercaderiasReducer,
    descuentosmercaderiasUpdate:descuentosmercaderiasUpdateReducer,
    constantes:constantesReducer,
    MercaderiasStock:MercaderiasStockReducer,
    MercaderiasStockUpdate:MercaderiasStockUpdateReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
      reducer, initialState, 
      composeEnhancer(applyMiddleware(thunk))
      );
  export default store;