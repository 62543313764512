import React, {useState, useEffect,useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux'; 
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import { Column } from 'primereact/column';  
import { getlistaRangosHorario ,RangoHorarioUpdate} from '../actions/rangoshorarioActions';
import swal from 'sweetalert';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import { Toolbar } from 'primereact/toolbar'; 
import RangoHorarioNuevo from '../components/RangoHorarioNuevo';
import { RANGOSHORARIO_LISTA_SUCCESS } from '../constants/RangosHorarioConstants';
import { InputNumber } from 'primereact/inputnumber';
export default function RangosHorarioScreen(props) {
    const RangosHorario =  useSelector(state => state.RangosHorario)
    const {loading, RangosHorarioLista} = RangosHorario;

    const RangosHorarioUpdate = useSelector((state) => state.RangosHorarioUpdate);
    const { error: errorUpdate } = RangosHorarioUpdate; 

    const [loadingdata, setLoadingdata] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const dt = useRef(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({type: PANTALLASELECCIONADA, payload:  'Rangos de Horario'}); 
        dispatch({type: PANTALLAATRAS, payload:  ''}); 
      dispatch(getlistaRangosHorario());
    }, [dispatch]);

    useEffect(() =>{
        setLoadingdata(loading);
    },[dispatch,loading]  )

    const IdBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="p-column-title">Id</span>
            {rowData.Id}
        </React.Fragment>
    );   }

    const HastaBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="p-column-title">Hasta</span>
            {rowData.sHasta}
        </React.Fragment>
    );  }

    const DesdeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Desde</span>
                {rowData.sDesde}
            </React.Fragment>
        );     }

useEffect(() => {
    if(errorUpdate && errorUpdate !== "")
    {
    swal({ title: "Error",  text:errorUpdate , icon: "error", button: "cerrar" });
    dispatch(getlistaRangosHorario());
    }
  }, [dispatch,errorUpdate]);

  const rightContents = (
    <React.Fragment>
          <span   className="p-input-icon-left">
                <i  className="pi pi-search" />
                <InputText     style={   { fontSize: "1.5rem", color:'black'    }   }   
                type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busqueda Global" />
            </span>  
    </React.Fragment>
);

const activoBodyTemplate = (rowData, column) => {
    if(!rowData.bActivo )
    {
      return (
        <React.Fragment>
        <span className="p-column-title">Activo</span>
          
                    <i class="fa fa-circle-o" aria-hidden="true" onClick={() => Activar(rowData.Id,true)}
                        style={   { fontSize: "2rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"  }   }    
                      />                
    </React.Fragment>);
    }
    else
    {
        return (
            <React.Fragment>
            <span className="p-column-title">Activo</span>
               
                        <i class="fa fa-check-circle-o" aria-hidden="true" onClick={() => Activar(rowData.Id,false)}
                            style={   { fontSize: "2rem", color:'black'  ,cursor: "pointer" , verticalAlign: "bottom"  }   }    
                             />
        </React.Fragment>);
    }}
    
    const Activar = ( sId, Activo) => {
        let o = RangosHorarioLista.find(r =>   r.Id === Number(sId) );
        o.bActivo = Activo;
        dispatch({type: RANGOSHORARIO_LISTA_SUCCESS, payload:  RangosHorarioLista}); 
        dispatch(RangoHorarioUpdate(o.Id,o.iCantidad,o.sDesde,o.sHasta,false,o.bActivo));
        }


        const onEditorValueChange = (Id, value) => {
 
            let o = RangosHorarioLista.find(r =>   r.Id === Number(Id) );
            o.iCantidad = value;
            dispatch({type: RANGOSHORARIO_LISTA_SUCCESS, payload:  RangosHorarioLista}); 
            dispatch(RangoHorarioUpdate(o.Id,o.iCantidad,o.sDesde,o.sHasta,false,o.bActivo));
        }

        const positiveIntegerValidator = (rowData) => {
            return     <React.Fragment>
            <span className="p-column-title">Cantidad: </span>
           {new  Intl.NumberFormat('en-US' ).format(rowData.iCantidad) }
                 </React.Fragment>
         }
     
         const stockEditor = ( props) => {
             return <InputNumber value={props.rowData['iCantidad']} 
                     style={{size: 'Large'}} showButtons buttonLayout="horizontal"
                     onValueChange={(e) => onEditorValueChange( props.rowData['Id'], e.value)} 
                     mode="decimal"   minFractionDigits={0} decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                     locale="en-US" className="p-inputtext-lg p-d-block"  />
         }



const customFunction = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0
}
    return (<div style={{marginBottom:'50px'}}>
                <div style={{  padding:"0px" ,position: 'fixed',zIndex: 1, width: '100%',marginTop:'20px'}}>
                    <Toolbar right={rightContents}  style={{ backgroundColor:"#179F5D", margin:0,padding:0, fontSize:'16px', fontWeight:"bold", width:"auto"}}>
                    </Toolbar>   
                </div>
                <div style={{ position: 'fixed',zIndex: 1, bottom: 0,  right: 0,  justifyContent: 'center', alignItems: 'center', marginBottom:"30px"}} >
                <RangoHorarioNuevo />
                </div>
                        <div className="datatable-doc-demo" style={{paddingTop:'55px'}}>
                        <DataTable ref={dt} value={RangosHorarioLista} className="p-datatable-customers" dataKey="Id"
                        rowHover globalFilter={globalFilter}   selectionMode="single"  loading={loadingdata}
                        paginator rows={10} emptyMessage="No se encontraron Pedidos" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
>
        <Column  field="bActivo" header="Act." body={activoBodyTemplate} headerStyle={{width:"8rem", textAlign: 'center' }} />
        <Column field="Id" header="Id"  body={IdBodyTemplate} headerStyle={{  width:"8rem", textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="sDesde" header="Desde" filterMatchMode="custom" filterFunction={customFunction} body={DesdeBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="sHasta" header="Hasta" filterMatchMode="custom" filterFunction={customFunction} body={HastaBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="iCantidad" header="Cupo"  sortable filter filterMatchMode="lte" filterPlaceholder="Desde"    body={positiveIntegerValidator} editor={(props) => stockEditor(props)}></Column>

</DataTable>       
</div>
</div>
);

}