import { DESCUENTOSMERCADERIAS_LISTA_FAIL, DESCUENTOSMERCADERIAS_LISTA_REQUEST, DESCUENTOSMERCADERIAS_LISTA_SUCCESS, 
  DESCUENTOSMERCADERIAS_UPDATE_FAIL, DESCUENTOSMERCADERIAS_UPDATE_REQUEST, DESCUENTOSMERCADERIAS_UPDATE_SUCCESS } from "../constants/Descuentos_MercaderiasConstants";

 
export const getlistadescuentosmercaderias = (DescuentoId) => async (dispatch) => {
  if(DescuentoId=== 0)
  {
    dispatch({ type: DESCUENTOSMERCADERIAS_LISTA_SUCCESS, payload:  []});
    return;
  }
    dispatch({ type: DESCUENTOSMERCADERIAS_LISTA_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId"),DescuentoId: DescuentoId };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Descuentos_Mercaderias/Lista', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: DESCUENTOSMERCADERIAS_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: DESCUENTOSMERCADERIAS_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: DESCUENTOSMERCADERIAS_LISTA_SUCCESS, payload:  data.Lista.sort((b, a) => a.bActivo - b.bActivo)}); 
           }
           
    } catch (error) {
      dispatch({
        type: DESCUENTOSMERCADERIAS_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const descuentosmercaderiasUpdate = (DescuentoId,MercaderiaId,Activar) => async (dispatch, getState) => {
    dispatch({ type: DESCUENTOSMERCADERIAS_UPDATE_REQUEST});
    const { operadorSignin: { operadorInfo }, } = getState(); 
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") ,DescuentoId: DescuentoId,MercaderiaId:MercaderiaId,Activar:Activar,OperadorId:operadorInfo.Id};
     /*    dispatch({ type: MERCADERIASGRUPOSMERCADERIAS_UPDATE_FAIL, payload:  JSON.stringify(parametros) });
return;  */ 
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Descuentos_Mercaderias/Insert', {
             method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: DESCUENTOSMERCADERIAS_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: DESCUENTOSMERCADERIAS_UPDATE_FAIL, payload:  data });
           }
           else
           {
           dispatch({type: DESCUENTOSMERCADERIAS_UPDATE_SUCCESS }); 
           }    
           
    } catch (error) {
      dispatch({
        type: DESCUENTOSMERCADERIAS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };