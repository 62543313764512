import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import { useDispatch,useSelector } from 'react-redux';  
import LoadingBox from '../LoadingBox';
import MessageBox from '../MessageBox';
import { getcomboFamilias, getcomboImpuestos, getcomboMercaderiaTipos, getcomboUnidades } from '../../actions/comboActions';
import { MERCADERIA_GET_SUCCESS } from '../../constants/mercaderiaConstants';
import 'primereact/resources/themes/saga-green/theme.css';
import { AutoComplete } from 'primereact/autocomplete';
import 'primeicons/primeicons.css';
import { uploadFile } from '../../actions/BlobActions';
export default function MercaderiaDetalleMinimos() {
  const dispatch = useDispatch();
  const mercaderiaGet =  useSelector(state => state.mercaderiaGet)
  const { mercaderiaItem} = mercaderiaGet;
  const Modificar = (campo,valor,combo) => 
  {
    switch (campo) {
      case 'MercaderiaUnidad_Id':
        setUnidad(combo);
      break;
      case 'MercaderiaTipo_Id':
        setTipo(combo);
      break;
      case 'Familia_Id':
        setFamilia(combo);
      break;
      case 'ImpuestoId':
        setImpuesto(combo);
      break;  
      default:
        break;
    }
    if(combo)
    mercaderiaItem[campo] = valor ? valor : 0;
    else
    mercaderiaItem[campo] = valor;
    dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});    
  }
 
  const comboUnidades =  useSelector(state => state.comboUnidades);
  const {loading: loadingUnidades, error: errorUnidades, UnidadesCombo} = comboUnidades;
  useEffect(() => { 
       dispatch(getcomboUnidades())
  }, [dispatch])
  const [Unidad, setUnidad] = useState(null);
  const [filteredUnidades, setFilteredUnidades] = useState(null); 
  const searchUnidades = (event) => {
      setTimeout(() => {
          let filteredUnidades;
          if (!event.query.trim().length) {
              filteredUnidades = [...UnidadesCombo];
          }
          else {
            filteredUnidades = UnidadesCombo.filter((country) => {
                  return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
              });
          } 
          setFilteredUnidades(filteredUnidades);
      }, 250);
  }
  useEffect(() => { 
    if(UnidadesCombo && mercaderiaItem)
    setUnidad(UnidadesCombo.find(r => r.Id === mercaderiaItem.MercaderiaUnidad_Id));
}, [dispatch,UnidadesCombo,mercaderiaItem])

  const comboMercaderiaTipos =  useSelector(state => state.comboMercaderiaTipos);
  const {loading: loadingMercaderiaTipos, error: errorMercaderiaTipos, MercaderiaTiposCombo} = comboMercaderiaTipos;
  useEffect(() => { 
       dispatch(getcomboMercaderiaTipos())
  }, [dispatch])
  const [Tipo, setTipo] = useState(null);
  const [filteredTipos, setFilteredTipos] = useState(null); 
  const searchTipos = (event) => {
      setTimeout(() => {
          let filteredTipos;
          if (!event.query.trim().length) {
              filteredTipos = [...MercaderiaTiposCombo];
          }
          else {
            filteredTipos = MercaderiaTiposCombo.filter((country) => {
                  return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
              });
          } 
          setFilteredTipos(filteredTipos);
      }, 250);
  }
  useEffect(() => { 
    if(MercaderiaTiposCombo && mercaderiaItem)
    setTipo(MercaderiaTiposCombo.find(r => r.Id === mercaderiaItem.MercaderiaTipo_Id));
}, [dispatch,MercaderiaTiposCombo,mercaderiaItem])


  const comboFamilias =  useSelector(state => state.comboFamilias);
  const {loading: loadingFamilias, error: errorFamilias, FamiliasCombo} = comboFamilias;
  useEffect(() => { 
       dispatch(getcomboFamilias())
  }, [dispatch])
  const [Familia, setFamilia] = useState(null);
  const [filteredFamilias, setFilteredFamilias] = useState(null); 
  const searchFamilias = (event) => {
      setTimeout(() => {
          let filteredFamilias;
          if (!event.query.trim().length) {
              filteredFamilias = [...FamiliasCombo];
          }
          else {
            filteredFamilias = FamiliasCombo.filter((country) => {
                  return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
              });
          } 
          setFilteredFamilias(filteredFamilias);
      }, 250);
  }
  useEffect(() => { 
    if(FamiliasCombo && mercaderiaItem)
    setFamilia(FamiliasCombo.find(r => r.Id === mercaderiaItem.Familia_Id));
}, [dispatch,FamiliasCombo,mercaderiaItem])

  const comboImpuestos =  useSelector(state => state.comboImpuestos);
  const {loading: loadingImpuestos, error: errorImpuestos, ImpuestosCombo} = comboImpuestos;
  useEffect(() => { 
       dispatch(getcomboImpuestos())
  }, [dispatch])
  const [Impuesto, setImpuesto] = useState(null);
  const [filteredImpuestos, setFilteredImpuestos] = useState(null); 
  const searchImpuestos = (event) => {
      setTimeout(() => {
          let filteredImpuestos;
          if (!event.query.trim().length) {
              filteredImpuestos= [...ImpuestosCombo];
          }
          else {
            filteredImpuestos= ImpuestosCombo.filter((country) => {
                  return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
              });
          } 
          setFilteredImpuestos(filteredImpuestos);
      }, 250);
  }
  useEffect(() => { 
    if(ImpuestosCombo && mercaderiaItem)
    setImpuesto(ImpuestosCombo.find(r => r.Id === mercaderiaItem.ImpuestoId));
}, [dispatch,ImpuestosCombo,mercaderiaItem])


  const handlefilechange = (e) =>{
    let files = e.target.files;
    handfiles(files);
}

const handfiles = files => {
    for(let file of files){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () =>{
          dispatch(uploadFile(file,true,false)); })
    }
}

const handledelete = (e) =>{
        mercaderiaItem.LinkFoto = "";  
        mercaderiaItem.NombreFotoNueva = "";  
        dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});     
}

const [highlight,setHighlight]=useState(false);
const handlehighlight = (e) =>{
    e.preventDefault();
    e.stopPropagation();
     setHighlight(true);
}

const handleunhighlight = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);
}

const handledrop = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    let dt =e.dataTransfer;
    let files = dt.files;
    handfiles(files);
    setHighlight(false);
}

const itemTemplate = (item) => {
  return (
      <div className="country-item">
          <img  alt={item.sDescripcion} src={'https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
          onError={(e) => e.target.src='https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
          className={`flag flag-${item.sCodigo.toLowerCase()}`} />
          <div>{item.sDescripcion}</div>
      </div>
  );
}

    return (
      <div>
        <form className="form"   >        
        {  mercaderiaItem &&<>
       <div>

       <div className={highlight? "custom-file-drop-area2 highlight":"custom-file-drop-area2"} onDragEnter={handlehighlight} 
                onDragOver={handlehighlight} onDragLeave={handleunhighlight} onDrop={handledrop}>
                    <input type="file"name="photos" placeholder="Enter photos"   id="filephotos" onChange={handlefilechange}/>
                    <label htmlFor="filephotos" >Imagen de Presentacion Arrastre o click para seleccionar</label>
                    {mercaderiaItem.LinkFoto !== "" &&
                    <div className="custom-file-preview">
                   
                 <div className="prev-img"  >
                     <span onClick={() => handledelete(mercaderiaItem.LinkFoto)}>&times;</span>
                     <img className="smallGrilla" src={mercaderiaItem.LinkFoto} alt={mercaderiaItem.LinkFoto}/>
                 </div>
         </div>}
                </div>

       { mercaderiaItem.Id > 0 &&

                  <Link onClick={() => {Modificar('bActivo',!mercaderiaItem.bActivo)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                  <div>

                    <tr>
                      <td>{mercaderiaItem.bActivo ? <i class="fa fa-check-square-o" 
                                      aria-hidden="true" 
                                      style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                              : <i class="fa fa-square-o" 
                                      aria-hidden="true" 
                                      style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                      }</td>
                      <td> <h4>Activo </h4></td>
                      </tr> 
                         
                  </div> 
                  </Link> 
 
               } 

             <h5 htmlFor="Codigo" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0"}}>Codigo </h5>  
                <input
                id="Codigo"
                type="text"
                required
                placeholder="Ingrese Codigo"
                value={mercaderiaItem.sCodigo}
                onChange={(e) => Modificar('sCodigo',e.target.value)} ></input>

            
            <h5 htmlFor="Descripcion" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Descripcion </h5>  
                <input
                id="Descripcion"
                type="text"
                required
                placeholder="Ingrese Descripcion"
                value={mercaderiaItem.sDescripcion}
                onChange={(e) => Modificar('sDescripcion',e.target.value)} ></input>

            
            <h5 htmlFor="EAN" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>EAN </h5>  
                <input
                id="EAN"
                type="text" 
                placeholder="Ingrese EAN"
                value={mercaderiaItem.sEAN}
                onChange={(e) => Modificar('sEAN',e.target.value)} ></input>

            <tbody>
            <tr>
              <td>
 
                  <Link onClick={() => {Modificar('bLlevaCodbar',!mercaderiaItem.bLlevaCodbar)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                  <div>

                    <tr>
                      <td>{mercaderiaItem.bLlevaCodbar ? <i class="fa fa-check-square-o" 
                                      aria-hidden="true" 
                                      style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                              : <i class="fa fa-square-o" 
                                      aria-hidden="true" 
                                      style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                      }</td>
                      <td> <h4 style={{marginRight:"20px"}}>Lleva Codbar </h4></td>
                      </tr> 
                         
                  </div> 
                  </Link> 

              </td>
              <td >
    
                  <Link onClick={() => {Modificar('bLlevaStock',!mercaderiaItem.bLlevaStock)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                  <div>

                    <tr>
                      <td>{mercaderiaItem.bLlevaStock ? <i class="fa fa-check-square-o" 
                                      aria-hidden="true" 
                                      style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                              : <i class="fa fa-square-o" 
                                      aria-hidden="true" 
                                      style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                      }</td>
                      <td> <h4 >Lleva Stock </h4></td>
                      </tr> 
                         
                  </div> 
                  </Link>
                </td>
              </tr>
            </tbody>
 
            <h5 htmlFor="Unidad" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Unidad </h5>  
            <div>
              {loadingUnidades? <LoadingBox></LoadingBox>
              :
              errorUnidades?<MessageBox variant="danger">{errorUnidades}</MessageBox>
              :    
              <AutoComplete value={Unidad} suggestions={filteredUnidades} completeMethod={searchUnidades} 
                 field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                 onChange={(e) => Modificar('MercaderiaUnidad_Id', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>
            }
          </div>

      
          <h5 htmlFor="Tipo" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Tipo </h5> 
            <div>
              {loadingMercaderiaTipos? <LoadingBox></LoadingBox>
              :
              errorMercaderiaTipos?<MessageBox variant="danger">{errorMercaderiaTipos}</MessageBox>
              :  
              <AutoComplete value={Tipo} suggestions={filteredTipos} completeMethod={searchTipos} 
                 field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                 onChange={(e) => Modificar('MercaderiaTipo_Id', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>
            }
          </div>

       
          <h5 htmlFor="Familia" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Familia </h5> 
            <div>
              {loadingFamilias? <LoadingBox></LoadingBox>
              :
              errorFamilias?<MessageBox variant="danger">{errorFamilias}</MessageBox>
              : 
              <AutoComplete value={Familia} suggestions={filteredFamilias} completeMethod={searchFamilias} 
                 field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                 onChange={(e) => Modificar('Familia_Id', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>
            }
          </div>
          <h5 htmlFor="Impuesto" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Impuesto </h5> 
         
            <div>
              {loadingImpuestos? <LoadingBox></LoadingBox>
              :
              errorImpuestos?<MessageBox variant="danger">{errorImpuestos}</MessageBox>
              :    
              <AutoComplete value={Impuesto} suggestions={filteredImpuestos} completeMethod={searchImpuestos} 
                 field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                 onChange={(e) => Modificar('ImpuestoId', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>
              }
          </div>
        </div> 
          </>}
        </form>
    </div>
    );
}
