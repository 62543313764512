import { IDENTIFICACION_GET_SUCCESS } from "../constants/identificacionConstants";
import { MERCADERIAS_ADDARCHIVO_REQUEST, MERCADERIAS_ADDARCHIVO_SUCCESS, MERCADERIA_GET_SUCCESS } from "../constants/mercaderiaConstants";
const { BlobServiceClient } = require("@azure/storage-blob")
    
export const uploadFile  = (file,EsPrincipal,EsFoto ) => async (dispatch, getState)  => { 
    dispatch({ type: MERCADERIAS_ADDARCHIVO_REQUEST, payload:String(file.name)});
    const { mercaderiaGet: { mercaderiaItem }, } = getState(); 
    const CONTAINER_NAME = 'imagenes'
    const sasURL = `https://twinsarchivos.blob.core.windows.net/mercaderiasapp?sv=2020-04-08&st=2021-04-19T16%3A02%3A25Z&se=2041-04-20T16%3A02%3A00Z&sr=c&sp=racwdl&sig=%2F8rlEzewMluGcGs12z46BJ5uxS1hkAEg7BMjU6eP5M8%3D`
    const blobServiceClient = new BlobServiceClient(sasURL)
    const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME)
    const filename = file.name.substring(0, file.name.lastIndexOf('.'))
    const ext = file.name.substring(file.name.lastIndexOf('.'))
    const blobName = filename + '_' + new Date().getTime() + ext
    const blockBlobClient = containerClient.getBlockBlobClient(blobName)
    await blockBlobClient.uploadBrowserData(file)
  
    if(EsFoto)
    {
      var max;
      for (var j=0 ; j < mercaderiaItem.fotos.length ; j++) {
          if (max == null || parseInt(mercaderiaItem.fotos[j].Id) > parseInt(max.Id))
              max = mercaderiaItem.fotos[j];
      }
    mercaderiaItem.fotos.push({Id:(max ? max.Id : 0) +  1 ,src:'https://twinsarchivos.blob.core.windows.net/mercaderiasapp/imagenes/' +blobName , nombre:''}) ;
    dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});  
  }
  if(EsPrincipal)
    {
      
      mercaderiaItem.LinkFoto='https://twinsarchivos.blob.core.windows.net/mercaderiasapp/imagenes/' +blobName ;
      dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem}); 
    }
     dispatch({ type: MERCADERIAS_ADDARCHIVO_SUCCESS, payload: 'https://twinsarchivos.blob.core.windows.net/mercaderiasapp/imagenes/' +blobName  });

  }


  export const uploadFileIdentificacion  = (file,Archivo  ) => async (dispatch, getState)  => { 
 
    const { identificacion: { identificacionInfo }, } = getState(); 
    const CONTAINER_NAME = 'imagenes'
    const sasURL = `https://twinsarchivos.blob.core.windows.net/mercaderiasapp?sv=2020-04-08&st=2021-04-19T16%3A02%3A25Z&se=2041-04-20T16%3A02%3A00Z&sr=c&sp=racwdl&sig=%2F8rlEzewMluGcGs12z46BJ5uxS1hkAEg7BMjU6eP5M8%3D`
    const blobServiceClient = new BlobServiceClient(sasURL)
    const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME)
    const filename = file.name.substring(0, file.name.lastIndexOf('.'))
    const ext = file.name.substring(file.name.lastIndexOf('.'))
    const blobName = filename + '_' + new Date().getTime() + ext
    const blockBlobClient = containerClient.getBlockBlobClient(blobName)
    await blockBlobClient.uploadBrowserData(file)
  
    if(Archivo === 1)
    {
        identificacionInfo.sLinkPublicidad1='https://twinsarchivos.blob.core.windows.net/mercaderiasapp/imagenes/' +blobName ;
    }
    else if(Archivo === 2)
    {
        identificacionInfo.sLinkPublicidad2='https://twinsarchivos.blob.core.windows.net/mercaderiasapp/imagenes/' +blobName ;
    }
    else if(Archivo === 3)
    {
        identificacionInfo.sLinkPublicidad3='https://twinsarchivos.blob.core.windows.net/mercaderiasapp/imagenes/' +blobName ;
    }
    else if(Archivo === 4)
    {
        identificacionInfo.sLinkPublicidad4='https://twinsarchivos.blob.core.windows.net/mercaderiasapp/imagenes/' +blobName ;
    }
    dispatch({type: IDENTIFICACION_GET_SUCCESS, payload:  identificacionInfo}); 
 
   
 
  
  }