import {  
    FAMILIA_LISTA_FAIL,
    FAMILIA_LISTA_REQUEST,
    FAMILIA_LISTA_SUCCESS, 
    FAMILIA_UPDATE_FAIL, 
    FAMILIA_UPDATE_REQUEST,
    FAMILIA_UPDATE_SUCCESS,
    FAMILIA_UPDATE_RESET
  } from '../constants/familiaConstants';
 
    export const familiasReducer = ( state = { loading:true,  familiasLista: [] },  action) => {
    switch (action.type) {
      case FAMILIA_LISTA_REQUEST:
        return { loading: true };
      case FAMILIA_LISTA_SUCCESS:
        return { loading: false, familiasLista: action.payload };
      case FAMILIA_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const familiaUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case FAMILIA_UPDATE_REQUEST:
        return { loading: true };
      case FAMILIA_UPDATE_SUCCESS:
        return { loading: false, success: true  };
      case FAMILIA_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case FAMILIA_UPDATE_RESET:
          return {};
      default:
        return state;
    }
  };


 