import { MERCADERIASSTOCK_LISTA_FAIL, MERCADERIASSTOCK_LISTA_REQUEST, MERCADERIASSTOCK_LISTA_SUCCESS, 
  MERCADERIASSTOCK_UPDATE_FAIL, MERCADERIASSTOCK_UPDATE_REQUEST, MERCADERIASSTOCK_UPDATE_RESET, 
  MERCADERIASSTOCK_UPDATE_SUCCESS } from "../constants/MercaderiasStockConstants";

 
  
    export const MercaderiasStockReducer = ( state = { loading:true,  MercaderiasStockLista: [] },  action) => {
    switch (action.type) {
      case MERCADERIASSTOCK_LISTA_REQUEST:
        return { loading: true };
      case MERCADERIASSTOCK_LISTA_SUCCESS:
        return { loading: false, MercaderiasStockLista: action.payload };
      case MERCADERIASSTOCK_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const MercaderiasStockUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case MERCADERIASSTOCK_UPDATE_REQUEST:
        return { loading: true };
      case MERCADERIASSTOCK_UPDATE_SUCCESS:
        return { loading: false, success: true  };
      case MERCADERIASSTOCK_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case MERCADERIASSTOCK_UPDATE_RESET:
          return {};
      default:
        return state;
    }
  };