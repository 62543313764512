import { MERCADERIASGRUPOSMERCADERIAS_LISTA_FAIL, MERCADERIASGRUPOSMERCADERIAS_LISTA_REQUEST, MERCADERIASGRUPOSMERCADERIAS_LISTA_SUCCESS, 
  MERCADERIASGRUPOSMERCADERIAS_UPDATE_FAIL, MERCADERIASGRUPOSMERCADERIAS_UPDATE_REQUEST, MERCADERIASGRUPOSMERCADERIAS_UPDATE_SUCCESS } 
  from "../constants/Mercaderias_GruposMercaderias";

export const getlistamercaderiasgruposmercaderias = (GrupoMercaderiaId) => async (dispatch) => {
    dispatch({ type: MERCADERIASGRUPOSMERCADERIAS_LISTA_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId"),GrupoMercaderiaId: GrupoMercaderiaId };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/MercaderiasGruposMercaderia/Lista', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: MERCADERIASGRUPOSMERCADERIAS_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: MERCADERIASGRUPOSMERCADERIAS_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: MERCADERIASGRUPOSMERCADERIAS_LISTA_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: MERCADERIASGRUPOSMERCADERIAS_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const mercaderiasgruposmercaderiasUpdate = (GrupoMercaderiaId,MercaderiaId,Activar) => async (dispatch) => {
    dispatch({ type: MERCADERIASGRUPOSMERCADERIAS_UPDATE_REQUEST});
  
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") ,GrupoMercaderiaId: GrupoMercaderiaId,MercaderiaId:MercaderiaId,Activar:Activar};
     /*    dispatch({ type: MERCADERIASGRUPOSMERCADERIAS_UPDATE_FAIL, payload:  JSON.stringify(parametros) });
return;  */ 


      const response = await fetch('https://twinsappservice.azurewebsites.net/api/MercaderiasGruposMercaderia/Insert', {
             method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: MERCADERIASGRUPOSMERCADERIAS_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: MERCADERIASGRUPOSMERCADERIAS_UPDATE_FAIL, payload:  data });
           }
           else
           {
           dispatch({type: MERCADERIASGRUPOSMERCADERIAS_UPDATE_SUCCESS }); 
           }    
           
    } catch (error) {
      dispatch({
        type: MERCADERIASGRUPOSMERCADERIAS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };