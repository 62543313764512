import {  OPERADOR_SIGNIN_FAIL,
    OPERADOR_SIGNIN_REQUEST,
    OPERADOR_SIGNIN_SUCCESS,
    OPERADOR_SIGNOUT
    } from '../constants/operadorConstants';
export const signin = (email, password) => async (dispatch) => {
    dispatch({ type: OPERADOR_SIGNIN_REQUEST});
    
    try {
      var parametros = {Pass: password,User:  email};
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Operadores/GetOperadoresGetPorCredencialesWebSite', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
           if(!data || data.Id === 0)
           {dispatch({ type: OPERADOR_SIGNIN_FAIL, payload:  'Email o password no validos'  });}
           else if(data.Message)
           {
           dispatch({ type: OPERADOR_SIGNIN_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else
           {
           dispatch({type: OPERADOR_SIGNIN_SUCCESS, payload:  data});
           localStorage.setItem('operadorInfo', JSON.stringify(data));
           localStorage.setItem("empresaId",data.Empresa_Id)
           }
           
    } catch (error) {
      dispatch({
        type: OPERADOR_SIGNIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const signout = () => (dispatch) => {
    localStorage.removeItem('operadorInfo');
    localStorage.removeItem('empresaId');
    localStorage.removeItem('funcionalidadesLista');
    dispatch({ type: OPERADOR_SIGNOUT });
  };