import { MERCADERIAOPCIONESSTOCK_LISTA_FAIL, MERCADERIAOPCIONESSTOCK_LISTA_REQUEST, MERCADERIAOPCIONESSTOCK_LISTA_SUCCESS,
   MERCADERIAOPCIONESSTOCK_UPDATE_FAIL, MERCADERIAOPCIONESSTOCK_UPDATE_REQUEST, MERCADERIAOPCIONESSTOCK_UPDATE_SUCCESS } 
   from "../constants/MercaderiasOpcionesStockConstants";

 

export const getlistaMercaderiasOpcionesStock = (mercaderiaId) => async (dispatch) => {
    dispatch({ type: MERCADERIAOPCIONESSTOCK_LISTA_REQUEST});
    try {
      var parametros = {
                          EmpresaId: localStorage.getItem("empresaId"),
                          MercaderiaId: mercaderiaId
                       };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/MercaderiasOpcionesStock/Lista', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: MERCADERIAOPCIONESSTOCK_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: MERCADERIAOPCIONESSTOCK_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
 
           dispatch({type: MERCADERIAOPCIONESSTOCK_LISTA_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: MERCADERIAOPCIONESSTOCK_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const updateMercaderiasOpcionesStock = (Item) => async (dispatch, getState) => {
    dispatch({ type: MERCADERIAOPCIONESSTOCK_UPDATE_REQUEST});
    try {
      //const { operadorSignin: { operadorInfo }, } = getState(); 
      var parametros = {EmpresaId: localStorage.getItem("empresaId"),
                   MercaderiaCaracteristicaOpcion1_Id: Item.MercaderiaCaracteristicaOpcion1_Id ,
                   MercaderiaCaracteristicaOpcion2_Id: Item.MercaderiaCaracteristicaOpcion2_Id ,
                   Stock: Item.nStock ,
                   Precio: Item.nPrecio
                  };
    
                 // dispatch({ type: MERCADERIAOPCIONESSTOCK_UPDATE_FAIL, payload:  JSON.stringify(parametros) });
    const response = await fetch('https://twinsappservice.azurewebsites.net/api/MercaderiasOpcionesStock/Insert', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: MERCADERIAOPCIONESSTOCK_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: MERCADERIAOPCIONESSTOCK_UPDATE_FAIL, payload:  data });
           }
           else
           {
           dispatch({type: MERCADERIAOPCIONESSTOCK_UPDATE_SUCCESS }); 
           }   
           
    } catch (error) {
      dispatch({
        type: MERCADERIAOPCIONESSTOCK_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };