import { DESCUENTOS_DELETE_FAIL, DESCUENTOS_DELETE_REQUEST, DESCUENTOS_DELETE_RESET, DESCUENTOS_DELETE_SUCCESS, DESCUENTOS_LISTA_FAIL, DESCUENTOS_LISTA_REQUEST, DESCUENTOS_LISTA_SUCCESS, DESCUENTOS_UPDATEGRILLA_SUCCESS, DESCUENTOS_UPDATE_FAIL, DESCUENTOS_UPDATE_REQUEST, DESCUENTOS_UPDATE_RESET, DESCUENTOS_UPDATE_SUCCESS } from "../constants/DescuentosConstants";

 
 
  
    export const descuentosReducer = ( state = { loading:true,  descuentosLista: [] },  action) => {
    switch (action.type) {
      case DESCUENTOS_LISTA_REQUEST:
        return { loading: true };
      case DESCUENTOS_LISTA_SUCCESS:
        return { loading: false, descuentosLista: action.payload };
      case DESCUENTOS_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const descuentosUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case DESCUENTOS_UPDATE_REQUEST:
        return { loading: true };
      case DESCUENTOS_UPDATE_SUCCESS:
        return { loading: false, success: true  };
        case DESCUENTOS_UPDATEGRILLA_SUCCESS:
          return { loading: false  };
      case DESCUENTOS_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case DESCUENTOS_UPDATE_RESET:
          return {};
      default:
        return state;
    }
  };

  export const descuentosDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case DESCUENTOS_DELETE_REQUEST:
        return { loading: true };
      case DESCUENTOS_DELETE_SUCCESS:
        return { loading: false, success: true  };
      case DESCUENTOS_DELETE_FAIL:
        return { loading: false, error: action.payload };
        case DESCUENTOS_DELETE_RESET:
          return {};
      default:
        return state;
    }
  };