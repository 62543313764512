import {  
  IDENTIFICACION_GET_FAIL,
  IDENTIFICACION_GET_REQUEST,
  IDENTIFICACION_GET_SUCCESS, 
  IDENTIFICACION_UPDATE_FAIL, 
  IDENTIFICACION_UPDATE_REQUEST,
  IDENTIFICACION_UPDATE_RESET,
  IDENTIFICACION_UPDATE_SUCCESS
    } from '../constants/identificacionConstants';
    
    export const identificacionReducers = (state = {}, action) => {
      switch (action.type) {
        case IDENTIFICACION_GET_REQUEST:
          return { loading: true };
        case IDENTIFICACION_GET_SUCCESS:
          return { loading: false, identificacionInfo: action.payload };
        case IDENTIFICACION_GET_FAIL:
          return { loading: false, error: action.payload };
  
        default:
          return state;
      }
    };

    export const identificacionUpdateReducer = (state = {}, action) => {
      switch (action.type) {
        case IDENTIFICACION_UPDATE_REQUEST:
          return { loading: true };
        case IDENTIFICACION_UPDATE_SUCCESS:
          return { loading: false, success: true  };
        case IDENTIFICACION_UPDATE_FAIL:
          return { loading: false, error: action.payload };
          case IDENTIFICACION_UPDATE_RESET:
            return {};
        default:
          return state;
      }
    };