import { MERCADERIAOPCIONESSTOCK_LISTA_FAIL, MERCADERIAOPCIONESSTOCK_LISTA_REQUEST, MERCADERIAOPCIONESSTOCK_LISTA_SUCCESS, 
  MERCADERIAOPCIONESSTOCK_UPDATE_FAIL, MERCADERIAOPCIONESSTOCK_UPDATE_REQUEST, MERCADERIAOPCIONESSTOCK_UPDATE_RESET, 
  MERCADERIAOPCIONESSTOCK_UPDATE_SUCCESS } from "../constants/MercaderiasOpcionesStockConstants";

 
  
    export const MercaderiasOpcionesStockReducer = ( state = { loading:true,  MercaderiasOpcionesStockLista: [] },  action) => {
    switch (action.type) {
      case MERCADERIAOPCIONESSTOCK_LISTA_REQUEST:
        return { loading: true };
      case MERCADERIAOPCIONESSTOCK_LISTA_SUCCESS:
        return { loading: false, MercaderiasOpcionesStockLista: action.payload };
      case MERCADERIAOPCIONESSTOCK_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const MercaderiasOpcionesStockUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case MERCADERIAOPCIONESSTOCK_UPDATE_REQUEST:
        return { loading: true };
      case MERCADERIAOPCIONESSTOCK_UPDATE_SUCCESS:
        return { loading: false, success: true  };
      case MERCADERIAOPCIONESSTOCK_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case MERCADERIAOPCIONESSTOCK_UPDATE_RESET:
          return {};
      default:
        return state;
    }
  };