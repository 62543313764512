import React, { useEffect,  useState }  from 'react';
import { useDispatch,useSelector } from 'react-redux';  
import { makeStyles } from '@material-ui/core/styles'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import NumericInput from 'react-numeric-input';
import MessageBox from './MessageBox';
import { getcomboFamilias } from '../actions/comboActions';
import LoadingBox from './LoadingBox';
import { CircularProgress, Fab } from '@material-ui/core';
import { green } from '@material-ui/core/colors'; 
import clsx from 'clsx';
import { FamiliasPromocionesUpdate, getlistaFamiliasPromociones } from '../actions/familiaspromocionesActions';
import Mensaje from 'sweetalert';
import { FAMILIASPROMOCIONES_UPDATE_RESET } from '../constants/FamiliasPromocionesConstants';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save'; 
import 'primereact/resources/themes/saga-green/theme.css';
import { AutoComplete } from 'primereact/autocomplete';
import 'primeicons/primeicons.css';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    fontSize:'20px'
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 200,
  },
  formControlLabel: {
    marginTop: theme.spacing(0),
    marginLeft: '25px',
    label:{
    fontSize:'20px'},
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  root2: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function FamiliaPromocionNueva(props) {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');
  const dispatch = useDispatch(); 
 
  const familiaspromociones =  useSelector(state => state.familiaspromociones)
  const {  familiaspromocionesLista} = familiaspromociones; 
  const familiaspromocionesUpdate = useSelector((state) => state.familiaspromocionesUpdate);
  const {loading:loadingSave, success, error:errorSave} = familiaspromocionesUpdate;

  const [faltaFamilia,setFaltaFamilia]  = useState(null);
  const [faltaCantidad,setFaltaCantidad]  = useState(null);
  const [faltaPrecio,setFaltaPrecio]  = useState(null);

  
  const [Cantidad, setCantidad] = useState(0);
  const [Precio, setPrecio] = useState(0);
  const [Familia, setFamilia] = useState(null);
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });


    useEffect(() => { 
      setFaltaFamilia("");
    }, [dispatch,Familia])
    useEffect(() => { 
      setFaltaCantidad(0);
    }, [dispatch,Cantidad])
    useEffect(() => { 
      setFaltaPrecio(0);
    }, [dispatch,Precio])

    const handleClickOpen = () => {
    setOpen(true);
    };
    const handleClose = () => {
    setOpen(false);
    }

    const Guardar = () => { 
      if (!Familia || Familia.Id === 0) {
        setFaltaFamilia("Debe seleccionar la familia");
          return;}
      if (!Cantidad || Cantidad === 0) {
        setFaltaCantidad("Debe cargar la cantidad");
          return;}
      if (!Precio || Precio === 0) {
        setFaltaPrecio("Debe cargar el valor");
          return;}

        dispatch(FamiliasPromocionesUpdate(0,Familia.Id,Cantidad,Precio,true));

   };



   useEffect(() => {
    if (success) {
        Mensaje({  title: "Exito", text: 'Promocion generada satisfactoriamente' ,  icon: "success", button: "cerrar" });
        dispatch({ type: FAMILIASPROMOCIONES_UPDATE_RESET });
        dispatch(getlistaFamiliasPromociones());
        
    }
    if(errorSave)
    {
      Mensaje({ title: "Error",  text: errorSave, icon: "error", button: "cerrar" });
    }
  }, [dispatch, props.history, success,errorSave]);


  useEffect(() => { 
    setOpen(false);
}, [dispatch,familiaspromocionesLista])
  

 
    const comboFamilias =  useSelector(state => state.comboFamilias);
    const {loading: loadingFamilias, error: errorFamilias, FamiliasCombo} = comboFamilias;
    useEffect(() => { 
         dispatch(getcomboFamilias())
    }, [dispatch])
    
    const [filteredFamilias, setFilteredFamilias] = useState(null); 
    const searchFamilias = (event) => {
        setTimeout(() => {
            let filteredFamilias;
            if (!event.query.trim().length) {
                filteredFamilias = [...FamiliasCombo];
            }
            else {
              filteredFamilias = FamiliasCombo.filter((country) => {
                    return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            } 
            setFilteredFamilias(filteredFamilias);
        }, 250);
    }
   


    const itemTemplate = (item) => {
      return (
          <div className="country-item">
              <img  alt={item.sDescripcion} src={'https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
              onError={(e) => e.target.src='https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
              className={`flag flag-${item.sCodigo.toLowerCase()}`} />
              <div>{item.sDescripcion}</div>
          </div>
      );
    }
  return (
    <React.Fragment> 
       <i class="fa fa-plus-circle" aria-hidden="true" style={{fontSize:'72px',color:'black',cursor:'pointer',zIndex:"100", marginRight:"20px"}} onClick={handleClickOpen}></i>  
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >

        <DialogContent>
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
            <div className="row center">
       
        <h3>Nueva Promocion</h3>
        </div>
              <h5 htmlFor="Familia" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Familia </h5> 
                  <div>
                    {loadingFamilias? <LoadingBox></LoadingBox>
                    :
                    errorFamilias?<MessageBox variant="danger">{errorFamilias}</MessageBox>
                    :    
                    <AutoComplete value={Familia} suggestions={filteredFamilias} completeMethod={searchFamilias} 
                 field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                 onChange={(e) => setFamilia(  e.value   )} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
                </div>
                {faltaFamilia?<MessageBox variant="danger">{faltaFamilia}</MessageBox>:<></>}

                <h5 htmlFor="nCantidad" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Cantidad </h5>  
                <td> <NumericInput step={0.1} mobile  size={15} precision={3} min={ 0 }  required value={Cantidad} 
                            onChange={e => setCantidad(e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td>
                {faltaCantidad?<MessageBox variant="danger">{faltaCantidad}</MessageBox>:<></>}
      
 
                <h5 htmlFor="nPrecio" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Valor </h5>  
                <td> <NumericInput step={0.1} mobile  size={15} precision={3} min={ 0 }  required value={Precio} 
                            onChange={e => setPrecio(e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td>
                {faltaPrecio?<MessageBox variant="danger">{faltaPrecio}</MessageBox>:<></>}

            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
        <div className={classes.root2}>
              <div className={classes.wrapper}>
                <Fab
                  aria-label="save"
                  color="primary"
                  className={buttonClassname}
                  onClick={() => Guardar()}
                >
                  {success ? <CheckIcon /> : <SaveIcon />}
                </Fab>
                {loadingSave && <CircularProgress size={68} className={classes.fabProgress} />}
              </div>
            </div>

 
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}