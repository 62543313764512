import { FAMILIASPROMOCIONES_LISTA_FAIL, FAMILIASPROMOCIONES_LISTA_REQUEST, FAMILIASPROMOCIONES_LISTA_SUCCESS, FAMILIASPROMOCIONES_UPDATE_FAIL, 
  FAMILIASPROMOCIONES_UPDATE_REQUEST, FAMILIASPROMOCIONES_UPDATE_RESET, FAMILIASPROMOCIONES_UPDATE_SUCCESS,FAMILIASPROMOCIONES_UPDATEGRILLA_SUCCESS, FAMILIASPROMOCIONES_DELETE_REQUEST, FAMILIASPROMOCIONES_DELETE_SUCCESS, FAMILIASPROMOCIONES_DELETE_FAIL, FAMILIASPROMOCIONES_DELETE_RESET }
   from "../constants/FamiliasPromocionesConstants";

 
  
    export const familiaspromocionesReducer = ( state = { loading:true,  familiaspromocionesLista: [] },  action) => {
    switch (action.type) {
      case FAMILIASPROMOCIONES_LISTA_REQUEST:
        return { loading: true };
      case FAMILIASPROMOCIONES_LISTA_SUCCESS:
        return { loading: false, familiaspromocionesLista: action.payload };
      case FAMILIASPROMOCIONES_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const familiaspromocionesUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case FAMILIASPROMOCIONES_UPDATE_REQUEST:
        return { loading: true };
      case FAMILIASPROMOCIONES_UPDATE_SUCCESS:
        return { loading: false, success: true  };
        case FAMILIASPROMOCIONES_UPDATEGRILLA_SUCCESS:
          return { loading: false  };
      case FAMILIASPROMOCIONES_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case FAMILIASPROMOCIONES_UPDATE_RESET:
          return {};
      default:
        return state;
    }
  };

  export const familiaspromocionesDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case FAMILIASPROMOCIONES_DELETE_REQUEST:
        return { loading: true };
      case FAMILIASPROMOCIONES_DELETE_SUCCESS:
        return { loading: false, success: true  };
      case FAMILIASPROMOCIONES_DELETE_FAIL:
        return { loading: false, error: action.payload };
        case FAMILIASPROMOCIONES_DELETE_RESET:
          return {};
      default:
        return state;
    }
  };
