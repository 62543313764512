import {  COMBO_UNIDADES_REQUEST,
    COMBO_UNIDADES_FAIL,
    COMBO_UNIDADES_SUCCESS, 
    COMBO_MERCADERIATIPOS_REQUEST,
    COMBO_MERCADERIATIPOS_FAIL,
    COMBO_MERCADERIATIPOS_SUCCESS,
    COMBO_FAMILIAS_REQUEST,
    COMBO_FAMILIAS_FAIL,
    COMBO_FAMILIAS_SUCCESS,
    COMBO_IMPUESTOS_REQUEST,
    COMBO_IMPUESTOS_FAIL,
    COMBO_IMPUESTOS_SUCCESS,
    COMBO_ETIQUETAS_REQUEST,
    COMBO_ETIQUETAS_FAIL,
    COMBO_ETIQUETAS_SUCCESS,
    COMBO_DEFINICIONESCUARTO_REQUEST,
    COMBO_DEFINICIONESCUARTO_FAIL,
    COMBO_DEFINICIONESCUARTO_SUCCESS,
    COMBO_NOMENCLADORES_REQUEST,
    COMBO_NOMENCLADORES_FAIL,
    COMBO_NOMENCLADORES_SUCCESS,
    COMBO_CLASIFICACIONES_REQUEST,
    COMBO_CLASIFICACIONES_FAIL,
    COMBO_CLASIFICACIONES_SUCCESS,
    COMBO_MERCADERIASAFIP_REQUEST,
    COMBO_MERCADERIASAFIP_FAIL,
    COMBO_MERCADERIASAFIP_SUCCESS,
    COMBO_GRUPOSMERCADERIA_REQUEST,
    COMBO_GRUPOSMERCADERIA_FAIL,
    COMBO_GRUPOSMERCADERIA_SUCCESS,
    COMBO_MERCADERIAS_REQUEST,
    COMBO_MERCADERIAS_FAIL,
    COMBO_MERCADERIAS_SUCCESS,
    COMBO_DIAS_SUCCESS,
    COMBO_METODOSPAGO_REQUEST,
    COMBO_METODOSPAGO_FAIL,
    COMBO_METODOSPAGO_SUCCESS
    } from '../constants/comboConstants';

export const getcomboUnidades = () => async (dispatch) => {
    dispatch({ type: COMBO_UNIDADES_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Combos/Unidades', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_UNIDADES_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_UNIDADES_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_UNIDADES_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_UNIDADES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getcomboMercaderiaTipos = () => async (dispatch) => {
    dispatch({ type: COMBO_MERCADERIATIPOS_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Combos/TiposMercaderia', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_MERCADERIATIPOS_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_MERCADERIATIPOS_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_MERCADERIATIPOS_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_MERCADERIATIPOS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getcomboFamilias = () => async (dispatch) => {
    dispatch({ type: COMBO_FAMILIAS_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Combos/Familias', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_FAMILIAS_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_FAMILIAS_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_FAMILIAS_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_FAMILIAS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getcomboImpuestos = () => async (dispatch) => {
    dispatch({ type: COMBO_IMPUESTOS_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Combos/Impuestos', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_IMPUESTOS_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_IMPUESTOS_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_IMPUESTOS_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_IMPUESTOS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getcomboEtiquetas = () => async (dispatch) => {
    dispatch({ type: COMBO_ETIQUETAS_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/ConfiguracionPC/Etiquetas', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_ETIQUETAS_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_ETIQUETAS_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_ETIQUETAS_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_ETIQUETAS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getcomboDefinicionesCuarto = () => async (dispatch) => {
    dispatch({ type: COMBO_DEFINICIONESCUARTO_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Combos/DefinicionesCuarto', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_DEFINICIONESCUARTO_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_DEFINICIONESCUARTO_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_DEFINICIONESCUARTO_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_DEFINICIONESCUARTO_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getcomboNomencladores = () => async (dispatch) => {
    dispatch({ type: COMBO_NOMENCLADORES_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Combos/Nomencladores', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_NOMENCLADORES_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_NOMENCLADORES_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_NOMENCLADORES_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_NOMENCLADORES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getcomboClasificaciones = () => async (dispatch) => {
    dispatch({ type: COMBO_CLASIFICACIONES_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Combos/Clasificaciones', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_CLASIFICACIONES_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_CLASIFICACIONES_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_CLASIFICACIONES_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_CLASIFICACIONES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getcomboMercaderiasAfip = () => async (dispatch) => {
    dispatch({ type: COMBO_MERCADERIASAFIP_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Combos/MercaderiasAfip', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_MERCADERIASAFIP_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_MERCADERIASAFIP_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_MERCADERIASAFIP_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_MERCADERIASAFIP_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getcomboGruposMercaderia = () => async (dispatch) => {
    dispatch({ type: COMBO_GRUPOSMERCADERIA_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Combos/GruposMercaderia', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_GRUPOSMERCADERIA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_GRUPOSMERCADERIA_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_GRUPOSMERCADERIA_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_GRUPOSMERCADERIA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getcomboMercaderias = () => async (dispatch) => {
    dispatch({ type: COMBO_MERCADERIAS_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Combos/Mercaderias', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_MERCADERIAS_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_MERCADERIAS_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_MERCADERIAS_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_MERCADERIAS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getcomboDias = () => async (dispatch) => {
 
    let lstDias = [];
    lstDias.push({Id:1, sDescripcion:"Domingo"})
    lstDias.push({Id:2, sDescripcion:"Lunes"})
    lstDias.push({Id:3, sDescripcion:"Martes"})
    lstDias.push({Id:4, sDescripcion:"Miercoles"})
    lstDias.push({Id:5, sDescripcion:"Jueves"})
    lstDias.push({Id:6, sDescripcion:"Viernes"})
    lstDias.push({Id:7, sDescripcion:"Sabado"})

           dispatch({type: COMBO_DIAS_SUCCESS, payload:  lstDias}); 
    
  
  };

  export const getcomboMetodosPago = () => async (dispatch) => {
    dispatch({ type: COMBO_METODOSPAGO_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Combos/MetodosPago', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: COMBO_METODOSPAGO_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: COMBO_METODOSPAGO_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: COMBO_METODOSPAGO_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: COMBO_METODOSPAGO_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
 