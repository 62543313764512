import { MERCADERIASSTOCK_LISTA_FAIL, MERCADERIASSTOCK_LISTA_REQUEST, MERCADERIASSTOCK_LISTA_SUCCESS,
   MERCADERIASSTOCK_UPDATE_FAIL, MERCADERIASSTOCK_UPDATE_REQUEST, MERCADERIASSTOCK_UPDATE_SUCCESS } 
   from "../constants/MercaderiasStockConstants";

 

export const getlistaMercaderiasStock = (Disponibles,Eliminadas) => async (dispatch) => {
    dispatch({ type: MERCADERIASSTOCK_LISTA_REQUEST});
    try {
      var parametros = {
                          EmpresaId: localStorage.getItem("empresaId") 
                       };
  
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/MercaderiasAppStock/MercaderiasLista', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: MERCADERIASSTOCK_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: MERCADERIASSTOCK_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
            let dtResultados = [];
            if ((Disponibles?Disponibles:false) === true && (Eliminadas?Eliminadas:false) ===true)
            dtResultados=data.Lista;
            else if ((Disponibles?Disponibles:false) === true)
            dtResultados=data.Lista.filter(r => r.bActivo === true);
            else if ((Eliminadas?Eliminadas:false) === true)
            dtResultados=data.Lista.filter(r => r.bActivo === false);
      
           dispatch({type: MERCADERIASSTOCK_LISTA_SUCCESS, payload:  dtResultados}); 
           }
           
    } catch (error) {
      dispatch({
        type: MERCADERIASSTOCK_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const updateMercaderiasStock = (parametros) => async (dispatch, getState) => {
    dispatch({ type: MERCADERIASSTOCK_UPDATE_REQUEST});
    try {
      const { operadorSignin: { operadorInfo }, } = getState(); 
      parametros.EmpresaId = localStorage.getItem("empresaId");
      parametros.OperadorId = operadorInfo.Id;
     /*console.log(JSON.stringify(parametros));
      dispatch({ type: MERCADERIASSTOCK_UPDATE_FAIL, payload:  JSON.stringify(parametros) });
   return;*/
    const response = await fetch('https://twinsappservice.azurewebsites.net/api/MercaderiasAppStock/MercaderiasUpdate', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: MERCADERIASSTOCK_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: MERCADERIASSTOCK_UPDATE_FAIL, payload:  data });
           }
           else
           {
           dispatch({type: MERCADERIASSTOCK_UPDATE_SUCCESS }); 
           }    
           
    } catch (error) {
      dispatch({
        type: MERCADERIASSTOCK_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
