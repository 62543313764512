import {  IDENTIFICACION_GET_FAIL,
  IDENTIFICACION_GET_REQUEST,
  IDENTIFICACION_GET_SUCCESS, 
  IDENTIFICACION_UPDATE_FAIL, 
  IDENTIFICACION_UPDATE_REQUEST,
  IDENTIFICACION_UPDATE_SUCCESS,

    } from '../constants/identificacionConstants';

export const IdentificacionGet = () => async (dispatch) => {
    dispatch({ type: IDENTIFICACION_GET_REQUEST});
    
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Identificacion/Get', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
           if(!data || data.Id === 0)
           {dispatch({ type: IDENTIFICACION_GET_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });}
           else if(data.Message)
           {
           dispatch({ type: IDENTIFICACION_GET_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else
           {
           dispatch({type: IDENTIFICACION_GET_SUCCESS, payload:  data});
           }
           
    } catch (error) {
      dispatch({
        type: IDENTIFICACION_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

 export const IdentificacionUpdate = (Item) => async (dispatch) => {
    dispatch({ type: IDENTIFICACION_UPDATE_REQUEST});
  
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") ,Item: Item};
      /* dispatch({ type: IDENTIFICACION_UPDATE_FAIL, payload:  JSON.stringify(parametros) });
return; */


      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Identificacion/Update', {
             method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: IDENTIFICACION_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: IDENTIFICACION_UPDATE_FAIL, payload:  data });
           }
           else
           {
           dispatch({type: IDENTIFICACION_UPDATE_SUCCESS }); 
           }    
           
    } catch (error) {
      dispatch({
        type: IDENTIFICACION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };