import React  from 'react';
import NumericInput from 'react-numeric-input';
import { useDispatch,useSelector } from 'react-redux';
import { MERCADERIA_GET_SUCCESS } from '../../constants/mercaderiaConstants';

export default function MercaderiaDetalleLimites() {
  const dispatch = useDispatch();
  const mercaderiaGet =  useSelector(state => state.mercaderiaGet)
  const { mercaderiaItem} = mercaderiaGet;
  const Modificar = (campo,e) => 
  {
    mercaderiaItem[campo] = e;
    dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});    
  }
    return (
      mercaderiaItem &&
            <form className="form">
         
            
<tbody>
    <tr>
        <td><h5 htmlFor="CantidadPiezasXCaja" style={{textAlign:"right"}}>PiezasXCaja: </h5></td>
        <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.hCantidadPiezasxCaja} 
                            onChange={e => Modificar('hCantidadPiezasxCaja',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>
            
            <tr><td><h5 htmlFor="Porciones" style={{textAlign:"right"}}>Porciones: </h5></td>
           <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.hPorciones} 
                            onChange={e => Modificar('hPorciones',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>

          <tr><td><h5 htmlFor="txtPesoMinimoPieza" style={{textAlign:"right"}}>Peso Min Pieza(gr): </h5></td>
           <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.iPesoMinimoPieza} 
                            onChange={e => Modificar('iPesoMinimoPieza',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>
           <tr><td><h5 htmlFor="txtPesoMaximoPieza" style={{textAlign:"right"}}>Peso Max Pieza(gr): </h5></td>
           <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.iPesoMaximoPieza} 
                            onChange={e => Modificar('iPesoMaximoPieza',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>

            <tr><td><h5 htmlFor="Absorcion" style={{textAlign:"right"}}>Absorcion: </h5></td>
           <td> <NumericInput step={0.1} mobile  size={6} precision={3} min={ 0 }  required value={mercaderiaItem.nAbsorcion} 
                            onChange={e => Modificar('nAbsorcion',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>

            <tr><td><h5 htmlFor="txtPesoEstandar" style={{textAlign:"right"}}>Peso Estandar(gr): </h5></td>
           <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.iPesoEstandar} 
                            onChange={e => Modificar('iPesoEstandar',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>

            <tr><td><h5 htmlFor="txtPalletCantidad" style={{textAlign:"right"}}>Cajas Por Pallet: </h5></td>
           <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.hPalletCajas} 
                            onChange={e => Modificar('hPalletCajas',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>

            <tr><td><h5 htmlFor="txtComposiciones" style={{textAlign:"right"}}>% de Composición: </h5></td>
           <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.hComposiciones} 
                            onChange={e => Modificar('hComposiciones',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>

            <tr><td><h5 htmlFor="hCantidadMinima" style={{textAlign:"right"}}>Cantidad Mínima: </h5></td>
           <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.hCantidadMinima} 
                            onChange={e => Modificar('hCantidadMinima',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>

<tr><td><h5 htmlFor="hCantidadMaxima" style={{textAlign:"right"}}>Cantidad Máxima: </h5></td>
           <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.hCantidadMaxima} 
                            onChange={e => Modificar('hCantidadMaxima',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>

<tr><td><h5 htmlFor="txtPorcentajeControlDespacho" style={{textAlign:"right"}}>% Control Despacho: </h5></td>
           <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.hPorcentajeControlDespacho} 
                            onChange={e => Modificar('hPorcentajeControlDespacho',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>
         </tbody>
      </form>

    )
}
