import {  
      OPERADOR_SIGNIN_FAIL,
      OPERADOR_SIGNIN_REQUEST,
      OPERADOR_SIGNIN_SUCCESS,
      OPERADOR_SIGNOUT,
      PANTALLAATRAS,
      PANTALLASELECCIONADA
    } from '../constants/operadorConstants';
    
    export const operadorSigninReducer = (state = {}, action) => {
      switch (action.type) {
        case OPERADOR_SIGNIN_REQUEST:
          return { loading: true };
        case OPERADOR_SIGNIN_SUCCESS:
          return { loading: false, operadorInfo: action.payload };
        case OPERADOR_SIGNIN_FAIL:
          return { loading: false, error: action.payload };
        case OPERADOR_SIGNOUT:
          return {};
        default:
          return state;
      }
    };

    export const pantallaSeleccionadaReducer = (state = {}, action) => {
      switch (action.type) {
        case PANTALLASELECCIONADA:
          return { loading: false, pantallaInfo: action.payload };
        default:
          return state;
      }
    };
  
    export const pantallaAtrasReducer = (state = {}, action) => {
      switch (action.type) {
          case PANTALLAATRAS:
            return { loading: false, pantallaAtrasInfo: action.payload };
        default:
          return state;
      }
    };