import React,{useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CircularProgress, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors'; 
import clsx from 'clsx';
import Mensaje from 'sweetalert';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';  
import { GRUPOSMERCADERIA_LISTA_SUCCESS, GRUPOSMERCADERIA_UPDATE_RESET } from '../constants/gruposmercaderiaConstants';
import imageCompression from 'browser-image-compression';
import { updateGruposMercaderia } from '../actions/gruposmercaderiaActions';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import NumericInput from 'react-numeric-input';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    root2: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    }, 
  }));


export default function GruposMercaderiaDetalleScreen(props) {
  const GrupoId = props.match.params.id;

  const gruposmercaderiaUpdate = useSelector((state) => state.gruposmercaderiaUpdate);
  const {loading:loadingSave, success, error:errorSave} = gruposmercaderiaUpdate;

  const gruposmercaderia =  useSelector(state => state.gruposmercaderia)
  const {  gruposmercaderiaLista} = gruposmercaderia;
  let dtGrupo = {
      Error: '',
      Id: 0,
      sCodigo: '',
      sDescripcion: '',
      sObservaciones:'',
      iOrden:1
    };
  if(GrupoId > 0)
  {
    dtGrupo = gruposmercaderiaLista.find(l => {
      return  l.Id === Number(GrupoId)});
  }

  const [Codigo, setCodigo] = useState(dtGrupo.sCodigo);
  const [Descripcion, setDescripcion] = useState(dtGrupo.sDescripcion);
  const [Activo, setActivo] = useState(dtGrupo.bActivo);
  const [LinkFoto, setLinkFoto] = useState(dtGrupo.LinkFoto);
  const [Observaciones,setObservaciones]= useState(dtGrupo.sObservaciones);
  const [NombreFotoNueva, setNombreFotoNueva] = useState('');
  const [Orden,setOrden]= useState(dtGrupo.iOrden);
  
 

const classes = useStyles();
const dispatch = useDispatch();
const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
      });
      const Guardar = () => { 
        if (Codigo === "") {
          Mensaje({
                title: "Debe Cargar el Codigo",
                text: "",
                icon: "error",
                button: "cerrar",
              });}             
      else if (Descripcion === "") {
                  Mensaje({
                        title: "Debe Cargar la descripcion",
                        text: "",
                        icon: "error",
                        button: "cerrar",
                      });
      } else {
        dtGrupo.sCodigo=Codigo;
        dtGrupo.sDescripcion=Descripcion;
        dtGrupo.bActivo=Activo;
        dtGrupo.LinkFoto=LinkFoto;
        dtGrupo.sObservaciones=Observaciones;
        dtGrupo.iOrden=Orden;
          dispatch(updateGruposMercaderia(dtGrupo,NombreFotoNueva));
          
      } 
     };

     useEffect(()=>{
      dispatch({type: PANTALLASELECCIONADA, payload: (props.match.params.id === "0" ? 'Nuevo Grupo' : "Editando Grupo Id : " + props.match.params.id)  }); 
      dispatch({type: PANTALLAATRAS, payload:  'GruposMercaderia'}); 
  },[dispatch,props.match.params.id])

     useEffect(() => {
        if (success) {
            Mensaje({  title: "Exito", text: 'Grupo guardado satisfactoriamente' ,  icon: "success", button: "cerrar" });
            dispatch({ type: GRUPOSMERCADERIA_UPDATE_RESET });
            props.history.push(`/GruposMercaderia`);
        }
        if(errorSave)
        {
          Mensaje({ title: "Error",  text: errorSave, icon: "error", button: "cerrar" });
          dispatch({ type: GRUPOSMERCADERIA_UPDATE_RESET });
        }
      }, [dispatch, props.history, success,errorSave]);

const handledelete = (e) =>{
         setLinkFoto("");  
         setNombreFotoNueva("");  
        dispatch({type: GRUPOSMERCADERIA_LISTA_SUCCESS, payload:  gruposmercaderiaLista});     
}

const [highlight,setHighlight]=useState(false);
const handlehighlight = (e) =>{
    e.preventDefault();
    e.stopPropagation();
     setHighlight(true);
}

const handleunhighlight = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);
}

const handledrop = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    let dt =e.dataTransfer;
    let files = dt.files;
    handfiles(files);
    setHighlight(false);
}

const handlefilechange = (e) =>{
  let files = e.target.files;
  handfiles(files);
}

const  subirFotoCarac = async (file)   => { 
  const imageFile = file;
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(imageFile, options);
    var reader = new FileReader();
    reader.readAsDataURL(compressedFile); 
    reader.onloadend = function() {  
      setLinkFoto(reader.result);
      setNombreFotoNueva(file.name);
}
  } catch (error) {
    console.log(error);
  }
};

const handfiles = files => {   
    for(let file of files){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () =>{
          subirFotoCarac(file); })
    }
}


    return (
        <>

        ({  

<div className="row top" style={{marginTop:"10px" , marginLeft:"20px" }} > 

<div className={classes.root2} style={{ position: 'fixed', top: 0,  right: 0,  justifyContent: 'center', alignItems: 'center', marginTop:"50px"}} >
              <div className={classes.wrapper}>
                  <Fab aria-label="save"  color="primary" className={buttonClassname} onClick={() => Guardar()}  >
                    {success ? <CheckIcon /> : <SaveIcon />}
                  </Fab>
                  {loadingSave && <CircularProgress size={68} className={classes.fabProgress} />}
              </div>
            </div>
  

<form className="form"   >  

<div className={highlight? "custom-file-drop-area2 highlight":"custom-file-drop-area2"} onDragEnter={handlehighlight} 
                onDragOver={handlehighlight} onDragLeave={handleunhighlight} onDrop={handledrop}>
                    <input type="file"name="photos" placeholder="Enter photos"   id="filephotos" onChange={handlefilechange}/>
                    <label htmlFor="filephotos" >Imagen de Presentacion Arrastre o click para seleccionar</label>
                    {LinkFoto !== "" &&
                    <div className="custom-file-preview">
                   
                 <div className="prev-img"  >
                     <span onClick={() => handledelete(LinkFoto)}>&times;</span>
                     <img className="smallGrilla" src={LinkFoto} alt={LinkFoto}/>
                 </div>
         </div>}
                </div>


        {dtGrupo.Id > 0 && <>
        <Link onClick={() => {setActivo(!Activo)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
        <div> 
            {Activo ? <i class="fa fa-check-square-o" 
                            aria-hidden="true" 
                            style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                    : <i class="fa fa-square-o" 
                            aria-hidden="true" 
                            style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
            } Activo
        </div> 
    </Link> 
    </>}    




       <h5 htmlFor="Codigo" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>Codigo </h5>  
                <input  
                id="Codigo"
                type="text"
                required
                placeholder="Ingrese Codigo"
                value={Codigo}
                onChange={(e) => setCodigo( e.target.value)} ></input>

<h5 htmlFor="Descripcion" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>Descripcion </h5>  
                <input  
                id="Descripcion"
                type="text"
                required
                placeholder="Ingrese Descripcion"
                value={Descripcion}
                onChange={(e) => setDescripcion( e.target.value)} ></input>

<div>
<h5 htmlFor="Descripcion" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0", textAlign:"start"}}>Orden </h5>  
        <div>  <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={Orden} 
                            onChange={e => setOrden( e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/> </div></div>


<div>
                      <h5 htmlFor="comment" style={{marginLeft:"5px", width:"100%",  textAlign:"start", verticalAlign:"end" , marginBottom:"0"}}>
                        Observaciones </h5>  
                      <textarea style={{marginLeft:"5px",width:"100%",minHeight:"200px", verticalAlign:"end", marginBottom:"0",}}
                        id="comment" maxLength="3999"
                        value={Observaciones}
                        onChange={(e) => setObservaciones(e.target.value)}
                      ></textarea>
                    </div>

</form>
</div>

            }
            </>
    )
}
     