import { GRUPOSMERCADERIA_LISTA_FAIL, GRUPOSMERCADERIA_LISTA_REQUEST, GRUPOSMERCADERIA_LISTA_SUCCESS, GRUPOSMERCADERIA_UPDATE_FAIL, GRUPOSMERCADERIA_UPDATE_REQUEST, GRUPOSMERCADERIA_UPDATE_RESET, GRUPOSMERCADERIA_UPDATE_SUCCESS } from "../constants/gruposmercaderiaConstants";

 
    export const gruposmercaderiaReducer = ( state = { loading:true,  gruposmercaderiaLista: [] },  action) => {
    switch (action.type) {
      case GRUPOSMERCADERIA_LISTA_REQUEST:
        return { loading: true };
      case GRUPOSMERCADERIA_LISTA_SUCCESS:
        return { loading: false, gruposmercaderiaLista: action.payload };
      case GRUPOSMERCADERIA_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const gruposmercaderiaUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case GRUPOSMERCADERIA_UPDATE_REQUEST:
        return { loading: true };
      case GRUPOSMERCADERIA_UPDATE_SUCCESS:
        return { loading: false, success: true  };
      case GRUPOSMERCADERIA_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case GRUPOSMERCADERIA_UPDATE_RESET:
          return {};
      default:
        return state;
    }
  };