import React, {useState, useEffect,useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux'; 
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import { Column } from 'primereact/column'; 
import { getlistaDescuentos } from '../actions/descuentosActions';
import { DESCUENTOSMERCADERIAS_DESCUENTOSELECCIONADO } from '../constants/constantesConstants';


export default function DescuentosGrilla(props) {
    const descuentos =  useSelector(state => state.descuentos)
    const {loading, descuentosLista} = descuentos;
    const [selectedDescuento, setSelectedDescuento] = useState(null);
    const [loadingdata, setLoadingdata] = useState(false);
    const dt = useRef(null);

    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getlistaDescuentos());
    }, [dispatch]);

    useEffect(() =>{
        setLoadingdata(loading);
    },[dispatch,loading]
    )

    const IdBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="p-column-title">Id</span>
            {rowData.Id}
        </React.Fragment>
    );}

    const DiaHastaBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="p-column-title">DiaHasta</span>
            {rowData.sDiaHasta}
        </React.Fragment>
    );}

    const MetodoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Metodo</span>
                {rowData.MetodoPagoDescripcion}
            </React.Fragment>
        );}
 
    const DiaDesdeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">DiaDesde</span>
                {rowData.sDiaDesde}
            </React.Fragment>
        );}

    const HoraHastaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">HoraHasta</span>
                {rowData.sHoraHasta}
            </React.Fragment>
        );}
        
    const HoraDesdeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">HoraDesde</span>
                {rowData.sHoraDesde}
            </React.Fragment>
        ); }
 
    const DescuentoBodyTemplate = (rowData) => {
        return   <React.Fragment>
                <span className="p-column-title">Descuento</span>
                {rowData.nDescuento}
                </React.Fragment>
    }

    const PorcentajeBodyTemplate = (rowData, column) => {
    if(!rowData.bEsPorcentaje )
        {
          return (
            <React.Fragment>
            <span className="p-column-title">%</span>
              
                        <i class="fa fa-circle-o" aria-hidden="true" 
                            style={   { fontSize: "2rem", color:'black'  ,cursor: "hand" , verticalAlign: "bottom"  }   }    
                          />                
        </React.Fragment>);
        }
        else
        {
            return (
                <React.Fragment>
                <span className="p-column-title">%</span>
                   
                            <i class="fa fa-check-circle-o" aria-hidden="true" 
                                style={   { fontSize: "2rem", color:'black'  ,cursor: "hand" , verticalAlign: "bottom"  }   }    
                                 />
            </React.Fragment>);
        }}

const customFunction = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0
}

const onChangeDescuento = (row) =>{
    setSelectedDescuento(row);
    if(row === null)
    { dispatch({type: DESCUENTOSMERCADERIAS_DESCUENTOSELECCIONADO, payload:  []}); }
    else
    {
   
    dispatch({type: DESCUENTOSMERCADERIAS_DESCUENTOSELECCIONADO, payload:  row}); 
    }
}


    return (<div style={{marginBottom:'0px'}}>
    
                        <div className="datatable-doc-demo" style={{paddingTop:'0px'}}>
                        <DataTable ref={dt} value={descuentosLista} className="p-datatable-customers" dataKey="Id"
                        rowHover  loading={loadingdata}
                        selectionMode="single" selection={selectedDescuento} onSelectionChange={e => onChangeDescuento(e.value)} 
                        paginator rows={5} emptyMessage="No se encontraron Pedidos" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[5,10,25,50]}
>
        <Column field="Id" header="Id"  body={IdBodyTemplate} headerStyle={{ width:"8rem",  textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="sDiaDesde" header="DiaDesde" filterMatchMode="custom" filterFunction={customFunction} body={DiaDesdeBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="sDiaHasta" header="DiaHasta" filterMatchMode="custom" filterFunction={customFunction} body={DiaHastaBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="sHoraDesde" header="HoraDesde" filterMatchMode="custom" filterFunction={customFunction} body={HoraDesdeBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="sHoraHasta" header="HoraHasta" filterMatchMode="custom" filterFunction={customFunction} body={HoraHastaBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="MetodoPagoDescripcion" header="Metodo" filterMatchMode="custom" filterFunction={customFunction} body={MetodoBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column  field="bEsPorcentaje" header="%" body={PorcentajeBodyTemplate} headerStyle={{width:"4rem", textAlign: 'center' }}  />
        <Column field="nDescuento" header="Descuento" body={DescuentoBodyTemplate} ></Column>
</DataTable>       
</div>
</div>
);

}