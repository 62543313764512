import { RANGOSHORARIO_LISTA_FAIL, RANGOSHORARIO_LISTA_REQUEST, RANGOSHORARIO_LISTA_SUCCESS, RANGOSHORARIO_UPDATE_FAIL, 
  RANGOSHORARIO_UPDATE_REQUEST, RANGOSHORARIO_UPDATE_RESET, RANGOSHORARIO_UPDATE_SUCCESS,RANGOSHORARIO_UPDATEGRILLA_SUCCESS, RANGOSHORARIO_DELETE_REQUEST, RANGOSHORARIO_DELETE_SUCCESS, RANGOSHORARIO_DELETE_FAIL, RANGOSHORARIO_DELETE_RESET }
   from "../constants/RangosHorarioConstants";

 
  
    export const RangosHorarioReducer = ( state = { loading:true,  RangosHorarioLista: [] },  action) => {
    switch (action.type) {
      case RANGOSHORARIO_LISTA_REQUEST:
        return { loading: true };
      case RANGOSHORARIO_LISTA_SUCCESS:
        return { loading: false, RangosHorarioLista: action.payload };
      case RANGOSHORARIO_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const RangosHorarioUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case RANGOSHORARIO_UPDATE_REQUEST:
        return { loading: true };
      case RANGOSHORARIO_UPDATE_SUCCESS:
        return { loading: false, success: true  };
        case RANGOSHORARIO_UPDATEGRILLA_SUCCESS:
          return { loading: false  };
      case RANGOSHORARIO_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case RANGOSHORARIO_UPDATE_RESET:
          return {};
      default:
        return state;
    }
  };

  export const RangosHorarioDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case RANGOSHORARIO_DELETE_REQUEST:
        return { loading: true };
      case RANGOSHORARIO_DELETE_SUCCESS:
        return { loading: false, success: true  };
      case RANGOSHORARIO_DELETE_FAIL:
        return { loading: false, error: action.payload };
        case RANGOSHORARIO_DELETE_RESET:
          return {};
      default:
        return state;
    }
  };