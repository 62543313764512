import { PEDIDO_APROBAR_FAIL, PEDIDO_APROBAR_REQUEST, PEDIDO_APROBAR_SUCCESS, PEDIDO_CERRAR_FAIL, PEDIDO_CERRAR_REQUEST, PEDIDO_CERRAR_SUCCESS, PEDIDO_ELIMINAR_FAIL, PEDIDO_ELIMINAR_REQUEST, PEDIDO_ELIMINAR_SUCCESS, PEDIDO_LISTA_FAIL, PEDIDO_LISTA_REQUEST, PEDIDO_LISTA_SUCCESS } from "../constants/pedidoConstants";

export const getlistaPedidos = (FechaDesde, FechaHasta) => async (dispatch) => {
    dispatch({ type: PEDIDO_LISTA_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId"), Desde:FechaDesde,Hasta: FechaHasta};
 
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/ListaWeb', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: PEDIDO_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: PEDIDO_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: PEDIDO_LISTA_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: PEDIDO_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const aprobarPedido = (Id, Mensaje) => async (dispatch, getState) => {
    dispatch({ type: PEDIDO_APROBAR_REQUEST});
    try {
          const { operadorSignin: { operadorInfo }, } = getState(); 
          var parametros = {EmpresaId: localStorage.getItem("empresaId"), PedidoId: Id,Mensaje:Mensaje, OperadorId: operadorInfo.Id};
          
  
          const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/AprobarWeb', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
          if(data.Message)
          {
            dispatch({ type: PEDIDO_APROBAR_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
          }
          else if(data !== "")
          {
            dispatch({ type: PEDIDO_APROBAR_FAIL, payload:  data });
          }
          else
          {
            dispatch({type: PEDIDO_APROBAR_SUCCESS }); 
          }  
    } catch (error) {
      dispatch({
        type: PEDIDO_APROBAR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const cerrarPedido = (Id, Mensaje) => async (dispatch, getState) => {
    dispatch({ type: PEDIDO_CERRAR_REQUEST});
    try {
          const { operadorSignin: { operadorInfo }, } = getState(); 
          var parametros = {EmpresaId: localStorage.getItem("empresaId"), PedidoId: Id,Mensaje:Mensaje, OperadorId: operadorInfo.Id};
          
  
          const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/CerrarWeb', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
          if(data.Message)
          {
            dispatch({ type: PEDIDO_CERRAR_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
          }
          else if(data !== "")
          {
            dispatch({ type: PEDIDO_CERRAR_FAIL, payload:  data });
          }
          else
          {
            dispatch({type: PEDIDO_CERRAR_SUCCESS }); 
          }  
    } catch (error) {
      dispatch({
        type: PEDIDO_CERRAR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const eliminarPedido = (Id, Mensaje, MercadoPagoId) => async (dispatch, getState) => {
    dispatch({ type: PEDIDO_ELIMINAR_REQUEST});
    try {
          const { operadorSignin: { operadorInfo }, } = getState(); 
          var parametros = {EmpresaId: localStorage.getItem("empresaId"), 
                            PedidoId: Id,
                            Mensaje:Mensaje, 
                            OperadorId: operadorInfo.Id,
                            MercadoPagoId: MercadoPagoId};
                            
         /*  dispatch({ type: PEDIDO_ELIMINAR_FAIL, payload:  JSON.stringify(parametros) });
          return; */
  
          const response = await fetch('https://twinsappservice.azurewebsites.net/api/MercadoPago/EliminarPedido', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
          if(data.Message)
          {
            dispatch({ type: PEDIDO_ELIMINAR_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
          }
          else if(data !== "")
          {
            dispatch({ type: PEDIDO_ELIMINAR_FAIL, payload:  data });
          }
          else
          {
            dispatch({type: PEDIDO_ELIMINAR_SUCCESS }); 
          }  
    } catch (error) {
      dispatch({
        type: PEDIDO_ELIMINAR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };



  export const ReportePedido = (Id) => async (dispatch) => {
    
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId"),PedidoId: Id};
 
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pedidos/ReporteResumen', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Url)
           {
 
            window.open('https://twinsappservice.azurewebsites.net/ArchivosPedidos/' + data.Url);
           }
   
           
    } catch (error) {
      dispatch({
        type: PEDIDO_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
