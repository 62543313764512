import React, { useState,useEffect }  from 'react'; 
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import {   Link, Tooltip } from '@material-ui/core';
  
import { useDispatch ,useSelector} from 'react-redux'; 
import { eliminarPedido, getlistaPedidos } from '../../actions/pedidosActions';
import { PEDIDO_ELIMINAR_RESET  } from '../../constants/pedidoConstants';
import Mensaje from 'sweetalert';
import LoadingBox from '../LoadingBox';
const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    fontSize:'20px'
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 50,
  },
  formControlLabel: {
    marginTop: theme.spacing(0),
    marginLeft: '25px',
    label:{
    fontSize:'20px'},
  },
}));

export default function PedidoEliminar(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');
  const [Descripcion, setDescripcion] = useState("");
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); }

  const Guardar = () => { 
    dispatch(eliminarPedido(props.Id,Descripcion,props.MercadoPagoId));
    };

    const pedidoEliminar = useSelector((state) => state.pedidoEliminar);
    const {loading:loadingSave, success, error:errorSave} = pedidoEliminar;

    const pedidos =  useSelector(state => state.pedidos)
    const { pedidosLista} = pedidos;

    useEffect(() => {
      if (success) { 
          dispatch({ type: PEDIDO_ELIMINAR_RESET });
          dispatch(getlistaPedidos());
          setOpen(false);
      }
      if(errorSave)
      {
        Mensaje({ title: "Error",  text: errorSave, icon: "error", button: "cerrar" });
      }
    }, [dispatch, success,errorSave,props.Id,pedidosLista]);

  useEffect(() => { 
    setDescripcion("Su Pedido " +  props.Id   + " Ha Sido Rechazado")
    }, [dispatch,props.Id])

 
  return (
    <React.Fragment>
 
      
      {(props.Id > 0 && props.bEliminado === false) ? 
      <button className="primary" type="submit" style={{backgroundColor:"#DC4D41",color:"white",margin:'0px',padding:'3px', marginLeft:"5px"}} onClick={handleClickOpen}>Eliminar</button>   :<></>}
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title" >
        <DialogContent style={{backgroundColor:"#DC4D41"}} >
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
            <div className="row center">
                <h3>Eliminando Pedido {String(props.Id)}</h3>
            </div>
            
            <h5>Mensaje al Cliente: deje en blanco si no quiere enviar un mensaje al cliente</h5>

            <h5 htmlFor="Descripcion" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Descripcion </h5>  
             

                    <textarea placeholder="Ingrese Descripcion" 
                        id="comment" style={{minHeight:'150px'}}
                        value={Descripcion}
                        onChange={(e) => setDescripcion( e.target.value)}
                      ></textarea>

            </FormControl>
          </form>
        </DialogContent>
        <DialogActions style={{backgroundColor:"#E1CD74"}} >
        {loadingSave? <LoadingBox Mensaje={'Eliminando Pedido'}></LoadingBox>:<></>}
        <Tooltip title={<h3 style={{ color: "#FE6DFE",margin:'0px',padding:'0px' }}>Aprobar el Pedido</h3>}>
                                <Link  style={   { fontSize: "4rem", color:'white'  ,cursor: "Hand",margin:'0px',padding:'0px' }}>
                                <div style={{  padding:"0" }}> 
                                    <i  class="fa fa-trash" aria-hidden="true" 
                                            style={   { fontSize: "4rem", color:'black',cursor:"pointer"  ,margin:'0px',padding:'0px' }   }    
                                            onClick={() => Guardar()}  /> 
                                    </div>
                                </Link>
                                </Tooltip> 
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}