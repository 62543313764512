import { LOCALIDADESAPP_LISTA_FAIL, LOCALIDADESAPP_LISTA_REQUEST, LOCALIDADESAPP_LISTA_SUCCESS, LOCALIDADESAPP_UPDATE_FAIL, LOCALIDADESAPP_UPDATE_REQUEST, LOCALIDADESAPP_UPDATE_SUCCESS } from "../constants/LocalidadesAppConstants";

 

export const getlistalocalidadesapp = () => async (dispatch) => {
    dispatch({ type: LOCALIDADESAPP_LISTA_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/LocalidadesDisponiblesApp/Lista', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: LOCALIDADESAPP_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: LOCALIDADESAPP_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: LOCALIDADESAPP_LISTA_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: LOCALIDADESAPP_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const localidadesappUpdate = (sId,Activo,CostoEnvio) => async (dispatch) => {
    dispatch({ type: LOCALIDADESAPP_UPDATE_REQUEST});
  
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") ,sId: sId,Activo:Activo,CostoEnvio:CostoEnvio};
     /*   dispatch({ type: LOCALIDADESAPP_UPDATE_FAIL, payload:  JSON.stringify(parametros) });
return; */ 


      const response = await fetch('https://twinsappservice.azurewebsites.net/api/LocalidadesDisponiblesApp/Insert', {
             method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: LOCALIDADESAPP_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: LOCALIDADESAPP_UPDATE_FAIL, payload:  data });
           }
           else
           {
           dispatch({type: LOCALIDADESAPP_UPDATE_SUCCESS }); 
           }    
           
    } catch (error) {
      dispatch({
        type: LOCALIDADESAPP_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };