import { FAMILIASPROMOCIONES_DELETE_FAIL, FAMILIASPROMOCIONES_DELETE_REQUEST, FAMILIASPROMOCIONES_DELETE_SUCCESS, FAMILIASPROMOCIONES_LISTA_FAIL, FAMILIASPROMOCIONES_LISTA_REQUEST, FAMILIASPROMOCIONES_LISTA_SUCCESS, FAMILIASPROMOCIONES_UPDATEGRILLA_SUCCESS, FAMILIASPROMOCIONES_UPDATE_FAIL, FAMILIASPROMOCIONES_UPDATE_REQUEST, FAMILIASPROMOCIONES_UPDATE_SUCCESS } from "../constants/FamiliasPromocionesConstants";

 
export const getlistaFamiliasPromociones = () => async (dispatch) => {
    dispatch({ type: FAMILIASPROMOCIONES_LISTA_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/FamiliasPromociones/Lista', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: FAMILIASPROMOCIONES_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: FAMILIASPROMOCIONES_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: FAMILIASPROMOCIONES_LISTA_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: FAMILIASPROMOCIONES_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const FamiliasPromocionesUpdate = (Id,FamiliaId,nCantidad,nPrecio,DesdeABM) => async (dispatch) => {
    dispatch({ type: FAMILIASPROMOCIONES_UPDATE_REQUEST});
  
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") ,Id: Id,FamiliaId:FamiliaId,nCantidad:nCantidad,nPrecio:nPrecio};
     /*   dispatch({ type: LOCALIDADESAPP_UPDATE_FAIL, payload:  JSON.stringify(parametros) });
return; */ 


      const response = await fetch('https://twinsappservice.azurewebsites.net/api/FamiliasPromociones/Insert', {
             method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: FAMILIASPROMOCIONES_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: FAMILIASPROMOCIONES_UPDATE_FAIL, payload:  data });
           }
           else
           {
             if(DesdeABM && DesdeABM === true)
             {
                dispatch({type: FAMILIASPROMOCIONES_UPDATE_SUCCESS }); }
                else{
                  dispatch({type: FAMILIASPROMOCIONES_UPDATEGRILLA_SUCCESS }); } 
              
           }    
           
    } catch (error) {
      dispatch({
        type: FAMILIASPROMOCIONES_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

 

  export const FamiliasPromocionesDelete = (Id) => async (dispatch) => {
    dispatch({ type: FAMILIASPROMOCIONES_DELETE_REQUEST});
  
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") ,Id: Id};
      /*   dispatch({ type: FAMILIASPROMOCIONES_DELETE_FAIL, payload:  JSON.stringify(parametros) });
return;  */

      const response = await fetch('https://twinsappservice.azurewebsites.net/api/FamiliasPromociones/Eliminar', {
             method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: FAMILIASPROMOCIONES_DELETE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {dispatch({type: FAMILIASPROMOCIONES_DELETE_SUCCESS });  
            dispatch({ type: FAMILIASPROMOCIONES_DELETE_FAIL, payload:  data });
           }
           else
           {
              
                dispatch({type: FAMILIASPROMOCIONES_DELETE_SUCCESS });  
             
           }    
           
    } catch (error) {
      dispatch({
        type: FAMILIASPROMOCIONES_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };