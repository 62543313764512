import React, {useState, useEffect,useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux'; 
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import { Column } from 'primereact/column'; 
import { InputNumber } from 'primereact/inputnumber'; 
import { getlistaFamiliasPromociones ,FamiliasPromocionesUpdate, FamiliasPromocionesDelete} from '../actions/familiaspromocionesActions';
import { FAMILIASPROMOCIONES_LISTA_SUCCESS } from '../constants/FamiliasPromocionesConstants';
import FamiliaPromocionNueva from '../components/FamiliaPromocionNueva';
import swal from 'sweetalert';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import { Toolbar } from 'primereact/toolbar';

export default function FamiliasPromocionesScreen(props) {
    const familiaspromociones =  useSelector(state => state.familiaspromociones)
    const {loading, familiaspromocionesLista} = familiaspromociones;
    const familiaspromocionesDelete = useSelector((state) => state.familiaspromocionesDelete);
    const { success: successEliminando } = familiaspromocionesDelete; 
    const [loadingdata, setLoadingdata] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const dt = useRef(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({type: PANTALLASELECCIONADA, payload:  'Promociones Por Familia'}); 
        dispatch({type: PANTALLAATRAS, payload:  ''}); 
      dispatch(getlistaFamiliasPromociones());
    }, [dispatch]);

    useEffect(() =>{
        setLoadingdata(loading);
    },[dispatch,loading]
    )

    const IdBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="p-column-title">Id</span>
            {rowData.Id}
        </React.Fragment>
    );
    }

    const DescripcionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="p-column-title">Descripcion</span>
            {rowData.sDescripcion}
        </React.Fragment>
    );
    }

    const CantidadBodyTemplate = (rowData) => {
        return   <React.Fragment>
                <span className="p-column-title">Cantidad: </span>
                {new  Intl.NumberFormat('en-US').format(rowData.nCantidad) }
                </React.Fragment>
    }

    const CantidadEditor = ( props) => {
        return     <InputNumber value={props.rowData['nCantidad']} 
            style={{size: 'Large'}} showButtons buttonLayout="horizontal"
            onValueChange={(e) => onEditorValueChange( props, e.value)} 
            mode="decimal"   minFractionDigits={3} decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
            locale="en-US" className="p-inputtext-lg p-d-block"  />
    }
 
    const onEditorValueChange = (props, value) => {
        let o = familiaspromocionesLista.find(r => 
            r.Id === props.rowData['Id'] );
        o.nCantidad = value;
        dispatch({type: FAMILIASPROMOCIONES_LISTA_SUCCESS, payload:  familiaspromocionesLista}); 
        dispatch(FamiliasPromocionesUpdate(o.Id,o.Familia_Id,o.nCantidad,o.nPrecio,false)); 
    }

    const PrecioBodyTemplate = (rowData) => {
        return   <React.Fragment>
                <span className="p-column-title">Cantidad: </span>
                {new  Intl.NumberFormat('en-US').format(rowData.nPrecio) }
                </React.Fragment>
    }

    const PrecioEditor = ( props) => {
        return     <InputNumber value={props.rowData['nPrecio']} 
            style={{size: 'Large'}} showButtons buttonLayout="horizontal"
            onValueChange={(e) => onPrecioValueChange( props, e.value)} 
            mode="decimal"   minFractionDigits={3} decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
            locale="en-US" className="p-inputtext-lg p-d-block"  />
    }
 
    const onPrecioValueChange = (props, value) => {
        let o = familiaspromocionesLista.find(r => 
            r.Id === props.rowData['Id'] );
        o.nPrecio = value;
        dispatch({type: FAMILIASPROMOCIONES_LISTA_SUCCESS, payload:  familiaspromocionesLista}); 
        dispatch(FamiliasPromocionesUpdate(o.Id,o.Familia_Id,o.nCantidad,o.nPrecio,false)); 
    }

    const EliminarBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <i class="fa fa-trash" aria-hidden="true" style={{fontSize:'32px',color:'black',cursor:'pointer',zIndex:"100", marginRight:"20px"}} onClick={() => Eliminar(rowData['Id'])}></i>  
        </React.Fragment>);    
        }


const Eliminar = (Id) =>{
    swal({
        title: "Eliminando Promocion",
        text: "Esta seguro de Eliminar la Promocion con Id " + String(Id),
        icon: "warning",
        buttons: ["Cancelar", "Aceptar"],
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
            dispatch(FamiliasPromocionesDelete(Id)); 
        }  
      });
}

useEffect(() => {
    dispatch(getlistaFamiliasPromociones());
  }, [dispatch,successEliminando]);

  const rightContents = (
    <React.Fragment>
          <span   className="p-input-icon-left">
                <i  className="pi pi-search" />
                <InputText     style={   { fontSize: "1.5rem", color:'black'    }   }   
                type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busqueda Global" />
            </span>  
    </React.Fragment>
);

const customFunction = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0
}
    return (<div style={{marginBottom:'50px'}}>
                <div style={{  padding:"0px" ,position: 'fixed',zIndex: 1, width: '100%',marginTop:'20px'}}>
                    <Toolbar right={rightContents}  style={{ backgroundColor:"#179F5D", margin:0,padding:0, fontSize:'16px', fontWeight:"bold", width:"auto"}}>
                    </Toolbar>   
                </div>
                <div style={{ position: 'fixed',zIndex: 1, bottom: 0,  right: 0,  justifyContent: 'center', alignItems: 'center', marginBottom:"30px"}} >
                <FamiliaPromocionNueva />
                </div>
                        <div className="datatable-doc-demo" style={{paddingTop:'55px'}}>
                        <DataTable ref={dt} value={familiaspromocionesLista} className="p-datatable-customers" dataKey="Id"
                        rowHover globalFilter={globalFilter}   selectionMode="single"  loading={loadingdata}
                        paginator rows={10} emptyMessage="No se encontraron Pedidos" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
>
        <Column  body={EliminarBodyTemplate} headerStyle={{width:"8rem", textAlign: 'center' }}/>
        <Column field="Id" header="Id"  body={IdBodyTemplate} headerStyle={{   textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="sDescripcion" header="Descripcion" filterMatchMode="custom" filterFunction={customFunction} body={DescripcionBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
        <Column field="nCantidad" header="Cantidad" body={CantidadBodyTemplate} editor={(props) => CantidadEditor( props)}></Column>
        <Column field="nPrecio" header="Precio" body={PrecioBodyTemplate} editor={(props) => PrecioEditor( props)}></Column>
</DataTable>       
</div>
</div>
);

}