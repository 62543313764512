import React, {useState, useEffect,useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import MessageBox from '../components/MessageBox';
import { InputText } from 'primereact/inputtext';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import 'primereact/resources/themes/saga-green/theme.css';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import {   MERCADERIAOPCIONESSTOCK_LISTA_SUCCESS } from "../constants/MercaderiasOpcionesStockConstants";
import { getlistaMercaderiasOpcionesStock, updateMercaderiasOpcionesStock } from '../actions/MercaderiasOpcionesStockActions';
import { getcomboMercaderias } from '../actions/comboActions';
import { AutoComplete } from 'primereact/autocomplete';
import { Toolbar } from 'primereact/toolbar';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';

export default function MercaderiasOpcionesStockScreen(props) {

    const MercaderiasOpcionesStock =  useSelector(state => state.MercaderiasOpcionesStock)
    const {loading, error, MercaderiasOpcionesStockLista} = MercaderiasOpcionesStock;
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Mercaderia, setMercaderia] = useState(null);
    const [loadingdata, setLoadingdata] = useState(false);
    const [filteredMercaderias, setFilteredMercaderias] = useState(null); 
    const searchMercaderia = (event) => {
        setTimeout(() => {
            let filteredMercaderias;
            if (!event.query.trim().length) {
                filteredMercaderias = [...MercaderiasCombo];
            }
            else {
                filteredMercaderias = MercaderiasCombo.filter((country) => {
                    return country.sDescripcion.toLowerCase().includes(event.query.toLowerCase());
                });
            } 
            setFilteredMercaderias(filteredMercaderias);
        }, 250);
    }

    const itemTemplate = (item) => {
        return (
            <div className="country-item">
                <img style={{height:'30px',width:'30px'}} alt={item.sDescripcion} src={item.sExtra} 
                onError={(e) => e.target.src='https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
                className={`flag flag-${item.sCodigo.toLowerCase()}`} />
                <div>{item.sDescripcion}</div>
            </div>
        );
    }

    const dt = useRef(null);
    const dispatch = useDispatch();

    useEffect(() =>{
        setLoadingdata(loading);
    },[dispatch,loading]
    )

    useEffect(() => {
        if (Mercaderia && Mercaderia.Id > 0)
            dispatch(getlistaMercaderiasOpcionesStock(Mercaderia.Id));
          }, [dispatch,Mercaderia]);
      

    const comboMercaderias =  useSelector(state => state.comboMercaderias);
    const {  MercaderiasCombo} = comboMercaderias;
    useEffect(() => { 
        dispatch({type: PANTALLASELECCIONADA, payload:  'Caracteristicas Stock/Precio'}); 
        dispatch({type: PANTALLAATRAS, payload:  ''}); 
         dispatch(getcomboMercaderias())
         dispatch(getlistaMercaderiasOpcionesStock(0))
    }, [dispatch])
 
    const Carateristica1BodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Carateristica1</span>
                {rowData.MercaderiaCaracteristicaOpcion1_Desc}
            </React.Fragment>
        );
    }

    const Carateristica2BodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Carateristica2</span>
                {rowData.MercaderiaCaracteristicaOpcion2_Desc}
            </React.Fragment>
        );
    }
      
    const imagenBodyTemplate = (rowData, column) => {
              return (
                <React.Fragment>
                <span className="p-column-title"></span>
                            <img className="small" src={rowData.MercaderiaCaracteristicaOpcion1_Link} alt=''  />                
            </React.Fragment>);
    }

    const imagen2BodyTemplate = (rowData, column) => {
                return (
                  <React.Fragment>
                  <span className="p-column-title"></span>
                              <img className="small" src={rowData.MercaderiaCaracteristicaOpcion2_Link} alt=''  />                
              </React.Fragment>);
    }
 
      const onEditorValueChange = (props, value) => {
 
        let o = MercaderiasOpcionesStockLista.find(r => 
            r.MercaderiaCaracteristicaOpcion1_Id === parseInt(props.rowData['MercaderiaCaracteristicaOpcion1_Id'])
            && r.MercaderiaCaracteristicaOpcion2_Id === parseInt(props.rowData['MercaderiaCaracteristicaOpcion2_Id'])
            );
        o.nStock = value;
        dispatch({type: MERCADERIAOPCIONESSTOCK_LISTA_SUCCESS, payload:  MercaderiasOpcionesStockLista}); 
        dispatch(updateMercaderiasOpcionesStock(o));
    }

    const positiveIntegerValidator = (rowData) => {
       return     <React.Fragment>
       <span className="p-column-title">Cantidad: </span>
      {new  Intl.NumberFormat('en-US' ).format(rowData.nStock) }
            </React.Fragment>
    }

    const stockEditor = ( props) => {
        return <InputNumber value={props.rowData['nStock']} 
                style={{size: 'Large'}} showButtons buttonLayout="horizontal"
                onValueChange={(e) => onEditorValueChange( props, e.value)} 
                mode="decimal"   minFractionDigits={3} decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                locale="en-US" className="p-inputtext-lg p-d-block"  />
    }


    const precioValidator = (rowData) => {
        return     <React.Fragment>
        <span className="p-column-title">Precio: </span>
       {new  Intl.NumberFormat('en-US' ).format(rowData.nPrecio) }
             </React.Fragment>
     }
 
     const precioEditor = ( props) => {
         return <InputNumber value={props.rowData['nPrecio']} 
                 style={{size: 'Large'}} showButtons buttonLayout="horizontal"
                 onValueChange={(e) => onPrecioValueChange( props, e.value)} 
                 mode="decimal"   minFractionDigits={3} decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                 locale="es-US" className="p-inputtext-lg p-d-block"  />
     }

     const onPrecioValueChange = (props, value) => {
        let o = MercaderiasOpcionesStockLista.find(r => 
            r.MercaderiaCaracteristicaOpcion1_Id === parseInt(props.rowData['MercaderiaCaracteristicaOpcion1_Id'])
            && r.MercaderiaCaracteristicaOpcion2_Id === parseInt(props.rowData['MercaderiaCaracteristicaOpcion2_Id'])
            );
        o.nPrecio = value;
        dispatch({type: MERCADERIAOPCIONESSTOCK_LISTA_SUCCESS, payload:  MercaderiasOpcionesStockLista}); 
        dispatch(updateMercaderiasOpcionesStock(o));
    }

    const rightContents = (
        <React.Fragment>
              <span   className="p-input-icon-left">
                    <i  className="pi pi-search" />
                    <InputText     style={   { fontSize: "1.5rem", color:'black'  ,width:"83px"  }   }   
                    type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                </span>  
        </React.Fragment>
    );

const leftContents =(
    <React.Fragment>
     <label style={{color:"white"}}  >Merc  </label> 
                <AutoComplete value={Mercaderia} suggestions={filteredMercaderias} completeMethod={searchMercaderia} 
                 field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                 onChange={(e) => setMercaderia(e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}    style={{margin:"10px"}}/>
                
                    </React.Fragment>
)


    const customFunction = (value, filter) => {
        return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0
}
    return (
                error? <MessageBox variant="danger" >{error}</MessageBox>
                :
                <div style={{marginBottom:'50px'}}>
                <div style={{  padding:"0px" ,position: 'fixed',zIndex: 1, width: '100%',marginTop:'20px'}}>
                    <Toolbar right={rightContents} left={leftContents}  style={{ backgroundColor:"#179F5D", margin:0,padding:0, fontSize:'16px', fontWeight:"bold", width:"auto"}}>
                    </Toolbar>   
                </div>
                <div className="datatable-doc-demo" style={{paddingTop:"82px"}}>
                <DataTable ref={dt} value={MercaderiasOpcionesStockLista}  className="p-datatable-customers" dataKey="Id"
                    rowHover globalFilter={globalFilter}   selectionMode="single"  editMode="cell" loading={loadingdata} 
                    paginator rows={10} emptyMessage="No se encontraron Caracteristicas" 
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
                    rowsPerPageOptions={[10,25,50]}
                    >
                <Column  field="MercaderiaCaracteristicaOpcion1_Link"   body={imagenBodyTemplate} headerStyle={{width:"8rem", textAlign: 'center' }}  />             
                <Column field="MercaderiaCaracteristicaOpcion1_Desc" filterMatchMode="custom" filterFunction={customFunction}  header="Carateristica1"  body={Carateristica1BodyTemplate} headerStyle={{  textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
                <Column  field="MercaderiaCaracteristicaOpcion2_Link"   body={imagen2BodyTemplate} headerStyle={{width:"8rem", textAlign: 'end' }}  />
                <Column field="MercaderiaCaracteristicaOpcion2_Desc" filterMatchMode="custom" filterFunction={customFunction}  header="Carateristica2"  body={Carateristica2BodyTemplate} headerStyle={{ textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
                <Column field="nStock" header="Stock"  sortable filter filterMatchMode="lte" filterPlaceholder="Desde"    body={positiveIntegerValidator} editor={(props) => stockEditor(props)}></Column>        
                <Column field="nPrecio" header="Precio"  sortable filter filterMatchMode="lte" filterPlaceholder="Desde"    body={precioValidator} editor={(props) => precioEditor(props)}></Column>                          
</DataTable>       
</div>
</div>
);

}