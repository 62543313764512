export const MERCADERIA_LISTA_REQUEST = 'MERCADERIA_LISTA_REQUEST';
export const MERCADERIA_LISTA_SUCCESS = 'MERCADERIA_LISTA_SUCCESS';
export const MERCADERIA_LISTA_FAIL = 'MERCADERIA_LISTA_FAIL'; 

export const MERCADERIA_UPDATE_REQUEST = 'MERCADERIA_UPDATE_REQUEST';
export const MERCADERIA_UPDATE_SUCCESS = 'MERCADERIA_UPDATE_SUCCESS';
export const MERCADERIA_UPDATE_FAIL = 'MERCADERIA_UPDATE_FAIL'; 
export const MERCADERIA_UPDATE_RESET = 'MERCADERIA_UPDATE_RESET'; 

export const MERCADERIA_GET_FAIL= 'MERCADERIA_GET_FAIL'; 
export const MERCADERIA_GET_REQUEST= 'MERCADERIA_GET_REQUEST'; 
export const MERCADERIA_GET_SUCCESS= 'MERCADERIA_GET_SUCCESS';  

export const MERCADERIAS_ADDARCHIVO_REQUEST= 'MERCADERIAS_ADDARCHIVO_REQUEST'; 
export const MERCADERIAS_ADDARCHIVO_SUCCESS= 'MERCADERIAS_ADDARCHIVO_SUCCESS'; 