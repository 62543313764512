import {  
    MERCADERIA_LISTA_FAIL,
    MERCADERIA_LISTA_REQUEST,
    MERCADERIA_LISTA_SUCCESS, 
    MERCADERIA_UPDATE_FAIL, 
    MERCADERIA_UPDATE_REQUEST,
    MERCADERIA_UPDATE_SUCCESS,
    MERCADERIA_UPDATE_RESET,
    MERCADERIA_GET_REQUEST,
    MERCADERIA_GET_SUCCESS,
    MERCADERIA_GET_FAIL,
    MERCADERIAS_ADDARCHIVO_REQUEST,
    MERCADERIAS_ADDARCHIVO_SUCCESS
  } from '../constants/mercaderiaConstants';
  
    export const mercaderiasReducer = ( state = { loading:true,  mercaderiasLista: [] },  action) => {
    switch (action.type) {
      case MERCADERIA_LISTA_REQUEST:
        return { loading: true };
      case MERCADERIA_LISTA_SUCCESS:
        return { loading: false, mercaderiasLista: action.payload };
      case MERCADERIA_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const mercaderiaUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case MERCADERIA_UPDATE_REQUEST:
        return { loading: true };
      case MERCADERIA_UPDATE_SUCCESS:
        return { loading: false, success: true  };
      case MERCADERIA_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case MERCADERIA_UPDATE_RESET:
          return {};
      default:
        return state;
    }
  };

  export const mercaderiaGetReducer = ( state = { loading:true,  mercaderiaItem: {} },  action) => {
    switch (action.type) {
      case MERCADERIA_GET_REQUEST:
        return { loading: true };
      case MERCADERIA_GET_SUCCESS:
        return { loading: false, mercaderiaItem: action.payload };
      case MERCADERIA_GET_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const mercaderiaSubiendoFotosReducer = ( state = { loading:false,  archivo: "" },  action) => {
    switch (action.type) {
      case MERCADERIAS_ADDARCHIVO_REQUEST:
        return { loading: true };
      case MERCADERIAS_ADDARCHIVO_SUCCESS:
        return { loading: false , archivo: action.payload };
 
      default:
        return state;
    }
  };
  