 
import React, { useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getlista } from '../actions/funcionalidadActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Opcion from '../components/Opcion';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';

export default function MenuPantalla ( ){
  const operadorSignin = useSelector((state) => state.operadorSignin);
  const {operadorInfo} = operadorSignin;
  const funcionalidadesGet = useSelector((state) => state.funcionalidadesGet);
  const {funcionalidadesLista, loading, error} = funcionalidadesGet;

    const dispatch = useDispatch();
    useEffect(() => { 
      dispatch({type: PANTALLASELECCIONADA, payload:  'Menu'}); 
      dispatch({type: PANTALLAATRAS, payload:  ''}); 
        dispatch(getlista(operadorInfo.Empresa_Id))
        },[dispatch,operadorInfo.Empresa_Id])

    return (
      <div style={{marginTop:"40px", marginBottom:"40px"}}  >
        {loading? <LoadingBox></LoadingBox>
        :
        error?<MessageBox variant="danger">{error}</MessageBox>
        :
        <>
         { funcionalidadesLista &&
                <div className="row center" style={{ marginTop:"10px" }}>
                {
                  funcionalidadesLista.map(pantalla => (
                    <Opcion key={pantalla.Id} opcion={pantalla}/>
                    ))
                }   
              </div>
          } 
        </>
          
        }
      </div>
      
    )
}
