import React, {useState, useEffect,useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import MessageBox from '../components/MessageBox';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import { Column } from 'primereact/column';
import { Tooltip } from '@material-ui/core';
import { getlistaMercaderias } from '../actions/mercaderiaActions';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import { Toolbar } from 'primereact/toolbar';
import useWindowDimensions from '../components/windowsdimentions';
import { isMobile } from 'react-device-detect';
import { SelectButton } from 'primereact/selectbutton';

export default function MercaderiasAppPantalla(props) {

    const mercaderias =  useSelector(state => state.mercaderias)
    const {loading, error, mercaderiasLista} = mercaderias;
    const [loadingdata, setLoadingdata] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    
    const dt = useRef(null);
    const dispatch = useDispatch();
    useEffect(() => {
        let paneles = [];  
        //localStorage.removeItem('mercaderiasDisponibles');
        var Disponibles = false;
        var Eliminados = false;
        if (!localStorage.getItem('mercaderiasDisponibles')  )
        {
            Disponibles = true;
            paneles.push(1);
            localStorage.setItem('mercaderiasDisponibles',paneles)
        }
        else
        {
            
           if(localStorage.getItem('mercaderiasDisponibles')==="SI")
           {
                paneles.push(1);
                Disponibles = true;
           }
           if(localStorage.getItem('mercaderiasEliminados')==="SI")
           {
                paneles.push(2);
                Eliminados = true;
           }
        }

        setPaneles(paneles);
        
        dispatch({type: PANTALLASELECCIONADA, payload:  'Mercaderias App'}); 
        dispatch({type: PANTALLAATRAS, payload:  ''}); 

 
      dispatch(getlistaMercaderias(Disponibles,Eliminados));
    }, [dispatch]);

    useEffect(() =>{
        setLoadingdata(loading);
    },[dispatch,loading]
    )
 
      const IdBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Id</span>
                {rowData.Id}
            </React.Fragment>
        );
      }

      const CodigoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Codigo</span>
                {rowData.sCodigo}
            </React.Fragment>
        );
      }
      
      const DescripcionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Descripcion</span>
                {rowData.sDescripcion}
            </React.Fragment>
        );
      }

      const activoBodyTemplate = (rowData, column) => {
        if(!rowData.bActivo )
        {
          return (
            <React.Fragment>
            <span className="p-column-title">Activo</span>
              
                        <i class="fa fa-circle-o" aria-hidden="true" 
                            style={   { fontSize: "2rem", color:'black'  ,cursor: "hand" , verticalAlign: "bottom"  }   }    
                          />                
        </React.Fragment>);
       
        }
        else
        {
            return (
                <React.Fragment>
                <span className="p-column-title">Activo</span>
                   
                            <i class="fa fa-check-circle-o" aria-hidden="true" 
                                style={   { fontSize: "2rem", color:'black'  ,cursor: "hand" , verticalAlign: "bottom"  }   }    
                                 />
                         
                  
            </React.Fragment>);
        }

       
        }
  
        const Nueva = () => {
            props.history.push(`/mercaderiadetalle/0/''`);
        }
      
        const imagenBodyTemplate = (rowData, column) => {
            
              return (
                <React.Fragment>
                <span className="p-column-title"></span>
                  
                   
                            <img class="smallGrilla" src={rowData.LinkFoto} alt=''  />                
            </React.Fragment>);
           
      
            }

            const customFunction = (value, filter) => {
                return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0
        }
        const rightContents = (
            <React.Fragment>
    
                  <span   className="p-input-icon-left">
                        <i  className="pi pi-search" />
                        <InputText     style={   { fontSize: "1.5rem", color:'black'    }   }   
                        type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busqueda Global" />
                    </span>  
            </React.Fragment>
        );


        const [Paneles, setPaneles] = useState( );

        const panelesOpciones = [
            {name: 'Activas', value: 1},
            {name: 'No Activas', value: 2}
        ];
        
 

        const seleccionPanel = (e) => {
            localStorage.removeItem('mercaderiasDisponibles');
            localStorage.removeItem('mercaderiasEliminados')
            var Disponibles = false;
            var Eliminados = false;
            for (let i of e) {
                if(i === 1)
                {   localStorage.setItem('mercaderiasDisponibles','SI');
                    Disponibles = true;
                }
                if(i === 2)
                {localStorage.setItem('mercaderiasEliminados','SI');
                    Eliminados = true;
                }
              }
            setPaneles(e);
       
            dispatch(getlistaMercaderias( Disponibles,Eliminados ));
        }

        const leftContents = (
            <React.Fragment>
    <SelectButton style={{marginLeft:"25px" }} value={Paneles} options={panelesOpciones} onChange={(e) => seleccionPanel(e.value)} optionLabel="name" multiple />
            </React.Fragment>
        );




        const { height } = useWindowDimensions();
    return (
 
                error? <MessageBox variant="danger" >{error}</MessageBox>
                : 
                <div style={{marginBottom:'10px'}}>
                    <div style={{ position: 'fixed',zIndex: 1, bottom: 0,  right: 0,  justifyContent: 'center', alignItems: 'center', marginBottom:"30px"}} >
                        <Tooltip title={<h4 style={{ color: "#FF70FF" }}>Nueva Mercaderia</h4>}>
                            <i className="fa fa-plus-circle" aria-hidden="true" style={{fontSize:'72px',color:'black',zIndex:"100",cursor:'pointer', marginRight:"20px"}} onClick={() => Nueva()}></i> 
                        </Tooltip>
                    </div>

                    <div style={{  padding:"0px" ,position: 'fixed',zIndex: 1, width: '100%',marginTop:'20px'}}>
                        <Toolbar right={rightContents} left={leftContents}  style={{ backgroundColor:"#007ACC", margin:0,padding:0, fontSize:'16px', fontWeight:"bold", width:"auto"}}>
                        </Toolbar>   
                    </div>

                    <div className="datatable-doc-demo" style={{paddingTop:'55px'}}>
                        <DataTable ref={dt} value={mercaderiasLista}  className="p-datatable-customers" dataKey="Id"
                        rowHover globalFilter={globalFilter}  scrollable={isMobile ? false: true} scrollHeight={String(height - 240) + 'px'} loading={loadingdata}  onSelectionChange={(e) => {  props.history.push(`/mercaderiadetalle/${e.value.Id}/${e.value.sCodigo}`);  }}   selectionMode="single" 
                        paginator rows={10} emptyMessage="No se encontraron Mercaderias" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        paginatorTemplate=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport" rowsPerPageOptions={[10,25,50]}
                        >
                        <Column  field="bActivo" header="Act."   body={activoBodyTemplate} headerStyle={{width:"4rem", textAlign: 'center' }}  />
                        <Column  field="LinkFoto"   body={imagenBodyTemplate} headerStyle={{width:"15rem", textAlign: 'center' }}  />
                        <Column field="Id" header="Id" filterMatchMode="custom" filterFunction={customFunction} body={IdBodyTemplate} headerStyle={{ width:"8rem", textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
                        <Column field="sCodigo" header="Codigo" filterMatchMode="custom" filterFunction={customFunction} body={CodigoBodyTemplate} headerStyle={{ width:"10rem", textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
                        <Column field="sDescripcion" header="Descripcion" filterMatchMode="custom" filterFunction={customFunction} body={DescripcionBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
                        </DataTable>       
                    </div>
                </div>
);

}