import {  DESCUENTOS_LISTA_FAIL, DESCUENTOS_LISTA_REQUEST,
   DESCUENTOS_LISTA_SUCCESS, DESCUENTOS_UPDATEGRILLA_SUCCESS, DESCUENTOS_UPDATE_FAIL, DESCUENTOS_UPDATE_REQUEST, DESCUENTOS_UPDATE_SUCCESS } 
   from "../constants/DescuentosConstants";

 
 
export const getlistaDescuentos = () => async (dispatch) => {
    dispatch({ type: DESCUENTOS_LISTA_REQUEST});
    try {

     
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Descuentos/Lista', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: DESCUENTOS_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: DESCUENTOS_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: DESCUENTOS_LISTA_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: DESCUENTOS_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const DescuentoUpdate = (Item,DesdeABM) => async (dispatch,getState) => {
    dispatch({ type: DESCUENTOS_UPDATE_REQUEST});
    const { operadorSignin: { operadorInfo }, } = getState(); 
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId"),Item:Item,OperadorId:operadorInfo.Id };

     
    /*  dispatch({ type: DESCUENTOS_UPDATE_FAIL, payload:  JSON.stringify(parametros) });
return;   */ 
  
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Descuentos/Insert', {
             method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: DESCUENTOS_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: DESCUENTOS_UPDATE_FAIL, payload:  data });
           }
           else
           {
             if(DesdeABM && DesdeABM === true)
             {
                dispatch({type: DESCUENTOS_UPDATE_SUCCESS }); }
                else{
                  dispatch({type: DESCUENTOS_UPDATEGRILLA_SUCCESS }); } 
              
           }    
           
    } catch (error) {
      dispatch({
        type: DESCUENTOS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };