export const DESCUENTOS_LISTA_REQUEST = 'DESCUENTOS_LISTA_REQUEST';
export const DESCUENTOS_LISTA_SUCCESS = 'DESCUENTOS_LISTA_SUCCESS';
export const DESCUENTOS_LISTA_FAIL = 'DESCUENTOS_LISTA_FAIL'; 

export const DESCUENTOS_UPDATE_REQUEST = 'DESCUENTOS_UPDATE_REQUEST';
export const DESCUENTOS_UPDATE_SUCCESS = 'DESCUENTOS_UPDATE_SUCCESS';
export const DESCUENTOS_UPDATE_FAIL = 'DESCUENTOS_UPDATE_FAIL'; 
export const DESCUENTOS_UPDATE_RESET = 'DESCUENTOS_UPDATE_RESET'; 
export const DESCUENTOS_UPDATEGRILLA_SUCCESS= 'DESCUENTOS_UPDATEGRILLA_SUCCESS'; 

export const DESCUENTOS_DELETE_REQUEST = 'DESCUENTOS_DELETE_REQUEST';
export const DESCUENTOS_DELETE_SUCCESS = 'DESCUENTOS_DELETE_SUCCESS';
export const DESCUENTOS_DELETE_FAIL = 'DESCUENTOS_DELETE_FAIL'; 
export const DESCUENTOS_DELETE_RESET = 'DESCUENTOS_DELETE_RESET'; 