import {  
    MERCADERIA_LISTA_FAIL,
    MERCADERIA_LISTA_REQUEST,
    MERCADERIA_LISTA_SUCCESS, 
    MERCADERIA_UPDATE_FAIL, 
    MERCADERIA_UPDATE_REQUEST, 
    MERCADERIA_GET_FAIL,
    MERCADERIA_GET_REQUEST,
    MERCADERIA_GET_SUCCESS,
    MERCADERIA_UPDATE_SUCCESS, 
  } from '../constants/mercaderiaConstants';
  import imageCompression from 'browser-image-compression';

  export const subirFoto = (file) => async (dispatch, getState) => {
    const { mercaderiaGet: { mercaderiaItem }, } = getState(); 
    dispatch({ type: MERCADERIA_LISTA_REQUEST});
 
    try {
       // const compressedFile = await imageCompression(imageFile, options);
        var reader = new FileReader();
        reader.readAsDataURL(file); 
        reader.onloadend = function() {
        //var base64data = reader.result;  

        var max;

        for (var j=0 ; j < mercaderiaItem.fotos.length ; j++) {
            if (max == null || parseInt(mercaderiaItem.fotos[j].Id) > parseInt(max.Id))
                max = mercaderiaItem.fotos[j];
        }
        console.log(max ? max.Id : 0 +  1 );
        mercaderiaItem.fotos.push({Id:(max ? max.Id : 0) +  1 ,src:reader.result, nombre:file.name,file:file}) ;
       
       dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem}); 
        }
      
      //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob);  
      //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);  
    } catch (error) {
      console.log(error);
    }
  };
 
  export const subirFotoLink = (file) => async (dispatch, getState) => {
    const { mercaderiaGet: { mercaderiaItem }, } = getState();  
    const imageFile = file;
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      var reader = new FileReader();
      reader.readAsDataURL(compressedFile); 
      reader.onloadend = function() { 
      mercaderiaItem.LinkFoto = reader.result;
      mercaderiaItem.NombreFotoNueva = file.name;
      dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem}); 
}
    } catch (error) {
      console.log(error);
    }
  };

  export const getlistaMercaderias = (  Disponibles,Eliminadas) => async (dispatch) => {
    dispatch({ type: MERCADERIA_LISTA_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Mercaderia/MercaderiasGrilla', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: MERCADERIA_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: MERCADERIA_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
            let dtResultados = [];
            if ((Disponibles?Disponibles:false) === true && (Eliminadas?Eliminadas:false) ===true)
            dtResultados=data.Lista;
            else if ((Disponibles?Disponibles:false) === true)
            dtResultados=data.Lista.filter(r => r.bActivo === true);
            else if ((Eliminadas?Eliminadas:false) === true)
            dtResultados=data.Lista.filter(r => r.bActivo === false);
           dispatch({type: MERCADERIA_LISTA_SUCCESS, payload:  dtResultados}); 
           }
           
    } catch (error) {
      dispatch({
        type: MERCADERIA_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateMercaderias = (parametros) => async (dispatch, getState) => {
    dispatch({ type: MERCADERIA_UPDATE_REQUEST});
    try {
      const { operadorSignin: { operadorInfo }, } = getState(); 
      parametros.EmpresaId = localStorage.getItem("empresaId");
      parametros.OperadorId = operadorInfo.Id;
    /*   console.log(parametros);
      dispatch({ type: MERCADERIA_UPDATE_FAIL, payload:  JSON.stringify(parametros) });
   return; */
    const response = await fetch('https://twinsappservice.azurewebsites.net/api/MercaderiasApp/MercaderiasUpdate3', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: MERCADERIA_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: MERCADERIA_UPDATE_FAIL, payload:  data });
           }
           else
           {
           dispatch({type: MERCADERIA_UPDATE_SUCCESS }); 
           }    
           
    } catch (error) {
      dispatch({
        type: MERCADERIA_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const getMercaderia = (MercaderiaId,Codigo) => async (dispatch) => {
    console.log(MercaderiaId)
    dispatch({ type: MERCADERIA_GET_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId"),Id: MercaderiaId ,Codigo:Codigo};
 
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Mercaderia/MercaderiaGet', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: MERCADERIA_GET_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: MERCADERIA_GET_FAIL, payload:  data.Error });
           }
           else
           {
            data.fotos =[];

              if(data.LinkFoto2 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && String(data.LinkFoto2) !== ""){
                data.fotos = [...data.fotos, {Id:2, src:data.LinkFoto2, nombre:''}]
              }
              if(data.LinkFoto3 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto3 !== ""){
                data.fotos = [...data.fotos, {Id:3, src:data.LinkFoto3, nombre:''}]
              }
              if(data.LinkFoto4 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto4 !== ""){
                data.fotos = [...data.fotos, {Id:4, src:data.LinkFoto4, nombre:''}]
              }
              if(data.LinkFoto5 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto5 !== ""){
                data.fotos = [...data.fotos, {Id:5, src:data.LinkFoto5, nombre:''}]
              }
              if(data.LinkFoto6 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto6 !== ""){
                data.fotos = [...data.fotos, {Id:6, src:data.LinkFoto6, nombre:''}]
              }
              if(data.LinkFoto7 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto7 !== ""){
                data.fotos = [...data.fotos, {Id:7, src:data.LinkFoto7, nombre:''}]
              }
              if(data.LinkFoto8 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto8 !== ""){
                data.fotos = [...data.fotos, {Id:8, src:data.LinkFoto8, nombre:''}]
              }
              if(data.LinkFoto9 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto9 !== ""){
                data.fotos = [...data.fotos, {Id:9, src:data.LinkFoto9, nombre:''}]
              }
              if(data.LinkFoto10 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto10 !== ""){
                data.fotos = [...data.fotos, {Id:10, src:data.LinkFoto10, nombre:''}]
              }
              if(data.LinkFoto11 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto11 !== ""){
                data.fotos = [...data.fotos, {Id:11, src:data.LinkFoto11, nombre:''}]
              }
              if(data.LinkFoto12 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto12 !== ""){
                data.fotos = [...data.fotos, {Id:12, src:data.LinkFoto12, nombre:''}]
              }
              if(data.LinkFoto13 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto13 !== ""){
                data.fotos = [...data.fotos, {Id:13, src:data.LinkFoto13, nombre:''}]
              }
              if(data.LinkFoto14 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto14 !== ""){
                data.fotos = [...data.fotos, {Id:14, src:data.LinkFoto14, nombre:''}]
              }
              if(data.LinkFoto15 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto15 !== ""){
                data.fotos = [...data.fotos, {Id:15, src:data.LinkFoto15, nombre:''}]
              }
              if(data.LinkFoto16 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto16 !== ""){
                data.fotos = [...data.fotos, {Id:16, src:data.LinkFoto16, nombre:''}]
              }
              if(data.LinkFoto17 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto17 !== ""){
                data.fotos = [...data.fotos, {Id:17, src:data.LinkFoto17, nombre:''}]
              }
              if(data.LinkFoto18 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto18 !== ""){
                data.fotos = [...data.fotos, {Id:18, src:data.LinkFoto18, nombre:''}]
              }
              if(data.LinkFoto19 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto19 !== ""){
                data.fotos = [...data.fotos, {Id:19, src:data.LinkFoto19, nombre:''}]
              }
              if(data.LinkFoto20 !== "https://twinsarchivos.blob.core.windows.net/imagenesabmmercaderias/Producto.png" && data.LinkFoto20 !== ""){
                data.fotos = [...data.fotos, {Id:20, src:data.LinkFoto20, nombre:''}]
              }

              console.log(data)
           dispatch({type: MERCADERIA_GET_SUCCESS, payload:  data}); 
           }
           
    } catch (error) {
      dispatch({
        type: MERCADERIA_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };








