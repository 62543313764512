import React, {useState, useEffect,useRef  } from 'react'
import { useDispatch,useSelector } from 'react-redux'; 
import MessageBox from '../components/MessageBox';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable'; 
import './GrillaGris.css';
import { Column } from 'primereact/column';
import { Tooltip } from '@material-ui/core';  
import { getlistaGruposMercaderia } from '../actions/gruposmercaderiaActions'; 
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import { Toolbar } from 'primereact/toolbar';

export default function GruposMercaderiaScreen(props) {
    const gruposmercaderia =  useSelector(state => state.gruposmercaderia)
    const {loading, error, gruposmercaderiaLista} = gruposmercaderia;
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loadingdata, setLoadingdata] = useState(false);

    const dt = useRef(null);
     const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: PANTALLASELECCIONADA, payload:  'Grupos de Mercaderia'}); 
        dispatch({type: PANTALLAATRAS, payload:  ''}); 
        dispatch(getlistaGruposMercaderia());
    }, [dispatch]); 

    useEffect(() =>{
        setLoadingdata(loading);
    },[dispatch,loading]
    )

    const Nueva = () => {
        props.history.push(`/gruposmercaderiadetalle/0`);
    }
  
    const IdBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Id</span>
                {rowData.Id}
            </React.Fragment>
        );
      }

      const OrdenBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Orden</span>
                {rowData.iOrden}
            </React.Fragment>
        );
      }
      

      const CodigoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Codigo</span>
                {rowData.sCodigo}
            </React.Fragment>
        );
      }
      
      const DescripcionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Descripcion</span>
                {rowData.sDescripcion}
            </React.Fragment>
        );
      }

      const ObservacionesBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Observaciones</span>
                {rowData.sObservaciones} 
            </React.Fragment>
        );
      }


      const activoBodyTemplate = (rowData, column) => {
        if(!rowData.bActivo )
        {
          return (
            <React.Fragment>
            <span className="p-column-title">Activo</span>
            <i class="fa fa-circle-o" aria-hidden="true" style={{ fontSize: "2rem", color:'black'  ,cursor: "hand" , verticalAlign: "bottom"}}/>                
        </React.Fragment>);
        }
        else
        {
            return (
                <React.Fragment>
                <span className="p-column-title">Activo</span>
                            <i class="fa fa-check-circle-o" aria-hidden="true" 
                                style={   { fontSize: "2rem", color:'black'  ,cursor: "hand" , verticalAlign: "bottom"  }   }    
                                 />
            </React.Fragment>);
        }
        }
  
        const imagenBodyTemplate = (rowData, column) => {
              return (
                <React.Fragment>
                <span className="p-column-title"></span>
                <img class="smallGrilla" src={rowData.LinkFoto} alt=''  />                
            </React.Fragment>);
            }

            const rightContents = (
                <React.Fragment>
        
                      <span   className="p-input-icon-left">
                            <i  className="pi pi-search" />
                            <InputText     style={   { fontSize: "1.5rem", color:'black'    }   }   
                            type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busqueda Global" />
                        </span>  
                </React.Fragment>
            );
        return (
                error? <MessageBox variant="danger" >{error}</MessageBox>
                :
                (<div style={{marginBottom:'50px'}}>

                <div style={{ position: 'fixed',zIndex: 1, bottom: 0,  right: 0,  justifyContent: 'center', alignItems: 'center', marginBottom:"30px"}} >
                <Tooltip title={<h4 style={{ color: "#FF70FF" }}>Nuevo Grupo</h4>}>
                                <i className="fa fa-plus-circle" aria-hidden="true" style={{fontSize:'72px',color:'black',zIndex:"100",cursor:'pointer',
                                 marginRight:"20px"}} onClick={() => Nueva()}></i> 
                                </Tooltip>
                </div>
                <div style={{  padding:"0px" ,position: 'fixed',zIndex: 1, width: '100%',marginTop:'20px'}}>
                    <Toolbar right={rightContents}  style={{ backgroundColor:"#179F5D", margin:0,padding:0, fontSize:'16px', fontWeight:"bold", width:"auto"}}>
                    </Toolbar>   
                </div>
                <div className="datatable-doc-demo" style={{paddingTop:'55px'}}>
 
<DataTable ref={dt} value={gruposmercaderiaLista} className="p-datatable-customers" dataKey="Id"
rowHover globalFilter={globalFilter}     onSelectionChange={(e) => {  props.history.push(`/gruposmercaderiadetalle/${e.value.Id}`);  }}   selectionMode="single" 
paginator rows={10} emptyMessage="No se encontraron Pedidos" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
loading={loadingdata}
>
 
<Column  field="bActivo" header="Act." body={activoBodyTemplate} headerStyle={{width:"4rem", textAlign: 'center' }}  />
<Column  field="LinkFoto"   body={imagenBodyTemplate} headerStyle={{width:"15rem", textAlign: 'center' }}  />
 
<Column field="Id" header="Id"  body={IdBodyTemplate} headerStyle={{ width:"8rem", textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
<Column field="sCodigo" header="Codigo"  body={CodigoBodyTemplate} headerStyle={{ width:"10rem", textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
<Column field="sDescripcion" header="Descripcion"  body={DescripcionBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
<Column field="iOrden" header="Orden"  body={OrdenBodyTemplate} headerStyle={{ width:"8rem", textAlign: 'center' , margin:'0'}} sortable filter filterPlaceholder="Buscar"></Column>
<Column field="sObservaciones" header="Observaciones"  body={ObservacionesBodyTemplate} sortable filter filterPlaceholder="Buscar"></Column>
</DataTable>       
</div>
</div>)
);
}