import { DESCUENTOSMERCADERIAS_LISTA_FAIL, DESCUENTOSMERCADERIAS_LISTA_REQUEST, DESCUENTOSMERCADERIAS_LISTA_SUCCESS, DESCUENTOSMERCADERIAS_UPDATE_FAIL, 
  DESCUENTOSMERCADERIAS_UPDATE_REQUEST, DESCUENTOSMERCADERIAS_UPDATE_RESET, DESCUENTOSMERCADERIAS_UPDATE_SUCCESS } from "../constants/Descuentos_MercaderiasConstants";

    export const descuentosmercaderiasReducer = ( state = { loading:true,  descuentosmercaderiasLista: [] },  action) => {
    switch (action.type) {
      case DESCUENTOSMERCADERIAS_LISTA_REQUEST:
        return { loading: true };
      case DESCUENTOSMERCADERIAS_LISTA_SUCCESS:
        return { loading: false,descuentosmercaderiasLista: action.payload };
      case DESCUENTOSMERCADERIAS_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const descuentosmercaderiasUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case DESCUENTOSMERCADERIAS_UPDATE_REQUEST:
        return { loading: true };
      case DESCUENTOSMERCADERIAS_UPDATE_SUCCESS:
        return { loading: false, success: true  };
      case DESCUENTOSMERCADERIAS_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case DESCUENTOSMERCADERIAS_UPDATE_RESET:
          return {};
      default:
        return state;
    }
  };


