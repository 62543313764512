export const RANGOSHORARIO_LISTA_REQUEST = 'RANGOSHORARIO_LISTA_REQUEST';
export const RANGOSHORARIO_LISTA_SUCCESS = 'RANGOSHORARIO_LISTA_SUCCESS';
export const RANGOSHORARIO_LISTA_FAIL = 'RANGOSHORARIO_LISTA_FAIL'; 

export const RANGOSHORARIO_UPDATE_REQUEST = 'RANGOSHORARIO_UPDATE_REQUEST';
export const RANGOSHORARIO_UPDATE_SUCCESS = 'RANGOSHORARIO_UPDATE_SUCCESS';
export const RANGOSHORARIO_UPDATE_FAIL = 'RANGOSHORARIO_UPDATE_FAIL'; 
export const RANGOSHORARIO_UPDATE_RESET = 'RANGOSHORARIO_UPDATE_RESET'; 
export const RANGOSHORARIO_UPDATEGRILLA_SUCCESS= 'RANGOSHORARIO_UPDATEGRILLA_SUCCESS'; 

export const RANGOSHORARIO_DELETE_REQUEST = 'RANGOSHORARIO_DELETE_REQUEST';
export const RANGOSHORARIO_DELETE_SUCCESS = 'RANGOSHORARIO_DELETE_SUCCESS';
export const RANGOSHORARIO_DELETE_FAIL = 'RANGOSHORARIO_DELETE_FAIL'; 
export const RANGOSHORARIO_DELETE_RESET = 'RANGOSHORARIO_DELETE_RESET'; 