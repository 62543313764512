import {  FAMILIA_LISTA_REQUEST,
    FAMILIA_LISTA_FAIL,
    FAMILIA_LISTA_SUCCESS,
    FAMILIA_UPDATE_REQUEST,
    FAMILIA_UPDATE_FAIL,
    FAMILIA_UPDATE_SUCCESS
    } from '../constants/familiaConstants';

export const getlistaFamilias = () => async (dispatch) => {
    dispatch({ type: FAMILIA_LISTA_REQUEST});
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Familias/Lista', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: FAMILIA_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: FAMILIA_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: FAMILIA_LISTA_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: FAMILIA_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const updateFamilias = (Item) => async (dispatch, getState) => {
    dispatch({ type: FAMILIA_UPDATE_REQUEST});
    try {
      const { operadorSignin: { operadorInfo }, } = getState(); 
      var parametros = {EmpresaId: localStorage.getItem("empresaId"), Item: Item, OperadorId: operadorInfo.Id};
    
   
   const response = await fetch('https://twinsappservice.azurewebsites.net/api/Familias/Update', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: FAMILIA_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: FAMILIA_UPDATE_FAIL, payload:  data });
           }
           else
           {
           dispatch({type: FAMILIA_UPDATE_SUCCESS }); 
           }  
           
    } catch (error) {
      dispatch({
        type: FAMILIA_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };