import React, { useEffect,  useState }  from 'react';
import { useDispatch,useSelector } from 'react-redux';  
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import { MERCADERIA_GET_SUCCESS } from '../../constants/mercaderiaConstants';
import MessageBox from '../MessageBox';
import { uploadFile } from '../../actions/BlobActions';
const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    fontSize:'20px'
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 50,
  },
  formControlLabel: {
    marginTop: theme.spacing(0),
    marginLeft: '25px',
    label:{
    fontSize:'20px'},
  },
}));

export default function MercaderiaDetalleAppCaracteristicasABM(props) {
  const [Link,setLink]   =   useState(props.Link) ;
  const  [Descripcion,setDescripcion]   = useState(props.Descripcion) ;
  const  [Activo,setActivo]   = useState(props.Activo) ;
  const  [Default,setDefault]   = useState(props.Default) ;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');
  const dispatch = useDispatch();
  const mercaderiaGet =  useSelector(state => state.mercaderiaGet)
  const { mercaderiaItem} = mercaderiaGet;
  const [errorMercaderia,seterrorMercaderia]  = useState(null);

  const mercaderiaSubiendoFotos=  useSelector(state => state.mercaderiaSubiendoFotos);
  const { archivo} = mercaderiaSubiendoFotos;

    useEffect(() => { 
    seterrorMercaderia("");
    }, [dispatch,Descripcion])

    useEffect(() => { 
      setLink(archivo);
      }, [dispatch,archivo])

    const handleClickOpen = () => {
    setOpen(true);
    };
    const handleClose = () => {
    setOpen(false);
    }

    const guardar = () => {
    if (!Descripcion || Descripcion === "") {
        seterrorMercaderia("Debe Cargar la descripcion");
      
        return;}
    var max=null;
    if(props.Caracteristica === 1)
    { 
        if (mercaderiaItem.lstCaracteristicasOpciones1.find(r => r.sValor === Descripcion && r.Id !== props.Id)) {
        seterrorMercaderia("Ya existe un items con esta descripcion");
        return;}
        if(props.Id===0)
        {
            if(mercaderiaItem.lstCaracteristicasOpciones1)
            {
                for (var j=0 ; j<mercaderiaItem.lstCaracteristicasOpciones1.length ; j++) 
                {
                    if (max == null || parseInt(mercaderiaItem.lstCaracteristicasOpciones1[j].Id) > parseInt(max.Id))
                    max = mercaderiaItem.lstCaracteristicasOpciones1[j];
                }
            }
            mercaderiaItem.lstCaracteristicasOpciones1.push({Id:(max? max.Id: 0) +  1 ,MercaderiaCaracteristica_Id:mercaderiaItem.Id, sValor:Descripcion,sLink:Link,bActivo: Activo,bNuevo:true, bDefault:Default, sFotoNombre:""});
            dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});
        }   
        else
        {
            let oCaracteristica = mercaderiaItem.lstCaracteristicasOpciones1.find(r => r.Id === props.Id);
            if(oCaracteristica)
            {  
                oCaracteristica.bActivo = Activo;
                oCaracteristica.sValor = Descripcion;
                oCaracteristica.sLink = Link; 
                oCaracteristica.sDefault = Default;
                dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});
            }
        } 
    }
    else
    {
        if (mercaderiaItem.lstCaracteristicasOpciones2.find(r => r.sValor === Descripcion && r.Id !== props.Id)) 
        {
            seterrorMercaderia("Ya existe un items con esta descripcion");
            return;
        }
        if(props.Id===0)
        { 
            if(mercaderiaItem.lstCaracteristicasOpciones2)
            {
                for (var h=0 ; h<mercaderiaItem.lstCaracteristicasOpciones2.length ; h++) 
                {
                if (max == null || parseInt(mercaderiaItem.lstCaracteristicasOpciones2[h].Id) > parseInt(max.Id))
                max = mercaderiaItem.lstCaracteristicasOpciones2[h];
                }
            }
            mercaderiaItem.lstCaracteristicasOpciones2.push({Id: (max? max.Id: 0) +  1 ,MercaderiaCaracteristica_Id:mercaderiaItem.Id, sValor:Descripcion,sLink:Link,bActivo: Activo,bNuevo:true , bDefault:Default, sFotoNombre:""});
            dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});
        }   
        else{
            let oCaracteristica = mercaderiaItem.lstCaracteristicasOpciones2.find(r => r.Id === props.Id);
            if(oCaracteristica)
            {
                oCaracteristica.bActivo = Activo;
                oCaracteristica.sValor = Descripcion;
                oCaracteristica.sLink = Link; 
                oCaracteristica.sDefault = Default;
                dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});
            }
        }
    }
    setOpen(false);
    };

    const handlefilechange = (e) =>{
      let files = e.target.files;
      handfiles(files);
    }

    const handfiles = files => {   
        for(let file of files){


            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', () =>{
        
             dispatch( uploadFile(file)); })
        }
    }

    const handledelete = (e) =>{
            mercaderiaItem.LinkFoto = "";  
            mercaderiaItem.NombreFotoNueva = "";  
            dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});     
    }

    const [highlight,setHighlight]=useState(false);
    const handlehighlight = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        setHighlight(true);
    }

    const handleunhighlight = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        setHighlight(false);
    }

    const handledrop = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    let dt =e.dataTransfer;
    let files = dt.files;
    handfiles(files);
    setHighlight(false);
    }

    const setDefaults = (Id) => {
    if(Id === 0)
    {setDefault(true);}
    if(props.Caracteristica === 1)
    { 
    if(mercaderiaItem.lstCaracteristicasOpciones1)
    {
    for (var i=0 ; i<mercaderiaItem.lstCaracteristicasOpciones1.length ; i++) 
    {
          mercaderiaItem.lstCaracteristicasOpciones1[i].bDefault = false;
          if(parseInt(mercaderiaItem.lstCaracteristicasOpciones1[i].Id) === parseInt(Id)){
            setDefault(true);
          mercaderiaItem.lstCaracteristicasOpciones1[i].bDefault = true;}
    }
    }
    }
    else
    {
    if(mercaderiaItem.lstCaracteristicasOpciones2)
    {
    for (var j=0 ; j<mercaderiaItem.lstCaracteristicasOpciones2.length ; j++) 
    {
        mercaderiaItem.lstCaracteristicasOpciones2[j].bDefault = false;
        if(parseInt(mercaderiaItem.lstCaracteristicasOpciones2[j].Id) === parseInt(Id)){
          setDefault(true);
        mercaderiaItem.lstCaracteristicasOpciones2[j].bDefault = true;}
    }
    }
    } 
    }
  return (
    <React.Fragment>
      {props.Id===0 ?
       <i class="fa fa-plus-circle" aria-hidden="true" style={{fontSize:'32px',color:'white',cursor:'pointer'}} onClick={handleClickOpen}></i> 
        :<i class="fa fa-pencil-square" aria-hidden="true" style={{fontSize:'32px',color:'black',cursor:'pointer'}} onClick={handleClickOpen}></i>}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
 
        <DialogContent>
 
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
            <div className="row center">
        {props.Id===0 ?
        <h3>Nueva Opcion</h3> :<h3>Editando</h3>}
        </div>
            <div className={highlight? "custom-file-drop-area2 highlight":"custom-file-drop-area2"} onDragEnter={handlehighlight} 
                onDragOver={handlehighlight} onDragLeave={handleunhighlight} onDrop={handledrop}>
                    <input type="file"name="photos" placeholder="Enter photos"   id="filephotos" onChange={handlefilechange}/>
                    <label htmlFor="filephotos" >Arrastre o click para seleccionar</label>
                    {Link !== "" &&
                    <div className="custom-file-preview">
                 <div className="prev-img"  >
                     <span onClick={() => handledelete(Link)}>&times;</span>
                     <img className="smallGrilla" src={Link} alt={Link}/>
                 </div>
         </div>}
                </div>
                <h5 htmlFor="Descripcion" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Descripcion </h5>  
                <input
                id="Descripcion"
                type="text"
                required
                placeholder="Ingrese Descripcion"
                value={Descripcion}
                onChange={(e) => setDescripcion(e.target.value)} ></input>
                {errorMercaderia?<MessageBox variant="danger">{errorMercaderia}</MessageBox>:<></>}
                  <div onClick={() => {setActivo(!Activo)}} style={{fontSize: "2.5rem", color:'black',cursor: "pointer", verticalAlign: "bottom" }}>
                    <tr>
                      <td>{Activo ? <i class="fa fa-check-square-o" 
                                      aria-hidden="true" 
                                      style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                              : <i class="fa fa-square-o" 
                                      aria-hidden="true" 
                                      style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                      }</td>
                      <td> <h4 style={{marginRight:"20px"}}>Activo </h4></td>
                      </tr>                      
                  </div> 
                  <div onClick={() => {setDefaults(parseInt(props.Id))}} style={{fontSize: "2.5rem", color:'black',cursor: "pointer", verticalAlign: "bottom" }}>
                    <tr>
                      <td>{Default ? <i class="fa fa-check-square-o" 
                                      aria-hidden="true" 
                                      style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                              : <i class="fa fa-square-o" 
                                      aria-hidden="true" 
                                      style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                      }</td>
                      <td> <h4 style={{marginRight:"20px"}}>Default </h4></td>
                      </tr>                      
                  </div> 
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={guardar} color="primary">
            <h4>Guardar</h4>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}