import React   from 'react'
import { Link } from 'react-router-dom'; 
export default function Opcion(props) {
  const {opcion}=props;
    return (
      <Link to={`/${opcion.sController}`} > 
      <div key={opcion.Id} className="card"  style={{
        backgroundColor: opcion.Tema_Id === 4 ? '#A5A5A5' : opcion.Tema_Id === 5 ? '#E1CD74' : 'Transparent'
      }}>
      <div className="image-body"  >
         {/*  <img className="small" src={'https://twinsarchivos.blob.core.windows.net/imagenessistema/MenuOpciones/' + opcion.sIcono} alt={opcion.Id} /> */}
         <img className="small" src={'/Imagenes/' + opcion.sIcono}  alt=''/>
      </div>
        <div className="card-body">
          <h4 style={{ overflowWrap: "break-word", width:"120px", color:"black" }}>{opcion.sCodigo + '-'}{opcion.sNombre}</h4>
        </div> 
      </div>
       </Link>
    );
}
