import React, { useEffect,  useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { MERCADERIA_GET_SUCCESS } from '../../constants/mercaderiaConstants';
import {
    /* BrowserView,
    MobileView,
    isBrowser, */
    isMobile
  } from "react-device-detect"; 
import NumericInput from 'react-numeric-input';
import { getcomboUnidades } from '../../actions/comboActions';
import MessageBox from '../MessageBox';
import LoadingBox from '../LoadingBox';
import 'primereact/resources/themes/saga-green/theme.css';
import { AutoComplete } from 'primereact/autocomplete';
import 'primeicons/primeicons.css';   
import { uploadFile } from '../../actions/BlobActions';
import { Link } from 'react-router-dom'; 

export default function MercaderiaDetalleAppPedidos() {
    const dispatch = useDispatch();
    const mercaderiaGet =  useSelector(state => state.mercaderiaGet)
    const { mercaderiaItem} = mercaderiaGet;
    const Modificar = (campo,valor,combo) => 
    {
      switch (campo) {
        case 'iPresentacionUnidadId':
          setUnidad(combo);
        break;
        default:
          break;
      }
      if(combo)
      mercaderiaItem[campo] = valor ? valor : 0;
      else
      mercaderiaItem[campo] = valor;
      dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});    
    }

    const handlefilechange = (e) =>{
        let files = e.target.files;
        handfiles(files);
    }
 
    const handfiles = files => {
        if((files.length + mercaderiaItem.fotos.length ) > 19)
        {
            alert('Solo puede cargar hasta 19 fotos por producto')
            return;
        }
        for(let file of files){
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', () =>{
                dispatch(uploadFile(file,false,true)); 
                  })
        }
    }
 
    const handledelete = (e) =>{
            mercaderiaItem.fotos = mercaderiaItem.fotos.filter((x) => x.Id !== e);  
            dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});     
    }
 
    const [highlight,setHighlight]=useState(false);
    const handlehighlight = (e) =>{
        e.preventDefault();
        e.stopPropagation();
         setHighlight(true);
    }
 
    const handleunhighlight = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        setHighlight(false);
    }
 
    const handledrop = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        let dt =e.dataTransfer;
        let files = dt.files;
        handfiles(files);
        setHighlight(false);
    }

    const comboUnidades =  useSelector(state => state.comboUnidades);
    const {loading: loadingUnidades, error: errorUnidades, UnidadesCombo} = comboUnidades;
    useEffect(() => { 
    dispatch(getcomboUnidades())
    }, [dispatch])
    const [Unidad, setUnidad] = useState(null);
    const [filteredUnidades, setFilteredUnidades] = useState(null); 
    const searchUnidades = (event) => {
        setTimeout(() => {
            let filteredUnidades;
            if (!event.query.trim().length) {
                filteredUnidades = [...UnidadesCombo];
            }
            else {
            filteredUnidades = UnidadesCombo.filter((country) => {
                    return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            } 
            setFilteredUnidades(filteredUnidades);
        }, 250);
    }
    useEffect(() => { 
    if(UnidadesCombo && mercaderiaItem)
    setUnidad(UnidadesCombo.find(r => r.Id === mercaderiaItem.iPresentacionUnidadId));
    }, [dispatch,UnidadesCombo,mercaderiaItem])
    const itemTemplate = (item) => {
        return (
            <div className="country-item">
                <img  alt={item.sDescripcion} src={'https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
                onError={(e) => e.target.src='https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
                className={`flag flag-${item.sCodigo.toLowerCase()}`} />
                <div>{item.sDescripcion}</div>
            </div>
        );
      }
    return ( mercaderiaItem &&
        <div>
            {!isMobile &&
            <div className="custom-form-group">
                <div className={highlight? "custom-file-drop-area highlight":"custom-file-drop-area"} onDragEnter={handlehighlight} 
                onDragOver={handlehighlight} onDragLeave={handleunhighlight} onDrop={handledrop}>
                    <input type="file"name="photos" placeholder="Enter photos" multiple id="filephotos" onChange={handlefilechange}/>
                    <label htmlFor="filephotos" >Arrastre aqui sus imagenes o haga click para seleccionar una</label>
                   
                </div>
                <div className="custom-file-preview">
                    {mercaderiaItem && mercaderiaItem.fotos && mercaderiaItem.fotos.length > 0 && mercaderiaItem.fotos.map(
                        (item,index)=>(
                        <div className="prev-img" key={index} data-imgindex={index}>
                            <span onClick={() => handledelete(item.Id)}>&times;</span>
                            <img src={item.src} alt={item.Id}/>
                        </div>  )
                    )} 
                </div>
            </div>}
            {isMobile &&
            <div className="custom-form-group">
            { <div >
                <input type="file" name="photos"  style={{fontSize:"0px"}}
                        multiple id="filephotos" onChange={handlefilechange}/>
            </div> }
            <div className="custom-file-preview">
                {mercaderiaItem.fotos && mercaderiaItem.fotos.length > 0 && mercaderiaItem.fotos.map(
                    (item,index)=>(
                    <div className="prev-img" key={index} data-imgindex={index}>
                        <span onClick={() => handledelete(item.Id)}>&times;</span>
                        <img src={item.src} alt={item.Id}/>
                    </div>  )
                )} 
            </div>
        </div>}

        <div style={{   padding:"0", alignContent:"center"}} >
               <h2 style={{padding:"0",textAlign:"center"}}>  Presentacion  </h2> 
            </div>
            <form className="form" style={{borderColor:"#585858", border:"1px solid #585858"}} >
            <h5 htmlFor="Unidad" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Unidad </h5>  
            <div>
              {loadingUnidades? <LoadingBox></LoadingBox>
              :
              errorUnidades?<MessageBox variant="danger">{errorUnidades}</MessageBox>
              : 
              <AutoComplete value={Unidad} suggestions={filteredUnidades} completeMethod={searchUnidades} 
                 field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                 onChange={(e) => Modificar('iPresentacionUnidadId', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>   
             }
          </div>
          <tr><td><h5 htmlFor="nPresentacionCantidadMinima" style={{textAlign:"right"}}>Cantidad Minima: </h5></td>
           <td> <NumericInput step={0.1} mobile  size={15} precision={3} min={ 0 }  required value={mercaderiaItem.nPresentacionCantidadMinima} 
                            onChange={e => Modificar('nPresentacionCantidadMinima',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>
                 <tr><td><h5 htmlFor="nPresentacionCantidadMinima" style={{textAlign:"right"}}>Precio: </h5></td>
           <td> <NumericInput step={0.1} mobile  size={15} precision={3} min={ 0 }  required value={mercaderiaItem.nPrecio} 
                            onChange={e => Modificar('nPrecio',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>

<tr><td><h5 htmlFor="nStock" style={{textAlign:"right"}}>Stock: </h5></td>
           <td> <NumericInput step={0.1} mobile  size={15} precision={3} min={ 0 }  required value={mercaderiaItem.nStock} 
                            onChange={e => Modificar('nStock',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>
<div>
                <Link onClick={() => {Modificar('bConsumeCupoHorario',!mercaderiaItem.bConsumeCupoHorario)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                  <div>

                    <tr>
                      <td>{mercaderiaItem.bConsumeCupoHorario ? <i class="fa fa-check-square-o" 
                                      aria-hidden="true" 
                                      style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                              : <i class="fa fa-square-o" 
                                      aria-hidden="true" 
                                      style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                      }</td>
                      <td> <h4>Consume cupo horario </h4></td>
                      </tr> 
                         
                  </div> 
                  </Link> 
                  </div>
                <div>
                      <h5 htmlFor="comment" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Detalle del Producto </h5>  
                      <textarea
                        id="comment"
                        value={mercaderiaItem.Obs}
                        onChange={(e) => Modificar('Obs',e.target.value)}
                      ></textarea>
                    </div>
      </form>
        </div>
    )
}
