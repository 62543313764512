export const PEDIDO_LISTA_REQUEST = 'PEDIDO_LISTA_REQUEST';
export const PEDIDO_LISTA_SUCCESS = 'PEDIDO_LISTA_SUCCESS';
export const PEDIDO_LISTA_FAIL = 'PEDIDO_LISTA_FAIL'; 

export const PEDIDO_APROBAR_REQUEST = 'PEDIDO_APROBAR_REQUEST';
export const PEDIDO_APROBAR_SUCCESS = 'PEDIDO_APROBAR_SUCCESS';
export const PEDIDO_APROBAR_FAIL = 'PEDIDO_APROBAR_FAIL'; 
export const PEDIDO_APROBAR_RESET = 'PEDIDO_APROBAR_RESET'; 

export const PEDIDO_CERRAR_REQUEST = 'PEDIDO_CERRAR_REQUEST';
export const PEDIDO_CERRAR_SUCCESS = 'PEDIDO_CERRAR_SUCCESS';
export const PEDIDO_CERRAR_FAIL = 'PEDIDO_CERRAR_FAIL'; 
export const PEDIDO_CERRAR_RESET = 'PEDIDO_CERRAR_RESET'; 

export const PEDIDO_ELIMINAR_REQUEST = 'PEDIDO_ELIMINAR_REQUEST';
export const PEDIDO_ELIMINAR_SUCCESS = 'PEDIDO_ELIMINAR_SUCCESS';
export const PEDIDO_ELIMINAR_FAIL = 'PEDIDO_ELIMINAR_FAIL'; 
export const PEDIDO_ELIMINAR_RESET = 'PEDIDO_ELIMINAR_RESET'; 