import React,{useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'; 
import { CircularProgress, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors'; 
import clsx from 'clsx';
import Mensaje from 'sweetalert';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import { PANTALLAATRAS, PANTALLASELECCIONADA } from '../constants/operadorConstants';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { getcomboDias, getcomboMetodosPago } from '../actions/comboActions'; 
import { DESCUENTOS_UPDATE_RESET } from '../constants/DescuentosConstants';
import NumericInput from 'react-numeric-input';
import { Link } from 'react-router-dom';
import { DescuentoUpdate } from '../actions/descuentosActions';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    root2: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    }, 
  }));


export default function DescuentosDetalleScreen(props) {
  const DescuentoId = props.match.params.id;
  const descuentosUpdate = useSelector((state) => state.descuentosUpdate);
  const {loading:loadingSave, success, error:errorSave} = descuentosUpdate;
  const descuentos =  useSelector(state => state.descuentos)
  const {  descuentosLista} = descuentos;
  const [Registro, setRegistro] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [MetodoPago,setMetodoPago]  = useState(null);
  const [DiaDesde,setDiaDesde]  = useState(null);
  const [DiaHasta,setDiaHasta]  = useState(null);
  const [HoraDesde,setHoraDesde]  = useState(null);
  const [HoraHasta,setHoraHasta]  = useState(null);
  const [Descripcion,setDescripcion]  = useState(null);

  const [faltaDescuento,setFaltaDescuento]  = useState(null);
  const [FaltaHoraHasta,setFaltaHoraHasta]  = useState(null);
  const [FaltaHoraDesde,setFaltaHoraDesde]  = useState(null);
  const [faltaDiaHasta,setFaltaDiaHasta]  = useState(null);
  const [faltaDiaDesde,setFaltaDiaDesde]  = useState(null);
  const [FaltaMetodoPago,setFaltaMetodoPago]  = useState(null);

  useEffect(() => { 
    if(descuentosLista && DescuentoId > 0)
    {
        let o = descuentosLista.find(l => {  return  l.Id === Number(DescuentoId)});
        if(!o)
        {
            props.history.push(`/descuentos`);
        }
        else
        {
        var today = new Date();
        var FechaDesde = new Date(today.getFullYear(), today.getMonth(), today.getDate(), o.sHoraDesde.substring(0,2), o.sHoraDesde.substring(3,5), 0);
        setHoraDesde (FechaDesde);
        var FechaHasta = new Date(today.getFullYear(), today.getMonth(), today.getDate(), o.sHoraHasta.substring(0,2), o.sHoraHasta.substring(3,5), 0);
        setHoraHasta(FechaHasta);
        setDescripcion(o.sDescripcion);
        setRegistro(o);
        }
    }
    else
    setRegistro({ Error: '',  Id: 0, hDiaDesde: 0, hDiaHasta: 0, sHoraDesde: '', sHoraHasta: '', MetodoPago_Id: 0,
    bEsPorcentaje: true,nDescuento:0,bActivo: true } );
  }, [dispatch,DescuentoId,descuentosLista, props.history])





  const Modificar = (campo,valor,combo) => 
  {
    switch (campo) {
      case 'MetodoPago_Id':
        setMetodoPago(combo);
      break;
      case 'hDiaDesde':
        setDiaDesde(combo);
      break;
      case 'hDiaHasta':
        setDiaHasta(combo);
      break;  
 
      default:
        break;
    }
    if(combo)
    Registro[campo] = valor ? valor : 0;
    else
    {
      alert(valor)
    Registro[campo] = valor; 
    } 
  }


  useEffect(() => { 
    setFaltaDescuento("");
    setFaltaDiaDesde("");
    setFaltaDiaHasta("");
    setFaltaHoraDesde("");
    setFaltaHoraHasta("");
    setFaltaMetodoPago("");
  }, [dispatch,Registro,DiaDesde,DiaHasta,HoraDesde,HoraHasta])

 
  const itemTemplate = (item) => {
    return (
        <div className="country-item">
            <img  alt={item.sDescripcion} src={'https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
            onError={(e) => e.target.src='https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
            className={`flag flag-${item.sDescripcion.toLowerCase()}`} />
            <div>{item.sDescripcion}</div>
        </div>
    );
  }



useEffect(()=>{
  dispatch({type: PANTALLASELECCIONADA, payload: (props.match.params.id === "0" ? 'Nuevo Descuento' : "Editando Descuento Id : " + props.match.params.id)  }); 
  dispatch({type: PANTALLAATRAS, payload:  'Descuentos'}); 
},[dispatch,props.match.params.id])

const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
      });
      const Guardar = () => { 
        if (!DiaDesde ||  DiaDesde.Id === 0) {
            setFaltaDiaDesde("Este campo es obligatorio")}
        else if (!DiaHasta ||  DiaHasta.Id === 0) {
            setFaltaDiaHasta("Este campo es obligatorio") } 
        else if (!MetodoPago  ) {
            setFaltaMetodoPago("Este campo es obligatorio") } 
        else if (!HoraDesde ||  HoraDesde === "" ){
            setFaltaHoraDesde("Este campo es obligatorio") } 
              else if (!HoraHasta ||  HoraHasta === "" ){
            setFaltaHoraHasta("Este campo es obligatorio") } 
      else {
        
        Registro.sHoraDesde =    String(HoraDesde.getHours()).padStart(2, "0") + ":" + String(HoraDesde.getMinutes()).padStart(2, "0")
        Registro.sHoraHasta =    String(HoraHasta.getHours()).padStart(2, "0") + ":" + String(HoraHasta.getMinutes()).padStart(2, "0")
        Registro.sDescripcion=Descripcion;
      
          dispatch(DescuentoUpdate(Registro,true));
          
      } 
     };

     useEffect(() => {
        if (success) {
            Mensaje({  title: "Exito", text: 'Descuento guardado satisfactoriamente' ,  icon: "success", button: "cerrar" });
            dispatch({ type: DESCUENTOS_UPDATE_RESET });
            props.history.push(`/descuentos`);
        }
        if(errorSave)
        {
          Mensaje({ title: "Error",  text: errorSave, icon: "error", button: "cerrar" });
        }
      }, [dispatch, props.history, success,errorSave]);



      const comboMetodosPago =  useSelector(state => state.comboMetodosPago);
      const {loading: loadingMetodosPago, error: errorMetodosPago, MetodosPagoCombo} = comboMetodosPago;
      useEffect(() => { 
           dispatch(getcomboMetodosPago())
      }, [dispatch])
      const [filteredMetodosPago, setFilteredMetodosPago] = useState(null); 
      const searchMetodosPago = (event) => {
          setTimeout(() => {
              let filteredMetodosPago;
              if (!event.query.trim().length) {
                  filteredMetodosPago = [...MetodosPagoCombo];
              }
              else {
                filteredMetodosPago = MetodosPagoCombo.filter((country) => {
                      return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                  });
              } 
              setFilteredMetodosPago(filteredMetodosPago);
          }, 250);
      }
      useEffect(() => { 
        if(MetodosPagoCombo && Registro)
        setMetodoPago(MetodosPagoCombo.find(r => r.Id ===Registro.MetodoPago_Id ));
    }, [dispatch,MetodosPagoCombo,Registro])

      const comboDias =  useSelector(state => state.comboDias);
      const {loading: loadingDias, error: errorDias, DiasCombo} = comboDias;
      useEffect(() => { 
           dispatch(getcomboDias())
      }, [dispatch])
  
      const [filteredDiasDesde, setFilteredDiasDesde] = useState(null); 
      const searchDiasDesde = (event) => {
          setTimeout(() => {
              let filteredDiasDesde;
              if (!event.query.trim().length) {
                  filteredDiasDesde = [...DiasCombo];
              }
              else {
                filteredDiasDesde = DiasCombo.filter((country) => {
                      return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                  });
              } 
              setFilteredDiasDesde(filteredDiasDesde);
          }, 250);
      }
 

      const [filteredDiasHasta, setFilteredDiasHasta] = useState(null); 
      const searchDiasHasta = (event) => {
          setTimeout(() => {
              let filteredDiasHasta;
              if (!event.query.trim().length) {
                  filteredDiasHasta = [...DiasCombo];
              }
              else {
                filteredDiasHasta = DiasCombo.filter((country) => {
                      return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                  });
              } 
              setFilteredDiasHasta(filteredDiasHasta);
          }, 250);
      }
      useEffect(() => { 
        if(DiasCombo && Registro)
        {
          setDiaDesde(DiasCombo.find(r => r.Id ===Number(Registro.hDiaDesde) ));
          setDiaHasta(DiasCombo.find(r => r.Id ===Number(Registro.hDiaHasta) ));
        }
    }, [dispatch,DiasCombo,Registro])

    return (  Registro ? <>
<div style={{backgroundColor:"#585858" ,with:"100%",position: 'fixed',zIndex:"1" , marginTop:"10px" }}  >
<div className={classes.root2} style={{ position: 'fixed', top: 0,  right: 0,  justifyContent: 'center', alignItems: 'center', marginTop:"50px"}} >
              <div className={classes.wrapper}>
                  <Fab aria-label="save"  color="primary" className={buttonClassname} onClick={() => Guardar()}  >
                    {success ? <CheckIcon /> : <SaveIcon />}
                  </Fab>
                  {loadingSave && <CircularProgress size={68} className={classes.fabProgress} />}
              </div>
            </div>
      </div>  
        ({  

<div className="row top" style={{marginTop:"10px" , marginLeft:"20px" ,marginBottom:"50px" }} > 
< form className="form"  style={{width:"100%"}} > 
{Registro.Id > 0 && <>
        <Link onClick={() => {Modificar("bActivo",!Registro.bActivo)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
        <div> 
            {Registro.bActivo ? <i class="fa fa-check-square-o" 
                            aria-hidden="true" 
                            style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                    : <i class="fa fa-square-o" 
                            aria-hidden="true" 
                            style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
            } Activo
        </div> 
    </Link> 
    </>}                               
        <div><h5 htmlFor="Dia Desde" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Dia Desde </h5> 
             {loadingDias? <LoadingBox></LoadingBox> :
                errorDias?<MessageBox variant="danger">{errorDias}</MessageBox> :    
                <AutoComplete value={DiaDesde} suggestions={filteredDiasDesde} completeMethod={searchDiasDesde} 
                              field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                              onChange={(e) => Modificar( "hDiaDesde", e.value ? e.value.Id :0,e.value )} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
              </div> {faltaDiaDesde?<MessageBox variant="danger">{faltaDiaDesde}</MessageBox>:<></>}

              <div><h5 htmlFor="Dia Hasta" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Dia Hasta </h5> 
               {loadingDias? <LoadingBox></LoadingBox> :
                    errorDias?<MessageBox variant="danger">{errorDias}</MessageBox> :    
                    <AutoComplete value={DiaHasta} suggestions={filteredDiasHasta} completeMethod={searchDiasHasta} 
                                  field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                                  onChange={(e) => Modificar(  "hDiaHasta", e.value ? e.value.Id :0,e.value  )} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
              </div> {faltaDiaHasta?<MessageBox variant="danger">{faltaDiaHasta}</MessageBox>:<></>}

              <div> <h5 htmlFor="Metodo Pago" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Metodo Pago</h5> 
               {loadingMetodosPago? <LoadingBox></LoadingBox> :
                    errorMetodosPago?<MessageBox variant="danger">{errorMetodosPago}</MessageBox> :    
                    <AutoComplete value={MetodoPago} suggestions={filteredMetodosPago} completeMethod={searchMetodosPago} 
                                  field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                                  onChange={(e) => Modificar("MetodoPago_Id", e.value ? e.value.Id :0,e.value  )} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
              </div> {FaltaMetodoPago?<MessageBox variant="danger">{FaltaMetodoPago}</MessageBox>:<></>}

              <div className="p-field p-col-12 p-md-4"> <h5 htmlFor="Desde" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Desde </h5> 
                      <Calendar id="time12" value={HoraDesde} inputStyle={{fontSize:"20px"}} onChange={(e) => setHoraDesde(e.value)} timeOnly hourFormat="24" panelStyle={{fontSize:"20px"}} />
              </div> {FaltaHoraDesde?<MessageBox variant="danger">{FaltaHoraDesde}</MessageBox>:<></>}

              <div className="p-field p-col-12 p-md-4"> <h5 htmlFor="HoraHasta" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Hasta </h5> 
                      <Calendar id="time12" value={HoraHasta} inputStyle={{fontSize:"20px"}} onChange={(e) => setHoraHasta(e.value)} timeOnly hourFormat="24" panelStyle={{fontSize:"20px"}} />
              </div> {FaltaHoraHasta?<MessageBox variant="danger">{FaltaHoraHasta}</MessageBox>:<></>}
      

          <Link onClick={() => {Modificar("bEsPorcentaje",!Registro.bEsPorcentaje)}} style={{fontSize: "2rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
          <div> 
              {Registro.bEsPorcentaje ? <i class="fa fa-check-square-o" 
                              aria-hidden="true" 
                              style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                      : <i class="fa fa-square-o" 
                              aria-hidden="true" 
                              style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
              } Por Porcentaje (Destilde para monto fijo )
              
          </div> 
      </Link> 
     
              <td><h5 htmlFor="nDescuento" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Descuento </h5>  
              <NumericInput step={0.1} mobile  size={15} precision={3} min={ 0 }  required value={Registro.nDescuento} 
                            onChange={e => Modificar("nDescuento",e?e:0)}
                                    style={{    wrap: {  fontSize: 14 },
                                                input: {  fontWeight: 100,  }, 
                                                'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                                arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                                arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td>
                {faltaDescuento?<MessageBox variant="danger">{faltaDescuento}</MessageBox>:<></>}
                <div>
                      <h5 htmlFor="sDescripcion" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Detalle del Descuento </h5>  
                      <textarea maxLength={50}
                        id="sDescripcion"
                        value={ Descripcion}
                        onChange={(e) => setDescripcion( e.target.value)}
                      ></textarea>
                    </div>
          </form>
       </div>}
            </>:<></>
    )
}
     