

import React, { useState, useEffect } from 'react';
import NumericInput from 'react-numeric-input';
import { useDispatch,useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getcomboGruposMercaderia, getcomboMercaderias } from '../../actions/comboActions';
 
import { MERCADERIA_GET_SUCCESS } from '../../constants/mercaderiaConstants';
import LoadingBox from '../LoadingBox';
import MessageBox from '../MessageBox';
import 'primereact/resources/themes/saga-green/theme.css';
import { AutoComplete } from 'primereact/autocomplete';
import 'primeicons/primeicons.css';
export default function MercaderiaDetalleOtros() {
  const dispatch = useDispatch();
  const mercaderiaGet =  useSelector(state => state.mercaderiaGet)
  const { mercaderiaItem} = mercaderiaGet;
  const Modificar = (campo,valor,combo) => 
  {
    switch (campo) {
      case 'MercaderiaGrupo_Id':
        setGrupoMercaderia(combo);
      break;
      case 'MercaderiaIdMutacionEnvaseSecundario':
        setMercaderia(combo);
      break;
      
      default:
        break;
    }
    if(combo)
    mercaderiaItem[campo] = valor ? valor : 0;
    else
    mercaderiaItem[campo] = valor;
    dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});    
  }


    const comboGruposMercaderia =  useSelector(state => state.comboGruposMercaderia);
    const {loading: loadingGruposMercaderia, error: errorGruposMercaderia, GruposMercaderiaCombo} = comboGruposMercaderia;
    useEffect(() => { 
         dispatch(getcomboGruposMercaderia())
    }, [dispatch])
    const [GrupoMercaderia , setGrupoMercaderia] = useState(null);
    const [filteredGruposMercaderia, setFilteredGruposMercaderia] = useState(null); 
    const searchGruposMercaderia = (event) => {
        setTimeout(() => {
            let filteredGruposMercaderia;
            if (!event.query.trim().length) {
                filteredGruposMercaderia = [...GruposMercaderiaCombo];
            }
            else {
              filteredGruposMercaderia = GruposMercaderiaCombo.filter((country) => {
                    return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            } 
            setFilteredGruposMercaderia(filteredGruposMercaderia);
        }, 250);
    }
    useEffect(() => { 
      if(GruposMercaderiaCombo && mercaderiaItem)
      setGrupoMercaderia(GruposMercaderiaCombo.find(r => r.Id === mercaderiaItem.MercaderiaGrupo_Id));
  }, [dispatch,GruposMercaderiaCombo,mercaderiaItem])



    const comboMercaderias =  useSelector(state => state.comboMercaderias);
    const {loading: loadingMercaderias, error: errorMercaderias, MercaderiasCombo} = comboMercaderias;
    useEffect(() => { 
         dispatch(getcomboMercaderias())
    }, [dispatch])
    const [Mercaderia , setMercaderia] = useState(null);
    const [filteredMercaderias, setFilteredMercaderias] = useState(null); 
    const searchMercaderias = (event) => {
        setTimeout(() => {
            let filteredMercaderias;
            if (!event.query.trim().length) {
                filteredMercaderias = [...MercaderiasCombo];
            }
            else {
              filteredMercaderias = MercaderiasCombo.filter((country) => {
                    return country.sDescripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            } 
            setFilteredMercaderias(filteredMercaderias);
        }, 250);
    }
    useEffect(() => { 
      if(MercaderiasCombo && mercaderiaItem)
      setMercaderia(MercaderiasCombo.find(r => r.Id === mercaderiaItem.MercaderiaIdMutacionEnvaseSecundario));
  }, [dispatch,MercaderiasCombo,mercaderiaItem])

  
  const itemTemplate = (item) => {
    return (
        <div className="country-item">
            <img  alt={item.sDescripcion} src={'https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
            onError={(e) => e.target.src='https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
            className={`flag flag-${item.sCodigo.toLowerCase()}`} />
            <div>{item.sDescripcion}</div>
        </div>
    );
  }
    return (
      mercaderiaItem &&
        <form className="form">
            <h5 htmlFor="sCodigoExterno" style={{marginBottom:"0px"}}>Codigo Externo</h5>
            <input
            id="sCodigoExterno"
            type="text" 
            placeholder="Ingrese Codigo Externo"
            value={mercaderiaItem.sCodigoExterno}
            onChange={(e) => Modificar('sCodigoExterno',e.target.value)} ></input>
 
            <Link onClick={() => {Modificar('bControlCalidad',!mercaderiaItem.bControlCalidad)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                  <div>

                    <tr>
                      <td>{mercaderiaItem.bControlCalidad ? <i class="fa fa-check-square-o" 
                                      aria-hidden="true" 
                                      style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                              : <i class="fa fa-square-o" 
                                      aria-hidden="true" 
                                      style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                      }</td>
                      <td> <h4 style={{marginRight:"20px"}}> Control Calidad </h4></td>
                      </tr> 
                         
                  </div> 
                  </Link> 

            <h5 htmlFor="MercaderiaGrupo_Id">Grupo</h5>
            <div>
              {loadingGruposMercaderia ? <LoadingBox></LoadingBox>
              :
              errorGruposMercaderia?<MessageBox variant="danger">{errorGruposMercaderia}</MessageBox>
              :    
              <AutoComplete value={GrupoMercaderia} suggestions={filteredGruposMercaderia} completeMethod={searchGruposMercaderia} 
                 field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                 onChange={(e) => Modificar('MercaderiaGrupo_Id', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
          </div>

 

<tr><td><h5 htmlFor="txtPesoMinimoPieza" style={{textAlign:"right"}}>Cantidad Por Muestreo: </h5></td>
           <td> <NumericInput step={1} mobile  size={6} precision={0} min={ 0 }  required value={mercaderiaItem.hCantidadMuestra} 
                            onChange={e => Modificar('hCantidadMuestra',e?e:0)}
                                      style={{
                                        wrap: {  fontSize: 14 },
                                        input: {  fontWeight: 100,  },
                                        'input:focus' : {  border: '1px inset #69C', outline: 'none'  },
                                        arrowUp: {  borderBottomColor: 'rgba(66, 54, 0, 0.63)'  },
                                        arrowDown: {  borderTopColor: 'rgba(66, 54, 0, 0.63)' }
                                    }}/></td></tr>
 
            <Link onClick={() => {Modificar('bDesvio',!mercaderiaItem.bDesvio)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                  <div>

                    <tr>
                      <td>{mercaderiaItem.bDesvio ? <i class="fa fa-check-square-o" 
                                      aria-hidden="true" 
                                      style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                              : <i class="fa fa-square-o" 
                                      aria-hidden="true" 
                                      style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                      }</td>
                      <td> <h4 style={{marginRight:"20px"}}>Es Desvio </h4></td>
                      </tr> 
                         
                  </div> 
                  </Link> 

            <h5 htmlFor="MercaderiaIdMutacionEnvaseSecundario">Mutacion Por Cambio de Envase</h5>
            <div>
              {loadingMercaderias ? <LoadingBox></LoadingBox>
              :
              errorMercaderias?<MessageBox variant="danger">{errorMercaderias}</MessageBox>
              :    
              <AutoComplete value={Mercaderia} suggestions={filteredMercaderias} completeMethod={searchMercaderias} 
                 field="sDescripcion" dropdown forceSelection itemTemplate={itemTemplate}  
                 onChange={(e) => Modificar('MercaderiaIdMutacionEnvaseSecundario', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
          </div>
          <h5 htmlFor="Abreviatura" style={{marginBottom:"0px"}}>Abreviatura</h5>
            <input
            id="Abreviatura"
            type="text" 
            placeholder="Ingrese Abreviatura"
            value={mercaderiaItem.Abreviatura}
            onChange={(e) => Modificar('Abreviatura',e.target.value)} ></input>

         </form>
    )
}
