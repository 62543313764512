export const FAMILIASPROMOCIONES_LISTA_REQUEST = 'FAMILIASPROMOCIONES_LISTA_REQUEST';
export const FAMILIASPROMOCIONES_LISTA_SUCCESS = 'FAMILIASPROMOCIONES_LISTA_SUCCESS';
export const FAMILIASPROMOCIONES_LISTA_FAIL = 'FAMILIASPROMOCIONES_LISTA_FAIL'; 

export const FAMILIASPROMOCIONES_UPDATE_REQUEST = 'FAMILIASPROMOCIONES_UPDATE_REQUEST';
export const FAMILIASPROMOCIONES_UPDATE_SUCCESS = 'FAMILIASPROMOCIONES_UPDATE_SUCCESS';
export const FAMILIASPROMOCIONES_UPDATE_FAIL = 'FAMILIASPROMOCIONES_UPDATE_FAIL'; 
export const FAMILIASPROMOCIONES_UPDATE_RESET = 'FAMILIASPROMOCIONES_UPDATE_RESET'; 
export const FAMILIASPROMOCIONES_UPDATEGRILLA_SUCCESS= 'FAMILIASPROMOCIONES_UPDATEGRILLA_SUCCESS'; 

export const FAMILIASPROMOCIONES_DELETE_REQUEST = 'FAMILIASPROMOCIONES_DELETE_REQUEST';
export const FAMILIASPROMOCIONES_DELETE_SUCCESS = 'FAMILIASPROMOCIONES_DELETE_SUCCESS';
export const FAMILIASPROMOCIONES_DELETE_FAIL = 'FAMILIASPROMOCIONES_DELETE_FAIL'; 
export const FAMILIASPROMOCIONES_DELETE_RESET = 'FAMILIASPROMOCIONES_DELETE_RESET'; 