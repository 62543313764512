import React,{useEffect} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { BrowserRouter, Link, Route } from 'react-router-dom';
import LoginPantalla from './Pantallas/LoginPantalla';
import Menu from './Pantallas/MenuPantalla';
import { Tooltip } from '@material-ui/core';
import { signout } from './actions/operadorActions'; 
import FamiliaPantalla from './Pantallas/FamiliaPantalla';
import FamiliaDetallePantalla from './Pantallas/FamiliaDetallePantalla';
import { useState  } from 'react'; 
import MercaderiasAppPantalla from './Pantallas/MercaderiasAppPantalla';
import MercaderiaAppDetalleSreen from './Pantallas/MercaderiaAppDetalleSreen';
import MercaderiasOpcionesStockScreen from './Pantallas/MercaderiasOpcionesStockScreen';
import IdentificacionSreen from './Pantallas/IdentificacionSreen';
import LocalidadesAppScreen from './Pantallas/LocalidadesAppScreen';
import FamiliasPromocionesScreen from './Pantallas/FamiliasPromocionesScreen';
import GruposMercaderiaScreen from './Pantallas/GruposMercaderiaScreen';
import GruposMercaderiaDetalleScreen from './Pantallas/GruposMercaderiaDetalleScreen';
import MercaderiasGruposMercaderiasScreen from './Pantallas/MercaderiasGruposMercaderiasScreen';
import { Sidebar } from 'primereact/sidebar';
import { Toolbar } from 'primereact/toolbar';
import PedidosScreen from './Pantallas/PedidosScreen';
import RangosHorarioScreen from './Pantallas/RangosHorarioScreen';
import DescuentosScreen from './Pantallas/DescuentosScreen';
import DescuentosDetalleScreen from './Pantallas/DescuentosDetalleScreen';
import DescuentosMercaderiasScreen from './Pantallas/DescuentosMercaderiasScreen';
import MercaderiasAppStockScreen from './Pantallas/MercaderiasAppStockScreen';
function App() {
  const operadorSignin = useSelector((state) => state.operadorSignin);
  const {operadorInfo} = operadorSignin;
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [logoempresa, setlogoempresa] = useState("https://twinsarchivos.blob.core.windows.net/logosempresas/"+ localStorage.getItem('EmpresaId')+".png");
  const funcionalidadesGet = useSelector((state) => state.funcionalidadesGet);
  const {funcionalidadesLista} = funcionalidadesGet;
  const pantallaSeleccionada = useSelector((state) => state.pantallaSeleccionada);
  const {pantallaInfo} = pantallaSeleccionada;
  const pantallaAtras= useSelector((state) => state.pantallaAtras);
  const {pantallaAtrasInfo} = pantallaAtras;



  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
    //setSidebarIsOpen(false)
  }; 

  useEffect(() => {
    
    setlogoempresa("https://twinsarchivos.blob.core.windows.net/logosempresas/"+ (operadorInfo && operadorInfo.Empresa_Id ? String(operadorInfo.Empresa_Id):'188')+".png");

  }, [dispatch, operadorInfo]);




  const leftContents = (
    <React.Fragment>
      { !sidebarIsOpen ?
                 <i style={{fontSize: '34px',color: 'white', margin:"10px",cursor: "pointer"}} onClick={() => setSidebarIsOpen(true)}  className="fa fa-bars"></i>
                : <i style={{fontSize: '34px',color: 'white', margin:"10px", cursor: "pointer"}} onClick={() => setSidebarIsOpen(false)} className="fa fa-arrow-left"></i>
              }
                {pantallaInfo !== "Menu" ?
                    <Link style={{ fontSize: "2rem",color:'white',cursor: "pointer",margin:'10px', padding:"0"}} to={"/"+( pantallaAtrasInfo ? pantallaAtrasInfo :'')}>
                        <div style={{padding:"0",margin:'0'}}> 
                            <i className="fa fa-arrow-left" aria-hidden="true" style={{ fontSize: "3rem", color:'white',margin:'0', padding:"0"}}/> 
                        </div>
                    </Link>
                :<></>}
                 <h3 style={{ fontSize: "1.5rem",color:'white' ,margin:'0', padding:"0", marginLeft:"10px"}}>{pantallaInfo}</h3>
    </React.Fragment>
);

const rightContents = (
  <React.Fragment>
     {operadorInfo ?  
                   <Tooltip title={<h2 style={{ color: "lightgreen" }}>Salir del sistema</h2>}>
                      <Link to="/signin" style={{color: 'white',margin:'0', padding:"0"}}   onClick={signoutHandler}>  
                          <i className="fa fa-sign-out" style={{fontSize: '34px',color: 'white',margin:'0', padding:"0"}}  aria-hidden="true"></i>
                      </Link>
                  </Tooltip>
             :  <></>
            }       
    </React.Fragment>
);







  return (
    <BrowserRouter>
    <div className="grid-container">
      <header>
          <Toolbar left={leftContents} right={rightContents} style={{ backgroundColor:"#186C44", margin:0,padding:0, fontSize:'16px', fontWeight:"bold", width:"auto"}}>
          </Toolbar>
      </header>

      <Sidebar style={{overflowY:'auto', backgroundColor:"black" }} backgroundColor="black"   visible={sidebarIsOpen} baseZIndex={1000000} onHide={() => setSidebarIsOpen(false)}>
        <div>
          <div class="container">
            <img className="large2" src={logoempresa} alt='imslogo' />
        </div>
        </div>  
              {operadorInfo && <strong style={{color: 'white'}}>Bienvenido {operadorInfo.Nombre} </strong>}
              <ul className="categories">
                  {operadorInfo ? (
                  <div>
                      <li>
                          <Link to="/Perfil" onClick={() => setSidebarIsOpen(false)} style={{color: 'white'}} activeStyle={{color: 'red'}}>  
                              <tbody><td>  <i className="fa fa-id-card"></i>  </td><td><span style={{marginLeft:'10px'}}> Mis Datos</span></td></tbody>
                          </Link>
                      </li>
                      <li>
                          <Link to="#signout" style={{color: 'white'}} activeStyle={{color: 'red'}} onClick={signoutHandler}>  
                              <tbody><td><i class="fa fa-sign-out" aria-hidden="true"></i></td><td><span style={{marginLeft:'10px'}}> Salir</span></td></tbody>
                          </Link>
                      </li> 
                  </div>
            ) : (
              <div>
                  <ul> 
                      <li>
                      <Link to="/signin" onClick={() => setSidebarIsOpen(false)} style={{color: 'white'}} activeStyle={{color: 'red'}}>  
                          <tbody><td><i class="fa fa-user-circle" style={{fontSize: '24px', marginRight: '5px'}}  aria-hidden="true"></i> </td><td>
                          <span>Ingresar</span></td></tbody>
                      </Link>
                      </li>
                  </ul>
              </div>
            )}        
</ul>
          <ul className="categories">
            <li>
              <strong>Opciones</strong>
            </li>
           
            {!funcionalidadesLista  ? (
              <></>
            ) : 
            (         
              /* categories.map((c) => (
                  <Link to={`/search/category/${c.Grupo_Id}`}  onClick={() => setSidebarIsOpen(false)}>
                    <li key={c.Grupo_Id}><FilterIcon/> {c.Descripcion}
                </li>
                </Link>
              )) */


              funcionalidadesLista.map((c) => (
                <Link key={c.Id}  to={`/${c.sController}`} onClick={() => setSidebarIsOpen(false)} style={{color: 'white'}} activeStyle={{color: 'red'}}>  
                <li  
           
                >
                         
                   {' ' + c.sNombre} </li>      
                   </Link>   
            ))           
            )}
          </ul>
           
        </Sidebar>

      <main >
        <Route path="/" component={LoginPantalla} exact></Route> 
        <Route path="/signin" component={LoginPantalla} exact></Route>
        <Route path="/Menu" component={Menu}></Route> 
        <Route path="/familias" component={FamiliaPantalla}></Route> 
        <Route path="/mercaderiasapp" component={MercaderiasAppPantalla}></Route> 
        <Route path="/familiadetalle/:id" component={FamiliaDetallePantalla}></Route> 
        <Route path="/mercaderiadetalle/:id/:codigo" component={MercaderiaAppDetalleSreen}></Route> 
        <Route path="/MercaderiasOpcionesStock" component={MercaderiasOpcionesStockScreen}></Route> 
        <Route path="/Identificacion" component={IdentificacionSreen}></Route> 
        <Route path="/LocalidadesDisponiblesApp" component={LocalidadesAppScreen}></Route> 
        <Route path="/FamiliasPromociones" component={FamiliasPromocionesScreen}></Route> 
        <Route path="/GruposMercaderia" component={GruposMercaderiaScreen}></Route> 
        <Route path="/GruposMercaderiaDetalle/:id" component={GruposMercaderiaDetalleScreen}></Route> 
        <Route path="/Mercaderias_GruposMercaderia" component={MercaderiasGruposMercaderiasScreen}></Route> 
        <Route path="/Pedidos" component={PedidosScreen}></Route> 
        <Route path="/RangosHorario" component={RangosHorarioScreen}></Route> 
        <Route path="/Descuentos" component={DescuentosScreen}></Route> 
        <Route path="/descuentodetalle/:id" component={DescuentosDetalleScreen}></Route> 
        <Route path="/Descuentos_Mercaderias" component={DescuentosMercaderiasScreen}></Route> 
        <Route path="/MercaderiasAppStock" component={MercaderiasAppStockScreen}></Route> 
      </main>
      <footer className="row center" style={{ backgroundColor:"#186C44"}}>Twins Informatica SRL 2021 </footer>
    </div>
  </BrowserRouter>
  );
}
export default App;
