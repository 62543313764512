import {  FUNCIONALIDADES_LISTA_FAIL,
    FUNCIONALIDADES_LISTA_REQUEST,
    FUNCIONALIDADES_LISTA_SUCCESS
    } from '../constants/funcionalidadesConstants';
export const getlista = (EmpresaId) => async (dispatch) => {
    dispatch({ type: FUNCIONALIDADES_LISTA_REQUEST});
    try {
      var parametros = {EmpresaId: EmpresaId,ProgramaId:  0};
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/Pantallas/ListaWeb', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
             if(data.Message)
           {
            dispatch({ type: FUNCIONALIDADES_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else
           {
           dispatch({type: FUNCIONALIDADES_LISTA_SUCCESS, payload:  data.Lista.sort((a, b) => a.sCodigo - b.sCodigo)}); 
           localStorage.setItem('funcionalidadesLista',  JSON.stringify(data.Lista.sort((a, b) => a.sCodigo - b.sCodigo) ));
           }
           
    } catch (error) {
      dispatch({
        type: FUNCIONALIDADES_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };