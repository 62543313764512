import React, { useEffect,  useState }  from 'react';
import { useDispatch,useSelector } from 'react-redux';  
import { makeStyles } from '@material-ui/core/styles'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import MessageBox from './MessageBox';
import { CircularProgress, Fab } from '@material-ui/core';
import { green } from '@material-ui/core/colors'; 
import clsx from 'clsx';
import { RangoHorarioUpdate, getlistaRangosHorario } from '../actions/rangoshorarioActions';
import Mensaje from 'sweetalert';
import { RANGOSHORARIO_UPDATE_RESET } from '../constants/RangosHorarioConstants';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save'; 
import 'primereact/resources/themes/saga-green/theme.css';
import { Calendar } from 'primereact/calendar';
import 'primeicons/primeicons.css';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    fontSize:'20px'
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 200,
  },
  formControlLabel: {
    marginTop: theme.spacing(0),
    marginLeft: '25px',
    label:{
    fontSize:'20px'},
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  root2: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function RangoHorarioNuevo(props) {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');
  const dispatch = useDispatch(); 
 
  const RangosHorario =  useSelector(state => state.RangosHorario)
  const {  RangosHorarioLista} = RangosHorario; 
  const RangosHorarioUpdate = useSelector((state) => state.RangosHorarioUpdate);
  const {loading:loadingSave, success, error:errorSave} = RangosHorarioUpdate;

  const [faltaDesde,setFaltaDesde]  = useState(null);
  const [faltaHasta,setFaltaHasta]  = useState(null);

  const [Desde, setDesde] = useState(0);
  const [Hasta, setHasta] = useState(0);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
 


    const handleClickOpen = () => {
    setOpen(true);
    };
    const handleClose = () => {
    setOpen(false);
    }

    const Guardar = () => { 
      if (!Desde || Desde === '') {
        setFaltaDesde("Debe cargar el Rango desde");
          return;}

          if (!Hasta || Hasta === '') {
            setFaltaHasta("Debe cargar el Rango Hasta");
              return;}



       let HoraDesde =    String(Desde.getHours()).padStart(2, "0") + ":" + String(Desde.getMinutes()).padStart(2, "0")
       let HoraHasta =    String(Hasta.getHours()).padStart(2, "0") + ":" + String(Hasta.getMinutes()).padStart(2, "0")

       if ( HoraDesde > HoraHasta ) {
        Mensaje({ title: "Error",  text: "El Hoario desde debe ser mayor que el horario hasta", icon: "error", button: "cerrar" });
          return;}

        dispatch(RangoHorarioUpdate(0,0,HoraDesde,HoraHasta,true ));
   };


   useEffect(() => {
    if (success) {
        Mensaje({  title: "Exito", text: 'Promocion generada satisfactoriamente' ,  icon: "success", button: "cerrar" });
        dispatch({ type: RANGOSHORARIO_UPDATE_RESET });
        dispatch(getlistaRangosHorario());
    }
    if(errorSave)
    {
      Mensaje({ title: "Error",  text: errorSave, icon: "error", button: "cerrar" });
    }
  }, [dispatch, props.history, success,errorSave]);


  useEffect(() => { 
    setOpen(false);
}, [dispatch,RangosHorarioLista])
  

  return (
    <React.Fragment> 
       <i class="fa fa-plus-circle" aria-hidden="true" style={{fontSize:'72px',color:'black',cursor:'pointer',zIndex:"100", marginRight:"20px"}} onClick={handleClickOpen}></i>  
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >

        <DialogContent>
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
            <div className="row center">
       
        <h3>Nuevo Rango Horario</h3>
        </div>
              <h5 htmlFor="Desde" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Desde </h5> 
              <div className="p-field p-col-12 p-md-4">
                      <Calendar id="time12" value={Desde} inputStyle={{fontSize:"20px"}} onChange={(e) => setDesde(e.value)} timeOnly hourFormat="24" panelStyle={{fontSize:"20px"}} />
              </div>
              {faltaDesde?<MessageBox variant="danger">{faltaDesde}</MessageBox>:<></>}

              <h5 htmlFor="Hasta" style={{marginLeft:"5px", verticalAlign:"end", marginBottom:"0"}}>Hasta </h5> 
              <div className="p-field p-col-12 p-md-4">
                      <Calendar id="time12" value={Hasta} inputStyle={{fontSize:"20px"}} onChange={(e) => setHasta(e.value)} timeOnly hourFormat="24" panelStyle={{fontSize:"20px"}} />
              </div>
              {faltaHasta?<MessageBox variant="danger">{faltaHasta}</MessageBox>:<></>}
      

            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
        <div className={classes.root2}>
              <div className={classes.wrapper}>
                <Fab
                  aria-label="save"
                  color="primary"
                  className={buttonClassname}
                  onClick={() => Guardar()}
                >
                  {success ? <CheckIcon /> : <SaveIcon />}
                </Fab>
                {loadingSave && <CircularProgress size={68} className={classes.fabProgress} />}
              </div>
            </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}