import { RANGOSHORARIO_DELETE_FAIL, RANGOSHORARIO_DELETE_REQUEST, RANGOSHORARIO_DELETE_SUCCESS, RANGOSHORARIO_LISTA_FAIL,
   RANGOSHORARIO_LISTA_REQUEST, RANGOSHORARIO_LISTA_SUCCESS, RANGOSHORARIO_UPDATEGRILLA_SUCCESS, RANGOSHORARIO_UPDATE_FAIL,
    RANGOSHORARIO_UPDATE_REQUEST, RANGOSHORARIO_UPDATE_SUCCESS } from "../constants/RangosHorarioConstants";

 
export const getlistaRangosHorario = () => async (dispatch) => {
    dispatch({ type: RANGOSHORARIO_LISTA_REQUEST});
    try {

     
      var parametros = {EmpresaId: localStorage.getItem("empresaId") };
    
      const response = await fetch('https://twinsappservice.azurewebsites.net/api/RangosHorario/Lista', {
            method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
      
            if(data.Message)
           {
            dispatch({ type: RANGOSHORARIO_LISTA_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data.Error !== "")
           {
            dispatch({ type: RANGOSHORARIO_LISTA_FAIL, payload:  data.Error });
           }
           else
           {
           dispatch({type: RANGOSHORARIO_LISTA_SUCCESS, payload:  data.Lista}); 
           }
           
    } catch (error) {
      dispatch({
        type: RANGOSHORARIO_LISTA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const RangoHorarioUpdate = (Id,Cantidad,Desde,Hasta,DesdeABM,bActivo) => async (dispatch,getState) => {
    dispatch({ type: RANGOSHORARIO_UPDATE_REQUEST});
    const { operadorSignin: { operadorInfo }, } = getState(); 
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId"),Id:Id ,iCantidad: Cantidad,sDesde:Desde,sHasta:Hasta,OperadorId:operadorInfo.Id,bActivo:bActivo};

     
     /*   dispatch({ type: RANGOSHORARIO_UPDATE_FAIL, payload:  JSON.stringify(parametros) });
return;  */
  

      const response = await fetch('https://twinsappservice.azurewebsites.net/api/RangosHorario/Insert', {
             method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: RANGOSHORARIO_UPDATE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {
            dispatch({ type: RANGOSHORARIO_UPDATE_FAIL, payload:  data });
           }
           else
           {
             if(DesdeABM && DesdeABM === true)
             {
                dispatch({type: RANGOSHORARIO_UPDATE_SUCCESS }); }
                else{
                  dispatch({type: RANGOSHORARIO_UPDATEGRILLA_SUCCESS }); } 
              
           }    
           
    } catch (error) {
      dispatch({
        type: RANGOSHORARIO_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

 

  export const RangoHorarioDelete = (Id) => async (dispatch) => {
    dispatch({ type: RANGOSHORARIO_DELETE_REQUEST});
  
    try {
      var parametros = {EmpresaId: localStorage.getItem("empresaId") ,Id: Id};
      /*   dispatch({ type: RANGOSHORARIO_DELETE_FAIL, payload:  JSON.stringify(parametros) });
return;  */

      const response = await fetch('https://twinsappservice.azurewebsites.net/api/RangosHorario/Eliminar', {
             method: 'POST',
            headers: { 'Content-type': 'application/json; charset=UTF-8','Access-Control-Allow-Credentials':'true'},
            body: JSON.stringify(parametros)
            });    
           const data = await response.json();
            if(data.Message)
           {
            dispatch({ type: RANGOSHORARIO_DELETE_FAIL, payload:  'Error inesperado. Por favor vuelva a intentarlo'  });
           }
           else if(data !== "")
           {dispatch({type: RANGOSHORARIO_DELETE_SUCCESS });  
            dispatch({ type: RANGOSHORARIO_DELETE_FAIL, payload:  data });
           }
           else
           {
              
                dispatch({type: RANGOSHORARIO_DELETE_SUCCESS });  
             
           }    
           
    } catch (error) {
      dispatch({
        type: RANGOSHORARIO_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };