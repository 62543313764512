export const COMBO_UNIDADES_REQUEST = 'COMBO_UNIDADES_REQUEST';
export const COMBO_UNIDADES_SUCCESS = 'COMBO_UNIDADES_SUCCESS';
export const COMBO_UNIDADES_FAIL = 'COMBO_UNIDADES_FAIL'; 

export const COMBO_MERCADERIATIPOS_REQUEST = 'COMBO_MERCADERIATIPOS_REQUEST';
export const COMBO_MERCADERIATIPOS_SUCCESS = 'COMBO_MERCADERIATIPOS_SUCCESS';
export const COMBO_MERCADERIATIPOS_FAIL = 'COMBO_MERCADERIATIPOS_FAIL'; 

export const COMBO_FAMILIAS_REQUEST = 'COMBO_FAMILIAS_REQUEST';
export const COMBO_FAMILIAS_SUCCESS = 'COMBO_FAMILIAS_SUCCESS';
export const COMBO_FAMILIAS_FAIL = 'COMBO_FAMILIAS_FAIL';

export const COMBO_IMPUESTOS_REQUEST = 'COMBO_IMPUESTOS_REQUEST';
export const COMBO_IMPUESTOS_SUCCESS = 'COMBO_IMPUESTOS_SUCCESS';
export const COMBO_IMPUESTOS_FAIL = 'COMBO_IMPUESTOS_FAIL';

export const COMBO_ETIQUETAS_REQUEST = 'COMBO_ETIQUETAS_REQUEST';
export const COMBO_ETIQUETAS_SUCCESS = 'COMBO_ETIQUETAS_SUCCESS';
export const COMBO_ETIQUETAS_FAIL = 'COMBO_ETIQUETAS_FAIL';

export const COMBO_DEFINICIONESCUARTO_REQUEST = 'COMBO_DEFINICIONESCUARTO_REQUEST';
export const COMBO_DEFINICIONESCUARTO_SUCCESS = 'COMBO_DEFINICIONESCUARTO_SUCCESS';
export const COMBO_DEFINICIONESCUARTO_FAIL = 'COMBO_DEFINICIONESCUARTO_FAIL';

export const COMBO_NOMENCLADORES_REQUEST = 'COMBO_NOMENCLADORES_REQUEST';
export const COMBO_NOMENCLADORES_SUCCESS = 'COMBO_NOMENCLADORES_SUCCESS';
export const COMBO_NOMENCLADORES_FAIL = 'COMBO_NOMENCLADORES_FAIL';

export const COMBO_CLASIFICACIONES_REQUEST = 'COMBO_CLASIFICACIONES_REQUEST';
export const COMBO_CLASIFICACIONES_SUCCESS = 'COMBO_CLASIFICACIONES_SUCCESS';
export const COMBO_CLASIFICACIONES_FAIL = 'COMBO_CLASIFICACIONES_FAIL';

export const COMBO_MERCADERIASAFIP_REQUEST = 'COMBO_MERCADERIASAFIP_REQUEST';
export const COMBO_MERCADERIASAFIP_SUCCESS = 'COMBO_MERCADERIASAFIP_SUCCESS';
export const COMBO_MERCADERIASAFIP_FAIL = 'COMBO_MERCADERIASAFIP_FAIL';

export const COMBO_GRUPOSMERCADERIA_REQUEST = 'COMBO_GRUPOSMERCADERIA_REQUEST';
export const COMBO_GRUPOSMERCADERIA_SUCCESS = 'COMBO_GRUPOSMERCADERIA_SUCCESS';
export const COMBO_GRUPOSMERCADERIA_FAIL = 'COMBO_GRUPOSMERCADERIA_FAIL';

export const COMBO_MERCADERIAS_REQUEST = 'COMBO_MERCADERIAS_REQUEST';
export const COMBO_MERCADERIAS_SUCCESS = 'COMBO_MERCADERIAS_SUCCESS';
export const COMBO_MERCADERIAS_FAIL = 'COMBO_MERCADERIAS_FAIL';

export const COMBO_DIAS_SUCCESS= 'COMBO_DIAS_SUCCESS';

export const COMBO_METODOSPAGO_REQUEST = 'COMBO_METODOSPAGO_REQUEST';
export const COMBO_METODOSPAGO_SUCCESS = 'COMBO_METODOSPAGO_SUCCESS';
export const COMBO_METODOSPAGO_FAIL = 'COMBO_METODOSPAGO_FAIL';