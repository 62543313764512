
import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getcomboEtiquetas } from '../../actions/comboActions';
import { MERCADERIA_GET_SUCCESS } from '../../constants/mercaderiaConstants';
import LoadingBox from '../LoadingBox';
import MessageBox from '../MessageBox';

import 'primereact/resources/themes/saga-green/theme.css';
import { AutoComplete } from 'primereact/autocomplete';
import 'primeicons/primeicons.css';

export default function MercaderiaDetalleEtiquetas() {
  const dispatch = useDispatch();
  const mercaderiaGet =  useSelector(state => state.mercaderiaGet)
  const { mercaderiaItem} = mercaderiaGet;
  const Modificar = (campo,valor,combo) => 
  { switch (campo) {
    case 'EtiquetaCaja':
      setEtiquetaCaja(combo);
    break;
    case 'EtiquetaPrimaria':
      setEtiquetaPrimaria(combo);
    break;
    case 'EtiquetaPallet':
      setEtiquetaPallet(combo);
    break;
    case 'EtiquetaCambioEnvase':
      setEtiquetaCambioEnvase(combo);
    break;
    
    default:
      break;
  }
    if(combo)
    mercaderiaItem[campo] = combo.Etiqueta ? combo.Etiqueta : '';
    else
    mercaderiaItem[campo] = valor;
    dispatch({type: MERCADERIA_GET_SUCCESS, payload:  mercaderiaItem});    
  }

    const comboEtiquetas =  useSelector(state => state.comboEtiquetas);
    const {loading: loadingEtiquetas, error: errorEtiquetas, EtiquetasCombo} = comboEtiquetas;
    useEffect(() => { 
         dispatch(getcomboEtiquetas())
    }, [dispatch])
    const [EtiquetaCaja, setEtiquetaCaja] = useState(null);
    const [EtiquetaPrimaria, setEtiquetaPrimaria] = useState(null);
    const [EtiquetaPallet, setEtiquetaPallet] = useState(null);
    const [EtiquetaCambioEnvase, setEtiquetaCambioEnvase] = useState(null);
    const [filteredEtiquetas, setFilteredEtiquetas] = useState(null); 
    const searchEtiquetas = (event) => {
        setTimeout(() => {
            let filteredEtiquetas;
            if (!event.query.trim().length) {
                filteredEtiquetas = [...EtiquetasCombo];
            }
            else {
              filteredEtiquetas = EtiquetasCombo.filter((country) => {
                    return country.Etiqueta.toLowerCase().startsWith(event.query.toLowerCase());
                });
            } 
            setFilteredEtiquetas(filteredEtiquetas);
        }, 250);
    }

    useEffect(() => { 
      if(EtiquetasCombo && mercaderiaItem)
      {
      setEtiquetaCaja(EtiquetasCombo.find(r => r.Etiqueta === mercaderiaItem.EtiquetaCaja));
      setEtiquetaPrimaria(EtiquetasCombo.find(r => r.Etiqueta === mercaderiaItem.EtiquetaPrimaria));
      setEtiquetaPallet(EtiquetasCombo.find(r => r.Etiqueta === mercaderiaItem.EtiquetaPallet));
      setEtiquetaCambioEnvase(EtiquetasCombo.find(r => r.Etiqueta === mercaderiaItem.EtiquetaCambioEnvase));
      }
  }, [dispatch,EtiquetasCombo,mercaderiaItem])

  const itemTemplate = (item) => {
    return (
        <div className="country-item">
            <img  alt={item.Etiqueta} src={'https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
            onError={(e) => e.target.src='https://twinsarchivos.blob.core.windows.net/logosempresas/188.png'} 
            className={`flag flag-${item.Etiqueta.toLowerCase()}`} />
            <div>{item.Etiqueta}</div>
        </div>
    );
  }
    return (
        <form className="form">        
          <h5 htmlFor="EtiquetaCaja" style={{marginLeft:"5px", verticalAlign:"end",  marginBottom:"0"}}>Etiqueta Caja </h5>  
            <div>
              {loadingEtiquetas? <LoadingBox></LoadingBox>
              :
              errorEtiquetas?<MessageBox variant="danger">{errorEtiquetas}</MessageBox>
              :   
              <AutoComplete value={EtiquetaCaja} suggestions={filteredEtiquetas} completeMethod={searchEtiquetas} 
                 field="Etiqueta" dropdown forceSelection itemTemplate={itemTemplate}  
                 onChange={(e) => Modificar('EtiquetaCaja', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>

        }
        </div>
        <h5 htmlFor="EtiquetaPrimaria" style={{marginBottom:"0px"}}>Etiqueta Primaria</h5>
            <div>
              {loadingEtiquetas? <LoadingBox></LoadingBox>
              :
              errorEtiquetas?<MessageBox variant="danger">{errorEtiquetas}</MessageBox>
              :    
              <AutoComplete value={EtiquetaPrimaria} suggestions={filteredEtiquetas} completeMethod={searchEtiquetas} 
              field="Etiqueta" dropdown forceSelection itemTemplate={itemTemplate}  
              onChange={(e) => Modificar('EtiquetaPrimaria', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
        </div>

        <h5 htmlFor="EtiquetaPallet" style={{marginBottom:"0px"}}>Etiqueta Pallet</h5>
            <div>
              {loadingEtiquetas? <LoadingBox></LoadingBox>
              :
              errorEtiquetas?<MessageBox variant="danger">{errorEtiquetas}</MessageBox>
              :    
              <AutoComplete value={EtiquetaPallet} suggestions={filteredEtiquetas} completeMethod={searchEtiquetas} 
              field="Etiqueta" dropdown forceSelection itemTemplate={itemTemplate}  
              onChange={(e) => Modificar('EtiquetaPallet', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
        </div>

        <h5 htmlFor="EtiquetaCambioEnvase" style={{marginBottom:"0px"}}>Etiqueta Cambio Envase</h5>
            <div>
              {loadingEtiquetas? <LoadingBox></LoadingBox>
              :
              errorEtiquetas?<MessageBox variant="danger">{errorEtiquetas}</MessageBox>
              :    
              <AutoComplete value={EtiquetaCambioEnvase} suggestions={filteredEtiquetas} completeMethod={searchEtiquetas} 
              field="Etiqueta" dropdown forceSelection itemTemplate={itemTemplate}  
              onChange={(e) => Modificar('EtiquetaCambioEnvase', e.value ? e.value.Id :0,e.value)} inputStyle={{fontSize:'20px'}} panelStyle={{fontSize:'20px'}}/>}
        </div>
 
                  <Link onClick={() => {Modificar('bEtiquetasIndividuales',!mercaderiaItem.bEtiquetasIndividuales)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                  <div>

                    <tr>
                      <td>{mercaderiaItem.bEtiquetasIndividuales ? <i class="fa fa-check-square-o" 
                                      aria-hidden="true" 
                                      style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                              : <i class="fa fa-square-o" 
                                      aria-hidden="true" 
                                      style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                      }</td>
                      <td> <h4 style={{marginRight:"20px"}}>Etiquetas Individuales </h4></td>
                      </tr> 
                         
                  </div> 
                  </Link> 

                  <Link onClick={() => {Modificar('MostrarEnEtiquetaPallet',!mercaderiaItem.MostrarEnEtiquetaPallet)}} style={{fontSize: "2.5rem", color:'black',cursor: "Hand", verticalAlign: "bottom" }}>
                  <div>
                    <tr>
                      <td>{mercaderiaItem.MostrarEnEtiquetaPallet ? <i class="fa fa-check-square-o" 
                                      aria-hidden="true" 
                                      style={{ fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}}/> 
                              : <i class="fa fa-square-o" 
                                      aria-hidden="true" 
                                      style={{fontSize: "3rem",marginRight:"10px", color:'black' , verticalAlign: "bottom"}} /> 
                      }</td>
                      <td> <h4 style={{marginRight:"20px"}}>Mostrar en Pallet </h4></td>
                      </tr> 
                  </div> 
                  </Link> 
 
        </form>
    )
}
