import { LOCALIDADESAPP_LISTA_FAIL, LOCALIDADESAPP_LISTA_REQUEST, LOCALIDADESAPP_LISTA_SUCCESS, LOCALIDADESAPP_UPDATE_FAIL, LOCALIDADESAPP_UPDATE_REQUEST, LOCALIDADESAPP_UPDATE_RESET, LOCALIDADESAPP_UPDATE_SUCCESS } from "../constants/LocalidadesAppConstants";

 
  
    export const localidadesappReducer = ( state = { loading:true,  localidadesappLista: [] },  action) => {
    switch (action.type) {
      case LOCALIDADESAPP_LISTA_REQUEST:
        return { loading: true };
      case LOCALIDADESAPP_LISTA_SUCCESS:
        return { loading: false, localidadesappLista: action.payload };
      case LOCALIDADESAPP_LISTA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const localidadesappUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case LOCALIDADESAPP_UPDATE_REQUEST:
        return { loading: true };
      case LOCALIDADESAPP_UPDATE_SUCCESS:
        return { loading: false, success: true  };
      case LOCALIDADESAPP_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case LOCALIDADESAPP_UPDATE_RESET:
          return {};
      default:
        return state;
    }
  };